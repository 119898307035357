import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, TextField, MenuItem, Grid } from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanStore from '../loan-store';

const LoanTerms = observer(() => {
    // Local state for form values
    const [formValues, setFormValues] = useState({
        loan_product: '',
        loanAmountRequested: '',
        loan_amortization_years: '',
        maxInterestRate: '',
        loan_payment_frequency: ''
    });

    // Initialize form values from loanStore
    useEffect(() => {
        if (loanStore.loanDoc?.data?.application?.loanTerms) {
            setFormValues(loanStore.loanDoc.data.application.loanTerms);
        }
    }, []); // Empty dependency array as MobX observer will handle reactivity

    if (!loanStore.loanDoc?.hasData) return null;

    // Handle local state changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle currency field changes
    const handleCurrencyChange = (event, value) => {
        setFormValues(prev => ({
            ...prev,
            loanAmountRequested: value
        }));
    };

    // Handle interest rate changes
    const handleInterestRateChange = (event) => {
        let value = event.target.value.replace(/%/g, '');
        if (!isNaN(value) && value !== '') {
            value += '%';
        }
        setFormValues(prev => ({
            ...prev,
            maxInterestRate: value
        }));
    };

    // Update Firestore on blur
    const handleBlur = () => {
        loanStore.loanDoc.update({
            'application.loanTerms': formValues
        });
    };

    return (
        <Box>
            <Typography variant="h6" mb={1}>Loan Terms</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="loan_product"
                        label="Loan Product"
                        value={formValues.loan_product}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        select
                    >
                        <MenuItem value="5-Yr Revolving LOC">5-Yr Revolving LOC</MenuItem>
                        <MenuItem value="10-Yr Revolving LOC">10-Yr Revolving LOC</MenuItem>
                        <MenuItem value="1 Month ARM">1 Month ARM</MenuItem>
                        <MenuItem value="1-Yr VRM">1-Yr VRM</MenuItem>
                        <MenuItem value="3-Yr VRM">3-Yr VRM</MenuItem>
                        <MenuItem value="5-Yr VRM">5-Yr VRM</MenuItem>
                        <MenuItem value="10-Yr VRM">10-Yr VRM</MenuItem>
                        <MenuItem value="15-Yr VRM">15-Yr VRM</MenuItem>
                        <MenuItem value="7-Yr Fixed">7-Yr Fixed</MenuItem>
                        <MenuItem value="10-Yr Fixed">10-Yr Fixed</MenuItem>
                        <MenuItem value="15-Yr Fixed">15-Yr Fixed</MenuItem>
                        <MenuItem value="20-Yr Fixed">20-Yr Fixed</MenuItem>
                        <MenuItem value="25-Yr Fixed">25-Yr Fixed</MenuItem>
                        <MenuItem value="30-Yr Fixed">30-Yr Fixed</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyTextField
                        modifyValueOnWheel={false}
                        variant="outlined"
                        name="loanAmountRequested"
                        label="Loan Amount Requested"
                        value={formValues.loanAmountRequested}
                        onChange={handleCurrencyChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="loan_amortization_years"
                        label="Loan Amortization Years"
                        value={formValues.loan_amortization_years}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        select
                    >
                        <MenuItem value="15 Years">15 Years</MenuItem>
                        <MenuItem value="20 Years">20 Years</MenuItem>
                        <MenuItem value="25 Years">25 Years</MenuItem>
                        <MenuItem value="30 Years">30 Years</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="maxInterestRate"
                        label="Maximum Interest Rate"
                        value={formValues.maxInterestRate}
                        onChange={handleInterestRateChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="loan_payment_frequency"
                        label="Loan Payment Frequency"
                        value={formValues.loan_payment_frequency}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        select
                    >
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Semi-Annual">Semi-Annual</MenuItem>
                        <MenuItem value="Annual">Annual</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    );
});

export default LoanTerms;