// BSA.js
import React from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Typography, TextField, MenuItem, Divider} from '@mui/material';
import loanDetailStore from '../store';
import ScheduleA from './ScheduleA';
import ScheduleB from './ScheduleB';
import ScheduleC from './ScheduleC';
import ScheduleD from './ScheduleD';
import ScheduleE from './ScheduleE';
import ScheduleF from './ScheduleF';
import ScheduleG from './ScheduleG';
import ScheduleH from './ScheduleH';
import ScheduleI from './ScheduleI';
import ScheduleJ from './ScheduleJ';
import ScheduleK from './ScheduleK';
import LoanData from "./LoanData";
import TotalPISummary from './TotalPISummary';

const BSA = observer(({readOnly, proforma}) => {
    const {borrowers, selectedBorrowerId, setSelectedBorrowerId} = loanDetailStore;
    const selectedBorrower = borrowers.find((borrower) => borrower.id === selectedBorrowerId);

    const handleBorrowerChange = (event) => {
        setSelectedBorrowerId(event.target.value);
    };

    const borrowerData = (readOnly || proforma) ? borrowers : [selectedBorrower];

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" mb={2}>
            <Typography variant="h6" mb={1}>{readOnly ? 'Consolidated BSA' : 'BSA'}</Typography>
            {(!readOnly && !proforma) && (
                <TextField select label="Select Borrower" value={selectedBorrowerId} onChange={handleBorrowerChange}
                           mb={2}>
                    {borrowers.map((borrower) => {
                        let name;
                        switch (borrower.type) {
                            case 'Trust':
                                name = borrower.trustName;
                                break;
                            case 'Entity':
                                name = borrower.entityName;
                                break;
                            case 'Individual':
                                name = `${borrower.firstName} ${borrower.lastName}`;
                                break;
                            default:
                                name = 'Unknown';
                        }
                        return (
                            <MenuItem key={borrower.id} value={borrower.id}>
                                {name}
                            </MenuItem>
                        );
                    })}
                </TextField>
            )}
            <Box my={2}>
                <Divider/>
            </Box>
            {(readOnly || selectedBorrower || proforma) && (
                <>
                    <ScheduleA borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    {proforma && <LoanData/>}
                    <ScheduleB borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleC borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    {proforma && <TotalPISummary borrowerData={borrowerData}/>}
                    <ScheduleD borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleE borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleF borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleG borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleH borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleI borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleJ borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                    <ScheduleK borrowerData={borrowerData} readOnly={readOnly} proforma={proforma}/>
                </>
            )}
        </Box>
    );
});

export default BSA;