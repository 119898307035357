

import React, {useCallback, useMemo, useState} from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, MenuItem, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const ScheduleA = observer(({ borrowerData, readOnly, proforma }) => {
  const { updateBSAData, addBSAItem, deleteBSAItem, updateBSAProformaData, addBSAProformaItem, deleteBSAProformaItem, selectedBorrowerId } = loanDetailStore;
  const [editedItem, setEditedItem] = useState(null);
  const [editedIndex, setEditedIndex] = useState(null);

  const selectedBorrowerLocalId = useMemo(() => {
    let result;
    if (readOnly || proforma) {
      result = borrowerData.find(borrower => borrower.is_principal === true)["id"];
    } else {
      result = selectedBorrowerId;
    }
    if (!result) {
      console.warn('No borrower found!');
    }
    return result;
  }, [selectedBorrowerId, readOnly, proforma, borrowerData]);

  const selectedBorrower = useMemo(() => {
    return borrowerData.find(borrower => borrower.id === selectedBorrowerLocalId);
  }, [selectedBorrowerLocalId, borrowerData]);

  const handleInputChange = (item, index, field, value) => {
    if (editedIndex !== index) {
      setEditedItem({...item});
      setEditedIndex(index);
    }

    setEditedItem(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleBlur = (borrowerId, index) => {
    if (editedItem && editedIndex === index) {
      Object.entries(editedItem).forEach(([field, value]) => {
        if (field !== 'index' && field !== 'borrowerId') {
          if (proforma) {
            updateBSAProformaData(borrowerId, 'a', index, field, value);
          } else {
            updateBSAData(borrowerId, 'a', index, field, value);
          }
        }
      });
      setEditedItem(null);
      setEditedIndex(null);
    }
  };

  const handleAddItem = useCallback(() => {
    if (proforma) {
      addBSAProformaItem('a');
    } else {
      addBSAItem(selectedBorrowerLocalId, 'a');
    }
  }, [addBSAItem, addBSAProformaItem, proforma, selectedBorrowerLocalId]);

  const handleDeleteItem = useCallback((borrowerId, index) => {
    if (proforma) {
      const borrower = borrowerData.find(b => b.id === borrowerId);
      if (borrower && borrower.bsas?.a) {
        const originalIndex = borrower.bsas.a.findIndex((item, idx) => idx === index);
        if (originalIndex !== -1) {
          deleteBSAProformaItem(borrowerId, 'a', originalIndex);
        }
      }
    } else {
      deleteBSAItem(borrowerId, 'a', index);
    }
  }, [deleteBSAItem, deleteBSAProformaItem, proforma, borrowerData]);

  const getFieldValue = useCallback((item, field, index) => {
    if (editedIndex === index && editedItem) {
      return editedItem[field] ?? '';
    }
    return proforma ? (item.proforma?.[field] ?? item[field] ?? '') : (item[field] ?? '');
  }, [proforma, editedIndex, editedItem]);

  const renderScheduleItems = () => {
    if (readOnly) {
      return borrowerData.flatMap(borrower => borrower.bsas?.a || []);
    }

    if (proforma) {
      return borrowerData.flatMap((borrower, borrowerIndex) =>
          (borrower.bsas?.a || []).map((item, itemIndex) => ({
            ...item,
            ...(item.proforma || {}),
            borrowerId: borrower.id,
            index: itemIndex,
          }))
      );
    }
    return (selectedBorrower.bsas?.a || []).map((item, index) => ({ ...item, index }));
  };

  const scheduleItems = renderScheduleItems();

  const calculateTotals = () => {
    return scheduleItems.reduce((totals, item) => {
      totals.balance += parseFloat(getFieldValue(item, 'balance', item.index)) || 0;
      totals.accruedInterest += parseFloat(getFieldValue(item, 'accruedInterest', item.index)) || 0;
      totals.annualTotalInterest += parseFloat(getFieldValue(item, 'annualTotalInterest', item.index)) || 0;
      totals.annualPayment += parseFloat(getFieldValue(item, 'annualPayment', item.index)) || 0;
      return totals;
    }, { balance: 0, accruedInterest: 0, annualTotalInterest: 0, annualPayment: 0 });
  };

  const totals = calculateTotals();

  return (
      <>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Schedule A - Real Estate and Debt</Typography>
        {scheduleItems.map((item, index) => (
            <Box
                key={`${item.borrowerId}-${item.index}`}
                mb={2}
                sx={{
                  padding: '8px 0'
                }}
            >
              <Box
                  display="grid"
                  gap={2}
                  mb={1}
                  sx={{
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    alignItems: 'center'
                  }}
              >
                <TextField
                    select
                    fullWidth
                    label="New Loan Collateral?"
                    value={getFieldValue(item, 'newLoanCollateral', index)}
                    onChange={(e) => handleInputChange(item, index, 'newLoanCollateral', e.target.value)}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
                <TextField
                    fullWidth
                    label="Acres"
                    type="number"
                    value={getFieldValue(item, 'acres', index)}
                    onChange={(e) => handleInputChange(item, index, 'acres', e.target.value)}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    fullWidth
                    label="Year"
                    type="number"
                    value={getFieldValue(item, 'year', index)}
                    onChange={(e) => handleInputChange(item, index, 'year', e.target.value)}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={getFieldValue(item, 'description', index)}
                    onChange={(e) => handleInputChange(item, index, 'description', e.target.value)}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    fullWidth
                    modifyValueOnWheel={false}
                    variant="outlined"
                    label="Purchase Price"
                    value={getFieldValue(item, 'purchasePrice', index)}
                    onChange={(e) => handleInputChange(item, index, 'purchasePrice', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    fullWidth
                    modifyValueOnWheel={false}
                    variant="outlined"
                    label="Market Value"
                    value={getFieldValue(item, 'marketValue', index)}
                    onChange={(e) => handleInputChange(item, index, 'marketValue', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
              </Box>
              <Box
                  display="grid"
                  gap={2}
                  mb={1}
                  sx={{
                    gridTemplateColumns: 'repeat(7, 1fr)',
                    alignItems: 'center'
                  }}
              >
                <TextField
                    fullWidth
                    label="Payable To"
                    value={getFieldValue(item, 'payableTo', index)}
                    onChange={(e) => handleInputChange(item, index, 'payableTo', e.target.value)}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    fullWidth
                    modifyValueOnWheel={false}
                    variant="outlined"
                    label="Balance"
                    value={getFieldValue(item, 'balance', index)}
                    onChange={(e) => handleInputChange(item, index, 'balance', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    fullWidth
                    label="Rate"
                    type="number"
                    value={getFieldValue(item, 'rate', index)}
                    onChange={(e) => handleInputChange(item, index, 'rate', parseFloat(e.target.value) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    label="Accrued Interest"
                    value={getFieldValue(item, 'accruedInterest', index)}
                    onChange={(e) => handleInputChange(item, index, 'accruedInterest', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    fullWidth
                    modifyValueOnWheel={false}
                    variant="outlined"
                    label="Annual Total Interest"
                    value={(parseFloat(getFieldValue(item, 'balance', index)) || 0) * (parseFloat(getFieldValue(item, 'rate', index)/100) || 0)}
                    onChange={(e) => handleInputChange(item, index, 'annualTotalInterest', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: true }}
                />
                <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    label="Annual Total Principal"
                    value={(getFieldValue(item, 'annualPayment', index) || 0) - ((parseFloat(getFieldValue(item, 'balance', index)) || 0) * (parseFloat(getFieldValue(item, 'rate', index)/100) || 0))}
                    InputProps={{ readOnly: true }}
                />
                <CurrencyTextField
                    fullWidth
                    modifyValueOnWheel={false}
                    variant="outlined"
                    label="Annual Payment"
                    value={getFieldValue(item, 'annualPayment', index)}
                    onChange={(e) => handleInputChange(item, index, 'annualPayment', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
              </Box>
              {(proforma || !readOnly) && (
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={() => handleDeleteItem(item.borrowerId || selectedBorrowerLocalId, item.index)}
                        sx={{
                          backgroundColor: '#dc2626',
                          '&:hover': {
                            backgroundColor: '#b91c1c'
                          }
                        }}
                        startIcon={<DeleteIcon />}
                    >
                      Remove
                    </Button>
                  </Box>
              )}
            </Box>
        ))}
        {(proforma || !readOnly) && (
            <Box display="flex" justifyContent="flex-start" mb={2}>
              <Button variant="contained" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>
        )}
        {(readOnly || proforma) && (
            <Box
                display="grid"
                gap={2}
                my={2}
                sx={{
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  justifyContent: 'end'
                }}
            >
              <CurrencyTextField
                  fullWidth
                  variant="outlined"
                  label="Total Balance"
                  value={totals.balance}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  fullWidth
                  variant="outlined"
                  label="Total Annual Principal"
                  value={totals.annualPayment - totals.annualTotalInterest}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  fullWidth
                  variant="outlined"
                  label="Total Annual Payment"
                  value={totals.annualPayment}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
            </Box>
        )}
      </>
  );
});
export default ScheduleA;