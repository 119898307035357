import { Collection, Document } from 'firestorter';
import { makeObservable, observable, computed } from 'mobx';

class LoansStore {
  loansCollection;
  initialized = false;

  // Move getBorrowerName inside the class
  getBorrowerName = (borrowerData) => {
    if (!borrowerData) return 'Not Available';
    
    switch (borrowerData.type) {
      case 'Individual':
      case 'Individual + Spouse':
        const firstName = borrowerData.firstName || '';
        const middleName = borrowerData.middleName ? ` ${borrowerData.middleName}` : '';
        const lastName = borrowerData.lastName ? ` ${borrowerData.lastName}` : '';
        const suffix = borrowerData.suffix ? ` ${borrowerData.suffix}` : '';
        return `${firstName}${middleName}${lastName}${suffix}`.trim() || 'Not Available';
        
      case 'Entity':
        return borrowerData.entityName || 'Not Available';
        
      case 'Trust':
        return borrowerData.trustName || 'Not Available';
        
      default:
        return 'Not Available';
    }
  };

  constructor() {
    this.loansCollection = new Collection('loans', {
      mode: 'auto',
      debug: true,
      debugName: 'LoansCollection',
      createDocument: (source, options) => {
        const doc = new Document(source, {
          ...options,
          mode: 'auto',
          debug: true,
          debugName: 'LoanDocument'
        });
        
        doc.borrowers = new Collection(() => doc.ref ? `loans/${doc.id}/borrowers` : null, {
          mode: 'auto',
          debug: true,
          debugName: `Borrowers-${doc.id || 'unknown'}`
        });
        
        return doc;
      }
    });

    makeObservable(this, {
      loansCollection: observable,
      initialized: observable,
      loans: computed,
      isLoading: computed,
      hasData: computed,
      getBorrowerName: false // Tell mobx not to try to make this observable
    });
  }

  get loans() {
    if (!this.loansCollection.isLoaded) return [];
    
    return this.loansCollection.docs.map(doc => {
      if (!doc.hasData) return null;
      
      const principalBorrower = doc.borrowers?.docs?.find(borrower => 
        borrower.data?.is_principal === true
      );
      
      const principalBorrowerName = principalBorrower ? 
        this.getBorrowerName(principalBorrower.data) : 'Not Available';
      
      return {
        id: doc.id,
        loanAmountRequested: doc.data?.application?.loanTerms?.loanAmountRequested,
        status: doc.data?.status,
        created_at: doc.data?.created_at,
        ...doc.data,
        borrowers: doc.borrowers?.docs?.map(b => ({
          id: b.id,
          ...b.data,
          name: this.getBorrowerName(b.data)
        })) || [],
        principalBorrower: principalBorrowerName,
        principalBorrowerType: principalBorrower?.data?.type || 'Not Available',
        principalBorrowerData: principalBorrower?.data || null
      };
    }).filter(Boolean);
  }

  get isLoading() {
    if (!this.loansCollection.isLoaded) return true;
    
    return this.loansCollection.docs.some(doc => 
      !doc.isLoaded || 
      (doc.borrowers && !doc.borrowers.isLoaded)
    );
  }

  get hasData() {
    return this.loansCollection.isLoaded && this.loansCollection.docs.length > 0;
  }
}

const loansStore = new LoansStore();
export default loansStore;