// Application.js
import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import LoanOverview from './LoanOverview';
import UseOfFunds from './UseOfFunds';
import BorrowerDetails from './BorrowerDetails';
import OriginatorInformation from './OriginatorInformation';
import LoanTerms from './LoanTerms';
import Security from './Security';
import QuestionnaireSection from './QuestionnaireSection';
import FinalDetails from './FinalDetails';
const Application = observer(() => {
  return (
    <Box>
      <LoanOverview />
      <UseOfFunds />
      <BorrowerDetails />
      <OriginatorInformation />
      <LoanTerms />
      <Security />
      <QuestionnaireSection />
      <FinalDetails />
    </Box>
  );
});

export default Application;