// BalanceSheet.js
import React from 'react';
import { observer } from 'mobx-react-lite';
import { formatISO } from 'date-fns';
import { Box, Typography, TextField, MenuItem, Grid, Divider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import loanDetailStore from '../store';
import CurrentAssets from './CurrentAssets';
import CurrentLiabilities from './CurrentLiabilities';
import IntermediateAssets from './IntermediateAssets';
import IntermediateLiabilities from './IntermediateLiabilities';
import LongTermAssets from './LongTermAssets';
import LongTermLiabilities from './LongTermLiabilities';
import TotalAssets from './TotalAssets';
import TotalLiabilities from './TotalLiabilities';

const BalanceSheet = observer(() => {
  const {
    borrowers,
    selectedBorrowerId,
    setSelectedBorrowerId,
    updateBorrowerBalanceSheet,
  } = loanDetailStore;

  const selectedBorrower = borrowers.find((borrower) => borrower.id === selectedBorrowerId);

  const handleBorrowerChange = (event) => {
    setSelectedBorrowerId(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" mb={2}>
      <Typography variant="h6" mb={1}>
        Balance Sheet - Market Basis
      </Typography>
      <TextField
        select
        label="Select Borrower"
        value={selectedBorrowerId}
        onChange={handleBorrowerChange}
        mb={2}
      >
        {borrowers.map((borrower) => {
          let name;
          switch (borrower.type) {
            case 'Trust':
              name = borrower.trustName;
              break;
            case 'Entity':
              name = borrower.entityName;
              break;
            case 'Individual':
              name = `${borrower.firstName} ${borrower.lastName}`;
              break;
            default:
              name = 'Unknown';
          }
          return (
            <MenuItem key={borrower.id} value={borrower.id}>
              {name}
            </MenuItem>
          );
        })}
      </TextField>
      <Box my={2}>
        <Divider />
      </Box>

      {selectedBorrower && (
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              value={selectedBorrower.balanceSheet?.date ? new Date(selectedBorrower.balanceSheet.date) : null}
              onChange={(newValue) => {
                if (newValue) {
                  try {
                    // Validate that newValue is a valid date before formatting
                    if (newValue instanceof Date && !isNaN(newValue)) {
                      updateBorrowerBalanceSheet(selectedBorrower.id, 'date', formatISO(newValue));
                    } else {
                      console.warn('Invalid date value received:', newValue);
                    }
                  } catch (error) {
                    console.error('Error formatting date:', error);
                  }
                }
              }}
              format="MM/dd/yyyy"
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: "normal"
                }
              }}
            />
          </LocalizationProvider>
          <Grid container spacing={4}>
            <CurrentAssets borrowers={[selectedBorrower]} readOnly={false} />
            <CurrentLiabilities borrowers={[selectedBorrower]} readOnly={false} />
            <IntermediateAssets borrowers={[selectedBorrower]} readOnly={false} />
            <IntermediateLiabilities borrowers={[selectedBorrower]} readOnly={false} />
            <LongTermAssets borrowers={[selectedBorrower]} readOnly={false} />
            <LongTermLiabilities borrowers={[selectedBorrower]} readOnly={false} />
            <TotalAssets borrowers={[selectedBorrower]} />
            <TotalLiabilities borrowers={[selectedBorrower]} />
          </Grid>
        </Box>
      )}
    </Box>
  );
});

export default BalanceSheet;