import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import loanStore from '../loan-store';

const questions = [
    { 
        name: 'assetsSecurityForDebts', 
        text: 'Are any assets pledged as security for debts of others?' 
    },
    { 
        name: 'hadBankruptcy', 
        text: 'Have you had a bankruptcy? (past 7 years)' 
    },
    { 
        name: 'haveJudgments', 
        text: 'Do you have any judgments against you?' 
    },
    { 
        name: 'partyToLawsuit', 
        text: 'Are you a party to any lawsuit?' 
    },
    { 
        name: 'haveContingentLiabilities', 
        text: 'Do you have any contingent liabilities?' 
    },
    { 
        name: 'endorsedGuaranteedNotes', 
        text: 'Have you endorsed or guaranteed notes for others?' 
    },
    { 
        name: 'obligatedAlimonySupport', 
        text: 'Are you obligated to pay alimony or support?' 
    },
    { 
        name: 'propertyForeclosed', 
        text: 'Property foreclosed or deed in lieu? (past 7 years)' 
    }
];

const QuestionnaireSection = observer(() => {
    // Local state for editing
    const [editedQuestion, setEditedQuestion] = useState({
        field: null,
        questionnaire: null,
        explanations: null
    });

    if (!loanStore.loanDoc?.hasData) return null;

    const handleRadioChange = (name) => (event) => {
        const { value } = event.target;
        
        if (!editedQuestion.field) {
            setEditedQuestion({
                field: name,
                questionnaire: {
                    ...loanStore.loanDoc.data?.application?.questionnaire,
                    [name]: value === 'yes'
                },
                explanations: {
                    ...loanStore.loanDoc.data?.application?.explanations
                }
            });
        } else {
            setEditedQuestion(prev => ({
                ...prev,
                questionnaire: {
                    ...prev.questionnaire,
                    [name]: value === 'yes'
                }
            }));
        }

        // If changing to "No", clear the explanation
        if (value === 'no') {
            setEditedQuestion(prev => ({
                ...prev,
                explanations: {
                    ...prev.explanations,
                    [name]: ''
                }
            }));
            
            // Immediately update if switching to "No"
            handleBlur();
        }
    };

    const handleExplanationChange = (name) => (event) => {
        const { value } = event.target;
        
        if (!editedQuestion.field) {
            setEditedQuestion({
                field: name,
                questionnaire: {
                    ...loanStore.loanDoc.data?.application?.questionnaire
                },
                explanations: {
                    ...loanStore.loanDoc.data?.application?.explanations,
                    [name]: value
                }
            });
        } else {
            setEditedQuestion(prev => ({
                ...prev,
                explanations: {
                    ...prev.explanations,
                    [name]: value
                }
            }));
        }
    };

    const handleBlur = () => {
        if (editedQuestion.field) {
            loanStore.loanDoc.update({
                'application.questionnaire': editedQuestion.questionnaire,
                'application.explanations': editedQuestion.explanations
            });
            setEditedQuestion({
                field: null,
                questionnaire: null,
                explanations: null
            });
        }
    };

    // Use either edited values or stored values
    const displayData = {
        questionnaire: editedQuestion.questionnaire || loanStore.loanDoc.data?.application?.questionnaire || {},
        explanations: editedQuestion.explanations || loanStore.loanDoc.data?.application?.explanations || {}
    };

    return (
        <Box>
            <Typography variant="h6" mb={1}>
                These questions apply to all applicants.
            </Typography>
            
            {questions.map((question) => (
                <Box key={question.name} mb={2}>
                    <Typography>{question.text}</Typography>
                    <RadioGroup
                        name={question.name}
                        value={displayData.questionnaire[question.name] ? 'yes' : 'no'}
                        onChange={handleRadioChange(question.name)}
                        row
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    
                    {displayData.questionnaire[question.name] && (
                        <TextField
                            name={question.name}
                            label="Please provide an explanation"
                            value={displayData.explanations[question.name] || ''}
                            onChange={handleExplanationChange(question.name)}
                            onBlur={handleBlur}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                        />
                    )}
                </Box>
            ))}
        </Box>
    );
});

export default QuestionnaireSection;