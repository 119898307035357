import React from 'react';
import {observer} from 'mobx-react-lite';
import {Grid, TextField, IconButton, Typography, Box, Button} from '@mui/material';
import {Delete as DeleteIcon} from '@mui/icons-material';
import loanDetailStore from '../store';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const CurrentLiabilities = observer(({borrowers, consolidated, proforma}) => {
    const {
        loanData,
        addProformaBalanceSheetItem,
        updateProformaBalanceSheetItem,
        deleteProformaBalanceSheetItem
    } = loanDetailStore;

    const calculateCurrentTotal = (field) => {
        return borrowers.reduce((sum, borrower) => {
            const value = parseFloat(borrower.balanceSheet?.currentLiabilities?.[field]) || 0;
            return sum + value;
        }, 0);
    };

    const calculateElims = (field) => {
        return parseFloat(borrowers[0].balanceSheet?.currentLiabilities?.[`${field}Elims`] || 0);
    };

    const calculateConsolidated = (field) => {
        return calculateCurrentTotal(field) - calculateElims(field);
    };

    const calculateArrayFieldSum = (schedule, field) => {
        return (borrowers || []).reduce((sum, borrower) => {
            const array = (borrower?.bsas || {})[schedule] || [];
            const arraySum = array.reduce((innerSum, item) => {
                const value = parseFloat(item?.[field] || 0);
                return innerSum + value;
            }, 0);
            return sum + arraySum;
        }, 0);
    };

    const calculateAccruedInterestSum = () => {
        return ['a', 'b', 'c'].reduce((sum, schedule) => {
            return sum + calculateArrayFieldSum(schedule, 'accruedInterest');
        }, 0);
    };

    // Update the calculateCurrentMaturitySum function
    const calculateCurrentMaturitySum = () => {
        return ['a', 'b'].reduce((scheduleSum, schedule) => {
            const scheduleData = borrowers.flatMap(borrower => {
                const items = proforma 
                    ? (borrower.bsas?.[schedule] || []).map(item => ({
                        ...item,
                        ...(item.proforma || {})
                      }))
                    : borrower.bsas?.[schedule] || [];
                return items;
            });
            
            // Sum the principal component from each item in this schedule
            const principalSum = scheduleData.reduce((sum, item) => {
                const annualPayment = parseFloat(item.annualPayment) || 0;
                const balance = parseFloat(item.balance) || 0;
                const rate = parseFloat(item.rate) || 0;
                const annualInterest = balance * (rate/100);
                const principal = annualPayment - annualInterest;
                return sum + principal;
            }, 0);
            
            return scheduleSum + principalSum;
        }, 0);
    };

    const calculateAccountsPayableTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const accountsPayable = borrower.balanceSheet?.accountsPayable || [];
            const accountsPayableSum = accountsPayable.reduce((innerSum, account) => {
                const value = parseFloat(account.amount || 0);
                return innerSum + value;
            }, 0);
            return sum + accountsPayableSum;
        }, 0);
    };

    const calculateAccountsPayableConsolidatedTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const accountsPayable = borrower.balanceSheet?.accountsPayable || [];
            const accountsPayableSum = accountsPayable.reduce((innerSum, account) => {
                const value = parseFloat(account.amount || 0) - parseFloat(account.elims || 0);
                return innerSum + value;
            }, 0);
            return sum + accountsPayableSum;
        }, 0);
    };

    const calculateAccountsPayableChangeTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const accountsPayable = borrower.balanceSheet?.accountsPayable || [];
            const accountsPayableSum = accountsPayable.reduce((innerSum, account) => {
                const value = parseFloat(account.change || 0);
                return innerSum + value;
            }, 0);
            return sum + accountsPayableSum;
        }, 0);
    };

    const calculateAccountsPayableProformaTotal = () => {
        return calculateAccountsPayableConsolidatedTotal() + calculateAccountsPayableChangeTotal();
    };

    const calculateConsolidatedTotal = () => {
        return (
            calculateArrayFieldSum('c', 'balance') +
            calculateConsolidated('incomeTaxPayable') +
            calculateConsolidated('realEstateTaxesDue') +
            calculateConsolidated('rentsDue') +
            calculateAccruedInterestSum() +
            calculateCurrentMaturitySum() +
            calculateAccountsPayableConsolidatedTotal()
        );
    };

    const calculateProformaAccountsPayableTotal = () => {
        return (loanData.balanceSheetProforma?.currentLiabilities?.accountsPayable || [])
            .reduce((sum, account) => sum + (parseFloat(account.amount) || 0), 0);
    };
    
    const calculateProformaTotal = () => {
        return (
            calculateArrayFieldSum('c', 'balance') +
            calculateProforma('incomeTaxPayable') +
            calculateProforma('realEstateTaxesDue') +
            calculateProforma('rentsDue') +
            calculateAccruedInterestSum() +
            calculateCurrentMaturitySum() +
            calculateAccountsPayableProformaTotal() +
            calculateProformaAccountsPayableTotal()
        );
    };

    const calculateProforma = (field) => {
        return calculateConsolidated(field) + (loanDetailStore.loanData.balanceSheetProforma?.currentLiabilities?.[`${field}Change`] || 0);
    };

    const updateProformaField = (field, value) => {
        loanDetailStore.updateLoanData({
            balanceSheetProforma: {
                ...loanDetailStore.loanData.balanceSheetProforma,
                currentLiabilities: {
                    ...loanDetailStore.loanData.balanceSheetProforma?.currentLiabilities,
                    [field]: value,
                },            },
        });
    };

    const {
        addBalanceSheetField,
        updateBalanceSheetField,
        deleteBalanceSheetField,
        updateBorrowerBalanceSheet
    } = loanDetailStore;

    return (
        <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" my={2}>
                Current Liabilities
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
                {consolidated && (
                    <>
                        {/* Current Debt */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Debt (Schedule C) - Current"
                                value={calculateArrayFieldSum('c', 'balance')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <Box /> {/* Empty box for spacing */}
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Debt (Schedule C) - Consolidated"
                                value={calculateArrayFieldSum('c', 'balance')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Income Tax Payable */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Income Tax Payable - Current"
                                value={calculateCurrentTotal('incomeTaxPayable')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Income Tax Payable - Elims"
                                value={borrowers[0].balanceSheet?.currentLiabilities?.incomeTaxPayableElims || ""}
                                onChange={(e) => 
                                    updateBorrowerBalanceSheet(
                                        borrowers[0].id, 
                                        'currentLiabilities.incomeTaxPayableElims', 
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Income Tax Payable - Consolidated"
                                value={calculateConsolidated('incomeTaxPayable')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Real Estate Taxes Due */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Real Estate Taxes Due - Current"
                                value={calculateCurrentTotal('realEstateTaxesDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Real Estate Taxes Due - Elims"
                                value={borrowers[0].balanceSheet?.currentLiabilities?.realEstateTaxesDueElims || ""}
                                onChange={(e) => 
                                    updateBorrowerBalanceSheet(
                                        borrowers[0].id, 
                                        'currentLiabilities.realEstateTaxesDueElims', 
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Real Estate Taxes Due - Consolidated"
                                value={calculateConsolidated('realEstateTaxesDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Rents Due */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Rents Due - Current"
                                value={calculateCurrentTotal('rentsDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Rents Due - Elims"
                                value={borrowers[0].balanceSheet?.currentLiabilities?.rentsDueElims || ""}
                                onChange={(e) => 
                                    updateBorrowerBalanceSheet(
                                        borrowers[0].id, 
                                        'currentLiabilities.rentsDueElims', 
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Rents Due - Consolidated"
                                value={calculateConsolidated('rentsDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Accrued Interest */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Accrued Interest (Schedule A, B, C) - Current"
                                value={calculateAccruedInterestSum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <Box /> {/* Empty box for spacing */}
                            <CurrencyTextField
                                variant="outlined"
                                label="Accrued Interest (Schedule A, B, C) - Consolidated"
                                value={calculateAccruedInterestSum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Current Maturity */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Maturity (Schedule A, B) - Current"
                                value={calculateCurrentMaturitySum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <Box /> {/* Empty box for spacing */}
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Maturity (Schedule A, B) - Consolidated"
                                value={calculateCurrentMaturitySum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
                    </>
                )}
    
                {proforma && (
                    <>
                        {/* Current Debt */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Debt (Schedule C) - Current"
                                value={calculateArrayFieldSum('c', 'balance')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <Box /> {/* Empty box for spacing */}
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Debt (Schedule C) - Proforma"
                                value={calculateArrayFieldSum('c', 'balance')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Income Tax Payable */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Income Tax Payable - Current"
                                value={calculateConsolidated('incomeTaxPayable')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Income Tax Payable - Change"
                                value={loanData.balanceSheetProforma?.currentLiabilities?.incomeTaxPayableChange || ""}
                                onChange={(e) =>
                                    updateProformaField(
                                        'incomeTaxPayableChange',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Income Tax Payable - Proforma"
                                value={calculateProforma('incomeTaxPayable')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Real Estate Taxes Due */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Real Estate Taxes Due - Current"
                                value={calculateConsolidated('realEstateTaxesDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Real Estate Taxes Due - Change"
                                value={loanData.balanceSheetProforma?.currentLiabilities?.realEstateTaxesDueChange || ""}
                                onChange={(e) =>
                                    updateProformaField(
                                        'realEstateTaxesDueChange',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Real Estate Taxes Due - Proforma"
                                value={calculateProforma('realEstateTaxesDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Rents Due */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Rents Due - Current"
                                value={calculateConsolidated('rentsDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Rents Due - Change"
                                value={loanData.balanceSheetProforma?.currentLiabilities?.rentsDueChange || ""}
                                onChange={(e) =>
                                    updateProformaField(
                                        'rentsDueChange',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Rents Due - Proforma"
                                value={calculateProforma('rentsDue')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Accrued Interest */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Accrued Interest (Schedule A, B, C) - Current"
                                value={calculateAccruedInterestSum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <Box /> {/* Empty box for spacing */}
                            <CurrencyTextField
                                variant="outlined"
                                label="Accrued Interest (Schedule A, B, C) - Proforma"
                                value={calculateAccruedInterestSum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
    
                        {/* Current Maturity */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Maturity (Schedule A, B) - Current"
                                value={calculateCurrentMaturitySum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <Box /> {/* Empty box for spacing */}
                            <CurrencyTextField
                                variant="outlined"
                                label="Current Maturity (Schedule A, B) - Proforma"
                                value={calculateCurrentMaturitySum()}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Box>
                    </>
                )}
    
                {!consolidated && !proforma && (
                    <>
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Income Tax Payable"
                            value={borrowers[0].balanceSheet?.currentLiabilities?.incomeTaxPayable || ""}
                            onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.incomeTaxPayable', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                            fullWidth
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Real Estate Taxes Due"
                            value={borrowers[0].balanceSheet?.currentLiabilities?.realEstateTaxesDue || ""}
                            onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.realEstateTaxesDue', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                            fullWidth
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Rents Due"
                            value={borrowers[0].balanceSheet?.currentLiabilities?.rentsDue || ""}
                            onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.rentsDue', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                            fullWidth
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Accrued Interest (Schedule A, B, C)"
                            value={calculateAccruedInterestSum()}
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Current Maturity of Non-Current Debt (Schedule A, B)"
                            value={calculateCurrentMaturitySum()}
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </>
                )}
            </Box>
    
            <Typography variant="subtitle1" my={2}>
                Accounts Payable (one year)
            </Typography>
            {borrowers.map((borrower) => (
                <Box key={borrower.id} mb={2}>
                    <Typography variant="subtitle2">{borrower.name}</Typography>
                    {borrower.balanceSheet?.accountsPayable?.map((account, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={account.description}
                                onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                    ...account,
                                    description: e.target.value
                                })}
                                InputProps={{readOnly: consolidated || proforma}}
                                fullWidth
                            />
                            {consolidated && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={account.amount}
                                        InputProps={{readOnly: true}}
                                        fullWidth
                                    />
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Elims"
                                        value={account.elims || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                            ...account,
                                            elims: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                        fullWidth
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Consolidated"
                                        value={(parseFloat(account.amount || 0) - parseFloat(account.elims || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                        fullWidth
                                    />
                                </>
                            )}
                            {proforma && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={(parseFloat(account.amount || 0) - parseFloat(account.elims || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                        fullWidth
                                    />
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Change"
                                        value={account.change || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                            ...account,
                                            change: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                        fullWidth
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Proforma"
                                        value={(parseFloat(account.amount || 0) - parseFloat(account.elims || 0) + parseFloat(account.change || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                        fullWidth
                                    />
                                </>
                            )}
                            {!consolidated && !proforma && (
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Amount"
                                    value={account.amount}
                                    onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                        ...account,
                                        amount: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    })}
                                    fullWidth
                                />
                            )}
                            {!consolidated && (
                                <IconButton
                                    onClick={() => deleteBalanceSheetField(borrower.id, 'accountsPayable', index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            )}
                        </Box>
                    ))}
                    {!consolidated && !proforma && (
                        <Box display="flex" justifyContent="flex-start" mb={2}>
                            <Button variant="contained"
                                    onClick={() => addBalanceSheetField(borrower.id, 'accountsPayable')}>
                                Add Row
                            </Button>
                        </Box>
                    )}
                </Box>
            ))}
            {proforma && (
                <Box mb={2}>
                    {(loanData.balanceSheetProforma?.currentLiabilities?.accountsPayable || []).map((account, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={account.description || ''}
                                onChange={(e) => {
                                    updateProformaBalanceSheetItem(
                                        'currentLiabilities',
                                        'accountsPayable',
                                        index,
                                        'description',
                                        e.target.value
                                    );
                                }}
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Current"
                                value="0"
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Change"
                                value={account.amount || ''}
                                onChange={(e) => {
                                    updateProformaBalanceSheetItem(
                                        'currentLiabilities',
                                        'accountsPayable',
                                        index,
                                        'amount',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    );
                                }}
                                fullWidth
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Proforma"
                                value={parseFloat(account.amount || 0).toFixed(2)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                            <IconButton
                                onClick={() => deleteProformaBalanceSheetItem(
                                    'currentLiabilities',
                                    'accountsPayable',
                                    index
                                )}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    ))}
                    <Box display="flex" justifyContent="flex-start" mb={2}>
                        <Button
                            variant="contained"
                            onClick={() => addProformaBalanceSheetItem(
                                'currentLiabilities',
                                'accountsPayable'
                            )}
                        >
                            Add Row
                        </Button>
                    </Box>
                </Box>
            )}
            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography>Total Current Liabilities:</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {(
                            consolidated ? calculateConsolidatedTotal() :
                                proforma ? calculateProformaTotal() :
                                    calculateArrayFieldSum('c', 'balance') +
                                    calculateCurrentTotal('incomeTaxPayable') +
                                    calculateCurrentTotal('realEstateTaxesDue') +
                                    calculateCurrentTotal('rentsDue') +
                                    calculateAccruedInterestSum() +
                                    calculateCurrentMaturitySum() +
                                    calculateAccountsPayableTotal()
                        ).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                    </Typography>
                </Grid>
            </Box>
        </Grid>
    );
});

export default CurrentLiabilities;