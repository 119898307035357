import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { formatISO } from 'date-fns';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const SubsidiaryAnalysis = observer(() => {
  const {
    loanData,
    updateSubsidiaryAnalysis,
    initializeSubsidiaryAnalysis
  } = loanDetailStore;

  const subsidiaryAnalysis = loanData.subsidiaryAnalysis || {};
  const subsidiaryCount = subsidiaryAnalysis.count || 0;

  useEffect(() => {
    if (!subsidiaryAnalysis.initialized) {
      initializeSubsidiaryAnalysis();
    }
  }, [subsidiaryAnalysis.initialized, initializeSubsidiaryAnalysis]);

  const handleSubsidiaryCountChange = (event) => {
    const count = Number(event.target.value);
    updateSubsidiaryAnalysis('count', count);
    updateSubsidiaryAnalysis('initialized', true);
  };

  const handleSubsidiaryEntityChange = (index, field, value) => {
    updateSubsidiaryAnalysis(`entities.${index}.${field}`, value);
  };

  const renderSubsidiaryEntities = () => {
    return Array(subsidiaryCount)
      .fill()
      .map((_, index) => (
        <Grid container key={index} spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Entity Name"
              value={subsidiaryAnalysis.entities?.[index]?.name || ''}
              onChange={(event) => handleSubsidiaryEntityChange(index, 'name', event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Tax ID"
              value={subsidiaryAnalysis.entities?.[index]?.taxId || ''}
              onChange={(event) => handleSubsidiaryEntityChange(index, 'taxId', event.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      ));
  };

  const renderBalanceSheetRows = () => {
    const balanceSheetFields = [
      { id: 'bsDate', label: 'BS Date', type: 'date' },
      { id: 'costBasis', label: 'Cost Basis', type: 'checkbox' },
      { id: 'ownershipPercentage', label: 'Ownership Percentage', type: 'percentage' },
      { id: 'currentAssets', label: 'Current Assets', type: 'currency' },
      { id: 'currentLiabilities', label: 'Current Liabilities', type: 'currency' },
      { id: 'currentRatio', label: 'Current Ratio', type: 'number' },
      { id: 'totalAssets', label: 'Total Assets', type: 'currency' },
      { id: 'bsExternalDebt', label: 'BS Eliminations on Consolidation', type: 'currency' },
      { id: 'totalLiabilities', label: 'Total Liabilities', type: 'currency' },
      { id: 'netWorth', label: 'Net Worth', type: 'currency' },
      { id: 'debtAssetRatio', label: 'Debt/Asset Ratio', type: 'percentage' },
      { id: 'ownershipNetWorth', label: 'Ownership Net Worth of Entity', type: 'currency' },
    ];

    return balanceSheetFields.map((field) => (
      <TableRow key={field.id}>
        <TableCell>{field.label}</TableCell>
        <TableCell>
          {field.id === 'costBasis' ? (
            "Market"
          ) : field.id === 'bsDate' ? (
            // Get proforma date from balance sheet
            loanData.balanceSheetProforma?.date || ''
          ) : (
            // Get proforma data for other fields
            getProformaValue(field.id)
          )}
        </TableCell>
        {Array(subsidiaryCount).fill().map((_, index) => (
          <TableCell key={index}>
            {field.id === 'bsDate' ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={subsidiaryAnalysis.entities?.[index]?.balanceSheet?.[field.id] ?
                    new Date(subsidiaryAnalysis.entities[index].balanceSheet[field.id]) : null}
                  onChange={(newValue) => {
                    if (newValue) {
                      try {
                        if (newValue instanceof Date && !isNaN(newValue)) {
                          handleSubsidiaryEntityChange(
                            index,
                            `balanceSheet.${field.id}`,
                            formatISO(newValue)
                          );
                        }
                      } catch (error) {
                        console.error('Error formatting date:', error);
                      }
                    }
                  }}
                  format="MM/dd/yyyy"
                  slotProps={{
                    textField: { fullWidth: true, margin: "normal" }
                  }}
                />
              </LocalizationProvider>
            ) : field.id === 'costBasis' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!subsidiaryAnalysis.entities?.[index]?.balanceSheet?.[field.id]}
                    onChange={(event) =>
                      handleSubsidiaryEntityChange(index, `balanceSheet.${field.id}`, event.target.checked)
                    }
                  />
                }
                label="Cost"
              />
            ) : field.id === 'ownershipPercentage' ? (
              <TextField
                type="number"
                value={subsidiaryAnalysis.entities?.[index]?.balanceSheet?.[field.id] || ''}
                onChange={(event) => {
                  const value = Math.min(100, Math.max(0, parseInt(event.target.value) || 0));
                  handleSubsidiaryEntityChange(index, `balanceSheet.${field.id}`, value);
                }}
                inputProps={{ min: 0, max: 100 }}
                fullWidth
              />
            ) : field.id === 'currentRatio' ? (
              // Calculate current ratio dynamically
              (() => {
                const currentAssets = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.currentAssets || 0);
                const currentLiabilities = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.currentLiabilities || 0);

                if (currentLiabilities === 0) return 'N/A';
                return (currentAssets / currentLiabilities).toFixed(2);
              })()
            ) : field.id === 'bsExternalDebt' ? (
              // For BS Eliminations on Consolidation, display nothing in subsidiary columns
              ""
            ) : field.id === 'netWorth' ? (
              // Calculate net worth automatically as total assets - total liabilities
              (() => {
                const totalAssets = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.totalAssets || 0);
                const totalLiabilities = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.totalLiabilities || 0);
                const netWorth = totalAssets - totalLiabilities;

                // Store the calculated value for use in consolidations
                if (subsidiaryAnalysis.entities?.[index]?.balanceSheet) {
                  handleSubsidiaryEntityChange(index, `balanceSheet.netWorth`, netWorth);
                }

                return netWorth.toLocaleString('en-US');
              })()
            ) : field.id === 'debtAssetRatio' ? (
              // Calculate debt/asset ratio dynamically as total liabilities / total assets
              (() => {
                const totalAssets = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.totalAssets || 0);
                const totalLiabilities = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.totalLiabilities || 0);

                if (totalAssets === 0) return 'N/A';

                const debtAssetRatio = (totalLiabilities / totalAssets) * 100;

                // Store the calculated value for use in consolidations
                if (subsidiaryAnalysis.entities?.[index]?.balanceSheet) {
                  handleSubsidiaryEntityChange(index, `balanceSheet.debtAssetRatio`, debtAssetRatio);
                }

                return debtAssetRatio.toFixed(1) + '%';
              })()
            ) : field.id === 'ownershipNetWorth' ? (
              // Calculate ownership net worth as net worth * ownership percentage
              (() => {
                const netWorth = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.netWorth || 0);
                const ownershipPct = parseFloat(subsidiaryAnalysis.entities?.[index]?.balanceSheet?.ownershipPercentage || 0) / 100;
                const ownershipNetWorth = netWorth * ownershipPct;

                // Store the calculated value
                if (subsidiaryAnalysis.entities?.[index]?.balanceSheet) {
                  handleSubsidiaryEntityChange(index, `balanceSheet.ownershipNetWorth`, ownershipNetWorth);
                }

                return ownershipNetWorth.toLocaleString('en-US');
              })()
            ) : field.type === 'currency' ? (<CurrencyTextField
              modifyValueOnWheel={false}
              variant="outlined"
              value={subsidiaryAnalysis.entities?.[index]?.balanceSheet?.[field.id] || ''}
              onChange={(e) =>
                handleSubsidiaryEntityChange(
                  index,
                  `balanceSheet.${field.id}`,
                  parseFloat(e.target.value.replace(/,/g, "")) || ''
                )
              }
              fullWidth
            />
            ) : (
              <TextField
                value={subsidiaryAnalysis.entities?.[index]?.balanceSheet?.[field.id] || ''}
                onChange={(event) =>
                  handleSubsidiaryEntityChange(index, `balanceSheet.${field.id}`, event.target.value)
                }
                fullWidth
              />
            )}
          </TableCell>
        ))}
        <TableCell>{calculateConsolidationByOwnership(field.id)}</TableCell>
      </TableRow>
    ));
  };

  const renderIncomeExpenseRows = () => {
    const incomeExpenseFields = [
      { id: 'incExpDate', label: 'Inc/Exp Date', type: 'date' },
      { id: 'grossFarmIncome', label: 'Gross Farm Income', type: 'currency' },
      { id: 'farmExpense', label: 'Farm Expense', type: 'currency' },
      { id: 'netFarmIncome', label: 'Net Farm Income', type: 'currency' },
      { id: 'nonFarmIncome', label: 'Non Farm Income', type: 'currency' },
      { id: 'depreciation', label: 'Depreciation', type: 'currency' },
      { id: 'interest', label: 'Interest', type: 'currency' },
      { id: 'leases', label: 'Leases', type: 'currency' },
      { id: 'incomeTax', label: 'Income Tax', type: 'currency' },
      { id: 'living', label: 'Living', type: 'currency' },
      { id: 'incExpElimOnCon', label: 'Inc/Exp Elim on Consolidation', type: 'currency' },
      { id: 'cfa', label: 'Cash Flow Available', type: 'currency' },
      { id: 'debtService', label: 'Debt Service', type: 'currency' },
      { id: 'tdc', label: 'Total Debt Coverage', type: 'number' },
    ];

    return incomeExpenseFields.map((field) => (
      <TableRow key={field.id}>
        <TableCell>{field.label}</TableCell>
        <TableCell>
          {/* Get proforma income & expense data */}
          {getProformaIncomeExpenseValue(field.id)}
        </TableCell>
        {Array(subsidiaryCount).fill().map((_, index) => (
          <TableCell key={index}>
            {field.id === 'incExpDate' ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={subsidiaryAnalysis.entities?.[index]?.incomeExpense?.[field.id] ?
                    new Date(subsidiaryAnalysis.entities[index].incomeExpense[field.id]) : null}
                  onChange={(newValue) => {
                    if (newValue) {
                      try {
                        if (newValue instanceof Date && !isNaN(newValue)) {
                          handleSubsidiaryEntityChange(
                            index,
                            `incomeExpense.${field.id}`,
                            formatISO(newValue)
                          );
                        }
                      } catch (error) {
                        console.error('Error formatting date:', error);
                      }
                    }
                  }}
                  format="MM/dd/yyyy"
                  slotProps={{
                    textField: { fullWidth: true, margin: "normal" }
                  }}
                />
              </LocalizationProvider>
            ) : field.id === 'netFarmIncome' ? (
              // Calculate net farm income automatically as gross farm income - farm expense
              (() => {
                const grossFarmIncome = parseFloat(subsidiaryAnalysis.entities?.[index]?.incomeExpense?.grossFarmIncome || 0);
                const farmExpense = parseFloat(subsidiaryAnalysis.entities?.[index]?.incomeExpense?.farmExpense || 0);
                const netFarmIncome = grossFarmIncome - farmExpense;

                // Store the calculated value for use in consolidations
                if (subsidiaryAnalysis.entities?.[index]?.incomeExpense) {
                  handleSubsidiaryEntityChange(index, `incomeExpense.netFarmIncome`, netFarmIncome);
                }

                return netFarmIncome.toLocaleString('en-US');
              })()
            ) : field.id === 'incExpElimOnCon' ? (
              // For Inc/Exp Elim on Consolidation, display nothing in subsidiary columns
              ""
            ) : field.id === 'tdc' ? (
              // Calculate Total Debt Coverage as CFA / Debt Service
              (() => {
                const cfa = parseFloat(subsidiaryAnalysis.entities?.[index]?.incomeExpense?.cfa || 0);
                const debtService = parseFloat(subsidiaryAnalysis.entities?.[index]?.incomeExpense?.debtService || 0);
                
                if (debtService === 0) return 'N/A';
                const tdc = cfa / debtService;
                
                // Store the calculated value
                if (subsidiaryAnalysis.entities?.[index]?.incomeExpense) {
                  handleSubsidiaryEntityChange(index, `incomeExpense.tdc`, tdc);
                }
                
                return tdc.toFixed(2);
              })()
            ) : field.type === 'currency' ? (
            <CurrencyTextField
              modifyValueOnWheel={false}
              variant="outlined"
              value={subsidiaryAnalysis.entities?.[index]?.incomeExpense?.[field.id] || ''}
              onChange={(e) =>
                handleSubsidiaryEntityChange(
                  index,
                  `incomeExpense.${field.id}`,
                  parseFloat(e.target.value.replace(/,/g, "")) || ''
                )
              }
              fullWidth
            />
            ) : (
              <TextField
                value={subsidiaryAnalysis.entities?.[index]?.incomeExpense?.[field.id] || ''}
                onChange={(event) =>
                  handleSubsidiaryEntityChange(index, `incomeExpense.${field.id}`, event.target.value)
                }
                fullWidth
              />
            )}
          </TableCell>
        ))}
        <TableCell>{calculateConsolidationByOwnership(field.id)}</TableCell>
      </TableRow>
    ));
  };

  // Function to get proforma balance sheet values
  const getProformaValue = (fieldId) => {
    // Map to actual properties in proforma balance sheet
    const proformaMapping = {
      'currentAssets': loanData.balanceSheetProforma?.totalCurrentAssets || 0,
      'currentLiabilities': loanData.balanceSheetProforma?.totalCurrentLiabilities || 0,
      'totalAssets': loanData.balanceSheetProforma?.totalAssets || 0,
      'totalLiabilities': loanData.balanceSheetProforma?.totalLiabilities || 0,
      'netWorth': loanData.balanceSheetProforma?.netWorth || 0,
      'currentRatio': calculateProformaCurrentRatio(),
      // Add other mappings as needed
    };

    return proformaMapping[fieldId] || '';
  };


  const calculateProformaCurrentRatio = () => {
    const currentAssets = loanData.balanceSheetProforma?.totalCurrentAssets || 0;
    const currentLiabilities = loanData.balanceSheetProforma?.totalCurrentLiabilities || 0;

    if (currentLiabilities === 0) return 'N/A';
    return (currentAssets / currentLiabilities).toFixed(2);
  };

  const calculateProformaNetFarmIncome = () => {
    const grossFarmIncome = loanData.incomeAndExpensesProforma?.totalGrossFarmIncome || 0;
    const farmExpense = loanData.incomeAndExpensesProforma?.totalFarmExpenses || 0;
    return grossFarmIncome - farmExpense;
  };

  // Function to get proforma income & expense values
  const getProformaIncomeExpenseValue = (fieldId) => {
    // Map to actual properties in proforma income & expense
    const proformaMapping = {
      'grossFarmIncome': loanData.incomeAndExpensesProforma?.totalGrossFarmIncome || 0,
      'farmExpense': loanData.incomeAndExpensesProforma?.totalFarmExpenses || 0,
      'netFarmIncome': calculateProformaNetFarmIncome(),
      // Add other mappings as needed
    };
    
    return proformaMapping[fieldId] || '';
  };

  const calculateConsolidationByOwnership = (fieldId) => {
    // For date fields, return empty string
    if (fieldId === 'bsDate' || fieldId === 'incExpDate') return '';
  
    // For cost basis (checkbox), return empty string
    if (fieldId === 'costBasis') return '';
  
    // Special case for BS Eliminations on Consolidation
    if (fieldId === 'bsExternalDebt') {
      return (
        <CurrencyTextField
          modifyValueOnWheel={false}
          variant="outlined"
          value={subsidiaryAnalysis.bsEliminations || ''}
          onChange={(e) => {
            const value = parseFloat(e.target.value.replace(/,/g, "")) || 0;
            updateSubsidiaryAnalysis('bsEliminations', value);
          }}
          fullWidth
        />
      );
    }
    
    // Special case for Inc/Exp Elim on Consolidation
    if (fieldId === 'incExpElimOnCon') {
      return (
        <CurrencyTextField
          modifyValueOnWheel={false}
          variant="outlined"
          value={subsidiaryAnalysis.incExpEliminations || ''}
          onChange={(e) => {
            const value = parseFloat(e.target.value.replace(/,/g, "")) || 0;
            updateSubsidiaryAnalysis('incExpEliminations', value);
          }}
          fullWidth
        />
      );
    }
    
    if (fieldId === 'ownershipNetWorth') return '';
  
    const consolidatedValue = typeof getProformaValue(fieldId) === 'number' ?
      getProformaValue(fieldId) :
      typeof getProformaIncomeExpenseValue(fieldId) === 'number' ?
        getProformaIncomeExpenseValue(fieldId) : 0;
  
    // Sum up subsidiary values weighted by ownership percentage
    const subsidiaryTotal = subsidiaryAnalysis.entities?.reduce((sum, entity, idx) => {
      let value = 0;
  
      // Get value from balance sheet or income/expense
      if (entity?.balanceSheet?.[fieldId] !== undefined) {
        value = parseFloat(entity.balanceSheet[fieldId] || 0);
      } else if (entity?.incomeExpense?.[fieldId] !== undefined) {
        value = parseFloat(entity.incomeExpense[fieldId] || 0);
      }
  
      // Apply ownership percentage (divide by 100 to convert percentage to decimal)
      const ownershipPct = parseFloat(entity?.balanceSheet?.ownershipPercentage || 0) / 100;
  
      return sum + (value * ownershipPct);
    }, 0) || 0;
  
    let result = parseFloat(consolidatedValue) + subsidiaryTotal;
  
    // Subtract BS Eliminations from Net Worth
    if (fieldId === 'netWorth' && subsidiaryAnalysis.bsEliminations) {
      result -= parseFloat(subsidiaryAnalysis.bsEliminations);
    }
    
    // Subtract Inc/Exp Eliminations from CFA
    if (fieldId === 'cfa' && subsidiaryAnalysis.incExpEliminations) {
      result -= parseFloat(subsidiaryAnalysis.incExpEliminations);
    }
  
    return result.toLocaleString('en-US');
  };

  return (
    <Box>
      <Typography variant="h6">Subsidiary Analysis</Typography>
      <Box mt={2}>
        <TextField
          select
          label="Total Number of Subsidiary Entities"
          value={subsidiaryCount}
          onChange={handleSubsidiaryCountChange}
          fullWidth
          disabled={subsidiaryAnalysis.initialized}
        >
          {Array(100)
            .fill()
            .map((_, index) => (
              <MenuItem key={index} value={index}>
                {index}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      <Box mt={2}>{renderSubsidiaryEntities()}</Box>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Balance Sheet</TableCell>
                <TableCell>Borrowers</TableCell>
                {Array(subsidiaryCount).fill().map((_, index) => (
                  <TableCell key={index}>{subsidiaryAnalysis.entities?.[index]?.name || `Entity ${index + 1}`}</TableCell>
                ))}
                <TableCell>Consolidation by % Ownership</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderBalanceSheetRows()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Income/Expense</TableCell>
                <TableCell>Borrowers</TableCell>
                {Array(subsidiaryCount).fill().map((_, index) => (
                  <TableCell key={index}>{subsidiaryAnalysis.entities?.[index]?.name || `Entity ${index + 1}`}</TableCell>
                ))}
                <TableCell>Consolidation by % Ownership</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderIncomeExpenseRows()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
});

export default SubsidiaryAnalysis;