import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import loanDetailStore from '../store';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const CurrentAssets = observer(({ borrowers, consolidated, proforma }) => {
    const calculateProformaOtherCurrentAssetsTotal = () => {
        return (loanData.balanceSheetProforma?.otherCurrentAssets || []).reduce((sum, asset) => {
            return sum + (parseFloat(asset.amount) || 0);
        }, 0);
    };


    const calculateScheduleGValue = () => {
        return borrowers.reduce((sum, borrower) => {
            const scheduleGItems = borrower.bsas?.g || [];
            return sum + scheduleGItems.reduce((itemSum, item) => {
                const amount = parseFloat(item.amount) || 0;
                const price = parseFloat(item.price) || 0;
                return itemSum + (amount * price);
            }, 0);
        }, 0);
    };

    const calculateScheduleIValue = () => {
        return borrowers.reduce((sum, borrower) => {
            const scheduleIItems = borrower.bsas?.i || [];
            return sum + scheduleIItems.reduce((itemSum, item) => {
                const number = parseInt(item.number) || 0;
                const price = parseFloat(item.price) || 0;
                return itemSum + (number * price);
            }, 0);
        }, 0);
    };

    const calculateScheduleFValue = () => {
        return borrowers.reduce((sum, borrower) => {
            const scheduleFItems = borrower.bsas?.f || [];
            return sum + scheduleFItems.reduce((itemSum, item) => {
                const acres = parseFloat(item.acres) || 0;
                const costPerAcre = parseFloat(item.costPerAcre) || 0;
                return itemSum + (acres * costPerAcre);
            }, 0);
        }, 0);
    };

    const calculateOtherCurrentAssetsTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherCurrentAssets = borrower.balanceSheet?.otherCurrentAssets || [];
            const otherCurrentAssetsSum = otherCurrentAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset?.amount || 0);
                return innerSum + value;
            }, 0);
            return sum + otherCurrentAssetsSum;
        }, 0);
    };

    const calculateCurrentTotal = (field) => {
        return borrowers.reduce((sum, borrower) => {
            const value = parseFloat(borrower.balanceSheet?.currentAssets?.[field]) || 0;
            return sum + value;
        }, 0);
    };

    const calculateElims = (field) => {
        return parseFloat(loanData.consolidatedBalance?.currentAssets?.[`${field}Elims`] || 0);
    };

    const calculateConsolidated = (field) => {
        return calculateCurrentTotal(field) - calculateElims(field);
    };

    const calculateArrayFieldSum = (schedule, field) => {
        return (borrowers || []).reduce((sum, borrower) => {
            const array = (borrower?.bsas || {})[schedule] || [];
            const arraySum = array.reduce((innerSum, item) => {
                const value = parseFloat(item?.[field] || 0);
                return innerSum + value;
            }, 0);
            return sum + arraySum;
        }, 0);
    };

    const {
        addBalanceSheetField,
        updateBalanceSheetField,
        deleteBalanceSheetField,
        updateBorrowerBalanceSheet,
        loanData,
        updateBalanceSheetProformaData,
        deleteBalanceSheetProformaItem,
        addBalanceSheetProformaItem
    } = loanDetailStore;

    const updateConsolidatedField = (field, value) => {
        loanDetailStore.updateLoanData({
            consolidatedBalance: {
                ...loanDetailStore.loanData.consolidatedBalance,
                currentAssets: {
                    ...loanDetailStore.loanData.consolidatedBalance?.currentAssets,
                    [field]: value,
                },
            },
        });
    };

    
    const calculateOtherCurrentAssetsElimsTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherCurrentAssets = borrower.balanceSheet?.otherCurrentAssets || [];
            const otherCurrentAssetsSum = otherCurrentAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset?.elims || 0);
                return innerSum + value;
            }, 0);
            return sum + otherCurrentAssetsSum;
        }, 0);
    };

    const calculateOtherCurrentAssetsChangeTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherCurrentAssets = borrower.balanceSheet?.otherCurrentAssets || [];
            const otherCurrentAssetsSum = otherCurrentAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset?.change || 0);
                return innerSum + value;
            }, 0);
            return sum + otherCurrentAssetsSum;
        }, 0);
    }

    const calculateChangeSum = () => {
        const currentAssets = loanDetailStore.loanData?.balanceSheetProforma?.currentAssets || {};
        return Object.keys(currentAssets).reduce((innerSum, key) => {
            if (key.includes('Change')) {
                return innerSum + (parseFloat(currentAssets[key]) || 0);
            }
            return innerSum;
        }, 0);
    }

    const calculateProforma = (field) => {
        return calculateConsolidated(field) + (loanData.balanceSheetProforma?.currentAssets?.[`${field}Change`] || 0);
    };

    const updateProformaField = (field, value) => {
        loanDetailStore.updateLoanData({
            balanceSheetProforma: {
                ...loanDetailStore.loanData.balanceSheetProforma,
                currentAssets: {
                    ...loanDetailStore.loanData.balanceSheetProforma?.currentAssets,
                    [field]: value,
                },
            },
        });
    };

    const grandCurrentTotal = () => {
        const total = calculateCurrentTotal('cash') +
            calculateCurrentTotal('savings') +
            calculateCurrentTotal('marketableBondsAndSecurities') +
            calculateCurrentTotal('cashValueLifeInsurance') +
            calculateCurrentTotal('prepaidExpensesAndSupplies') +
            calculateArrayFieldSum('d', 'amount') +
            calculateArrayFieldSum('e', 'principal') -
            calculateArrayFieldSum('e', 'interest') +
            calculateScheduleGValue() +
            calculateScheduleFValue() +
            calculateScheduleIValue() +
            calculateOtherCurrentAssetsTotal();

        if (consolidated) {
            return total -
                calculateElims('cash') -
                calculateElims('savings') -
                calculateElims('marketableBondsAndSecurities') -
                calculateElims('cashValueLifeInsurance') -
                calculateElims('prepaidExpensesAndSupplies') -
                (loanData.consolidatedBalance?.currentAssets?.accountsReceivableScheduleDElims || 0) -
                (loanData.consolidatedBalance?.currentAssets?.currentPortionNotesReceivableScheduleEElims || 0) -
                (loanData.consolidatedBalance?.currentAssets?.harvestedCropsScheduleGElims || 0) -
                (loanData.consolidatedBalance?.currentAssets?.growingCropsAtCostScheduleFElims || 0) -
                (loanData.consolidatedBalance?.currentAssets?.livestockHeldForSaleAtMarketScheduleIElims || 0) -
                calculateOtherCurrentAssetsElimsTotal();
        } else if (proforma) {
            return total +
                calculateChangeSum() +
                calculateOtherCurrentAssetsChangeTotal() +
                calculateProformaOtherCurrentAssetsTotal(); // Add this line
        } else {
            return total;
        }
    };

return (
    <Grid item xs={12} md={6}>
        <Typography variant="subtitle1" my={2}>
            Current Assets
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
            {consolidated && (
                <>
                    {/* Cash */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash - Current"
                            value={calculateCurrentTotal('cash')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Cash - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.cashElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'cashElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash - Consolidated"
                            value={calculateConsolidated('cash')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Savings */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Savings - Current"
                            value={calculateCurrentTotal('savings')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Savings - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.savingsElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'savingsElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Savings - Consolidated"
                            value={calculateConsolidated('savings')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Marketable Bonds and Securities */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Marketable Bonds and Securities - Current"
                            value={calculateCurrentTotal('marketableBondsAndSecurities')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Marketable Bonds and Securities - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.marketableBondsAndSecuritiesElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'marketableBondsAndSecuritiesElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Marketable Bonds and Securities - Consolidated"
                            value={calculateConsolidated('marketableBondsAndSecurities')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Cash Value Life Insurance */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash Value Life Insurance - Current"
                            value={calculateCurrentTotal('cashValueLifeInsurance')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Cash Value Life Insurance - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.cashValueLifeInsuranceElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'cashValueLifeInsuranceElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash Value Life Insurance - Consolidated"
                            value={calculateConsolidated('cashValueLifeInsurance')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Prepaid Expenses and Supplies */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Prepaid Expenses and Supplies - Current"
                            value={calculateCurrentTotal('prepaidExpensesAndSupplies')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Prepaid Expenses and Supplies - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.prepaidExpensesAndSuppliesElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'prepaidExpensesAndSuppliesElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Prepaid Expenses and Supplies - Consolidated"
                            value={calculateConsolidated('prepaidExpensesAndSupplies')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Accounts Receivable (Schedule D) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Accounts Receivable (Schedule D) - Current"
                            value={calculateArrayFieldSum('d', 'amount')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Accounts Receivable (Schedule D) - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.accountsReceivableScheduleDElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'accountsReceivableScheduleDElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Accounts Receivable (Schedule D) - Consolidated"
                            value={
                                calculateArrayFieldSum('d', 'amount') -
                                (loanData.consolidatedBalance?.currentAssets?.accountsReceivableScheduleDElims || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Current Portion Notes Receivable (Schedule E) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Current Portion Notes Receivable (Schedule E) - Current"
                            value={calculateArrayFieldSum('e', 'value') - calculateArrayFieldSum('e', 'interest')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Current Portion Notes Receivable (Schedule E) - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.currentPortionNotesReceivableScheduleEElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'currentPortionNotesReceivableScheduleEElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Current Portion Notes Receivable (Schedule E) - Consolidated"
                            value={
                                calculateArrayFieldSum('e', 'value') -
                                calculateArrayFieldSum('e', 'interest') -
                                (loanData.consolidatedBalance?.currentAssets?.currentPortionNotesReceivableScheduleEElims || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Harvested Crops (Schedule G) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Harvested Crops (Schedule G) - Current"
                            value={calculateScheduleGValue()}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Harvested Crops (Schedule G) - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.harvestedCropsScheduleGElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'harvestedCropsScheduleGElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Harvested Crops (Schedule G) - Consolidated"
                            value={
                                calculateScheduleGValue() -
                                (loanData.consolidatedBalance?.currentAssets?.harvestedCropsScheduleGElims || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Growing Crops at Cost (Schedule F) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Growing Crops at Cost (Schedule F) - Current"
                            value={calculateScheduleFValue()}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Growing Crops at Cost (Schedule F) - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.growingCropsAtCostScheduleFElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'growingCropsAtCostScheduleFElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Growing Crops at Cost (Schedule F) - Consolidated"
                            value={
                                calculateScheduleFValue() -
                                (loanDetailStore.loanData.consolidatedBalance?.currentAssets?.growingCropsAtCostScheduleFElims || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Livestock held for sale (at market) (Schedule I) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Livestock held for sale (at market) (Schedule I) - Current"
                            value={calculateScheduleIValue()}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Livestock held for sale (at market) (Schedule I) - Elims"
                            value={loanDetailStore.loanData.consolidatedBalance?.currentAssets?.livestockHeldForSaleAtMarketScheduleIElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'livestockHeldForSaleAtMarketScheduleIElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Livestock held for sale (at market) (Schedule I) - Consolidated"
                            value={
                                calculateScheduleIValue() -
                                (loanDetailStore.loanData.consolidatedBalance?.currentAssets?.livestockHeldForSaleAtMarketScheduleIElims || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>
                </>
            )}

            {proforma && (
                <>
                    {/* Cash */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash - Current"
                            value={calculateConsolidated('cash')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Cash - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.cashChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'cashChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash - Proforma"
                            value={calculateProforma('cash')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Savings */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Savings - Current"
                            value={calculateConsolidated('savings')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Savings - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.savingsChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'savingsChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Savings - Proforma"
                            value={calculateProforma('savings')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Marketable Bonds and Securities */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Marketable Bonds and Securities - Current"
                            value={calculateConsolidated('marketableBondsAndSecurities')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Marketable Bonds and Securities - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.marketableBondsAndSecuritiesChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'marketableBondsAndSecuritiesChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Marketable Bonds and Securities - Proforma"
                            value={calculateProforma('marketableBondsAndSecurities')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Cash Value Life Insurance */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash Value Life Insurance - Current"
                            value={calculateConsolidated('cashValueLifeInsurance')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Cash Value Life Insurance - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.cashValueLifeInsuranceChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'cashValueLifeInsuranceChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Cash Value Life Insurance - Proforma"
                            value={calculateProforma('cashValueLifeInsurance')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Prepaid Expenses and Supplies */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Prepaid Expenses and Supplies - Current"
                            value={calculateConsolidated('prepaidExpensesAndSupplies')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Prepaid Expenses and Supplies - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.prepaidExpensesAndSuppliesChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'prepaidExpensesAndSuppliesChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Prepaid Expenses and Supplies - Proforma"
                            value={calculateProforma('prepaidExpensesAndSupplies')}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Accounts Receivable (Schedule D) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Accounts Receivable (Schedule D) - Current"
                            value={calculateArrayFieldSum('d', 'amount')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Accounts Receivable (Schedule D) - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.accountsReceivableScheduleDChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'accountsReceivableScheduleDChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Accounts Receivable (Schedule D) - Proforma"
                            value={
                                calculateArrayFieldSum('d', 'amount') +
                                (borrowers[0].balanceSheet?.currentAssets?.accountsReceivableScheduleDElims || 0) +
                                (loanData.balanceSheetProforma?.currentAssets?.accountsReceivableScheduleDChange || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Current Portion Notes Receivable (Schedule E) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Current Portion Notes Receivable (Schedule E) - Current"
                            value={calculateArrayFieldSum('e', 'value') - calculateArrayFieldSum('e', 'interest')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Current Portion Notes Receivable (Schedule E) - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.currentPortionNotesReceivableScheduleEChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'currentPortionNotesReceivableScheduleEChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Current Portion Notes Receivable (Schedule E) - Proforma"
                            value={
                                calculateArrayFieldSum('e', 'value') -
                                calculateArrayFieldSum('e', 'interest') +
                                (borrowers[0].balanceSheet?.currentAssets?.currentPortionNotesReceivableScheduleEElims || 0) +
                                (loanData.balanceSheetProforma?.currentAssets?.currentPortionNotesReceivableScheduleEChange || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Harvested Crops (Schedule G) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Harvested Crops (Schedule G) - Current"
                            value={calculateScheduleGValue()}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Harvested Crops (Schedule G) - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.harvestedCropsScheduleGChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'harvestedCropsScheduleGChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Harvested Crops (Schedule G) - Proforma"
                            value={
                                calculateScheduleGValue() +
                                (borrowers[0].balanceSheet?.currentAssets?.harvestedCropsScheduleGElims || 0) +
                                (loanData.balanceSheetProforma?.currentAssets?.harvestedCropsScheduleGChange || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Growing Crops at Cost (Schedule F) */}


                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Growing Crops at Cost (Schedule F) - Current"
                            value={calculateScheduleFValue()}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Growing Crops at Cost (Schedule F) - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.growingCropsAtCostScheduleFChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'growingCropsAtCostScheduleFChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Growing Crops at Cost (Schedule F) - Proforma"
                            value={
                                calculateScheduleFValue() +
                                (borrowers[0].balanceSheet?.currentAssets?.growingCropsAtCostScheduleFElims || 0) +
                                (loanData.balanceSheetProforma?.currentAssets?.growingCropsAtCostScheduleFChange || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                    {/* Livestock held for sale (at market) (Schedule I) */}
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Livestock held for sale (at market) (Schedule I) - Current"
                            value={calculateScheduleIValue()}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Livestock held for sale (at market) (Schedule I) - Change"
                            value={loanData.balanceSheetProforma?.currentAssets?.livestockHeldForSaleAtMarketScheduleIChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'livestockHeldForSaleAtMarketScheduleIChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Livestock held for sale (at market) (Schedule I) - Proforma"
                            value={
                                calculateScheduleIValue() +
                                (borrowers[0].balanceSheet?.currentAssets?.livestockHeldForSaleAtMarketScheduleIElims || 0) +
                                (loanData.balanceSheetProforma?.currentAssets?.livestockHeldForSaleAtMarketScheduleIChange || 0)
                            }
                            InputProps={{ readOnly: true }}
                        />
                    </Box>

                </>
            )}

            {!consolidated && !proforma && (
                <>
                    <CurrencyTextField
                        modifyValueOnWheel={false}
                        variant="outlined"
                        label="Cash"
                        value={borrowers[0].balanceSheet?.currentAssets?.cash || ""}
                        onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentAssets.cash', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                    />
                    <CurrencyTextField
                        modifyValueOnWheel={false}
                        variant="outlined"
                        label="Savings"
                        value={borrowers[0].balanceSheet?.currentAssets?.savings || ""}
                        onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentAssets.savings', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                    />
                    <CurrencyTextField
                        modifyValueOnWheel={false}
                        variant="outlined"
                        label="Marketable Bonds and Securities"
                        value={borrowers[0].balanceSheet?.currentAssets?.marketableBondsAndSecurities || ""}
                        onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentAssets.marketableBondsAndSecurities', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                    />
                    <CurrencyTextField
                        modifyValueOnWheel={false}
                        variant="outlined"
                        label="Cash Value Life Insurance"
                        value={borrowers[0].balanceSheet?.currentAssets?.cashValueLifeInsurance || ""}
                        onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentAssets.cashValueLifeInsurance', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                    />
                    <CurrencyTextField
                        modifyValueOnWheel={false}
                        variant="outlined"
                        label="Prepaid Expenses and Supplies"
                        value={borrowers[0].balanceSheet?.currentAssets?.prepaidExpensesAndSupplies || ""}
                        onChange={(e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentAssets.prepaidExpensesAndSupplies', parseFloat(e.target.value.replace(/,/g, "")) || "")}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Accounts Receivable (Schedule D)"
                        value={calculateArrayFieldSum('d', 'amount')}
                        InputProps={{ readOnly: true }}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Current Portion Notes Receivable (Schedule E)"
                        value={calculateArrayFieldSum('e', 'value') - calculateArrayFieldSum('e', 'interest')}
                        InputProps={{ readOnly: true }}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Harvested Crops (Schedule G)"
                        value={calculateScheduleGValue()}
                        InputProps={{ readOnly: true }}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Growing Crops at Cost (Schedule F)"
                        value={calculateScheduleFValue()}
                        InputProps={{ readOnly: true }}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Livestock held for sale (at market) (Schedule I)"
                        value={calculateScheduleIValue()}
                        InputProps={{ readOnly: true }}
                    />
                </>
            )}
        </Box>

        <Typography variant="subtitle1" my={2}>
            Other Current Assets
        </Typography>
        {borrowers.map((borrower) => (
            <Box key={borrower.id} mb={2}>
                <Typography variant="subtitle2">{borrower.name}</Typography>
                {(borrower.balanceSheet?.otherCurrentAssets || []).map((asset, index) => (
                    <Box key={index} display="flex" gap={2} mb={2}>
                        <TextField
                            label="Description"
                            value={proforma ? (asset.proforma?.description || asset.description) : asset.description}
                            onChange={(e) => {
                                if (proforma) {
                                    updateBalanceSheetProformaData(
                                        borrower.id,
                                        'otherCurrentAssets',
                                        index,
                                        'description',
                                        e.target.value
                                    );
                                } else {
                                    updateBalanceSheetField(borrower.id, 'otherCurrentAssets', index, {
                                        ...asset,
                                        description: e.target.value
                                    });
                                }
                            }}
                            InputProps={{ readOnly: consolidated }}
                        />
                        {consolidated && (
                            <>
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Current"
                                    value={asset.amount}
                                    InputProps={{ readOnly: true }}
                                />
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Elims"
                                    value={asset.elims || ""}
                                    onChange={(e) => updateBalanceSheetField(borrower.id, 'otherCurrentAssets', index, {
                                        ...asset,
                                        elims: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    })}
                                />
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Consolidated"
                                    value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0)).toFixed(2)}
                                    InputProps={{ readOnly: true }}
                                />
                            </>
                        )}
                            {proforma && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Change"
                                        value={asset.proforma?.amount || ""}
                                        onChange={(e) => updateBalanceSheetProformaData(
                                            borrower.id,
                                            'otherCurrentAssets',
                                            index,
                                            'amount',
                                            parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        )}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Proforma"
                                        value={(
                                            parseFloat(asset.amount || 0) -
                                            parseFloat(asset.elims || 0) +
                                            parseFloat(asset.proforma?.amount || 0)
                                        ).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                </>
                            )}

                        {!consolidated && !proforma && (
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Amount"
                                value={asset.amount}
                                onChange={(e) => updateBalanceSheetField(borrower.id, 'otherCurrentAssets', index, {
                                    ...asset,
                                    amount: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                })}
                            />
                        )}
                        {!consolidated && (
                            <IconButton
                                onClick={() => {
                                    if (proforma) {
                                        deleteBalanceSheetProformaItem(borrower.id, 'otherCurrentAssets', index);
                                    } else {
                                        deleteBalanceSheetField(borrower.id, 'otherCurrentAssets', index);
                                    }
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                ))}
                {!consolidated && !proforma && (
                    <Box display="flex" justifyContent="flex-start" mb={2}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (proforma) {
                                    addBalanceSheetProformaItem('otherCurrentAssets');
                                } else {
                                    addBalanceSheetField(borrower.id, 'otherCurrentAssets');
                                }
                            }}
                        >
                            Add Row
                        </Button>
                    </Box>
                )}
            </Box>
        ))}
        {proforma && (
        
        <Box display="flex" justifyContent="flex-start" mb={2}>
            <Button
                variant="contained"
                onClick={() => {addBalanceSheetProformaItem('otherCurrentAssets');}}
            >
                Add Row
            </Button>
        </Box>
    )}
        <Box display="flex" flexDirection="row" gap={2} my={2}>
            <Grid item xs={12} md={6}>
                <Typography>Total Current Assets:</Typography>
            </Grid>
            <Grid item xs={12} md={6} align="right">
                <Typography>
                    {(grandCurrentTotal()).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </Typography>
            </Grid>
        </Box>
    </Grid>
);
});
export default CurrentAssets;