import { Document, Collection } from 'firestorter';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { doc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

class LoanStore {
  loanDoc = null;
  originatorDoc = null;
  userName = '';
  borrowersCollection = null;

  constructor() {
    makeAutoObservable(this, {
      applicationDate: computed,
      mostRecentYear: computed,
      status: computed,
      hasLoanDoc: computed,
      useOfFundsTotal: computed,
      originatorInfo: computed,
      selectedBorrower: computed,
      incomeAndExpensesTotal: computed,
      farmExpensesTotal: computed,
      nonFarmIncomeTotal: computed,
      netFarmIncome: computed,
      totalPreTaxIncome: computed,
      totalCashFlowAvailable: computed
    });
  }

  // In loan-store.js
  initializeLoan(loanId) {
    this.loanDoc = new Document(`loans/${loanId}`, {
      mode: 'auto', // Changed from 'on' to 'auto'
      debug: true,
      debugName: 'LoanDocument'
    });
  
    this.originatorDoc = new Document(() => {
      const lenderId = this.loanDoc?.data?.lending_organization?.id;
      return lenderId ? `organizations/${lenderId}` : null;
    }, {
      mode: 'auto', // Changed from 'on' to 'auto'
      debug: true,
      debugName: 'OriginatorDocument'
    });
  
    this.borrowersCollection = new Collection(() =>
      this.loanDoc ? `loans/${loanId}/borrowers` : null,
      {
        mode: 'auto', // Changed from 'on' to 'auto'
        debug: true,
        debugName: 'BorrowersCollection'
      }
    );
  }

  get originatorInfo() {
    if (!this.originatorDoc?.hasData) return null;

    const data = this.originatorDoc.data;
    return {
      name: data?.name || '',
      displayName: data?.display_name || '',
      streetAddress: data?.street_address || '',
      city: data?.city || '',
      state: data?.state || '',
      phoneNumber: data?.phone_number || '',
      faxNumber: data?.fax_number || ''
    };
  }

  // Computed getters
  get applicationDate() {
    return this.loanDoc?.data?.applicationDate;
  }

  get mostRecentYear() {
    return this.loanDoc?.data?.mostRecentYear;
  }

  get status() {
    return this.loanDoc?.data?.status || 'Pending';
  }

  get hasLoanDoc() {
    return this.loanDoc?.hasData || false;
  }

  get useOfFundsTotal() {
    const useOfFunds = this.loanDoc?.data?.application?.useOfFunds || [];
    return useOfFunds.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
  }

  // Actions
  async fetchUserName(userId) {
    try {
      const userDocRef = doc(db, 'Users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        runInAction(() => {
          this.userName = userDoc.data().display_name;
        });
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  }

  addUseOfFunds() {
    const useOfFunds = this.loanDoc?.data?.application?.useOfFunds || [];
    this.loanDoc.update({
      'application.useOfFunds': [...useOfFunds, { loanPurpose: '', description: '', amount: '' }]
    });
  }

  removeUseOfFunds(index) {
    const useOfFunds = [...(this.loanDoc?.data?.application?.useOfFunds || [])];
    useOfFunds.splice(index, 1);
    this.loanDoc.update({
      'application.useOfFunds': useOfFunds
    });
  }

  // Borrowers Collection methods
  get borrowers() {
    return this.borrowersCollection?.docs || [];
  }

  async addNewBorrower() {
    if (!this.loanDoc?.id) return;
  
    try {
      // Get the loan ID
      const loanId = this.loanDoc.id;
      
      // Create the borrower data
      const borrowerData = {
        type: '',
        is_principal: this.borrowers.length === 0,
        createdAt: serverTimestamp()
      };
      
      // Get a reference to the borrowers subcollection
      const borrowersCollectionRef = collection(db, `loans/${loanId}/borrowers`);
      
      // Add the document directly using Firebase v9 API
      await addDoc(borrowersCollectionRef, borrowerData);
      
      // Force a refresh of the borrowersCollection if needed
      // This may not be necessary if your Collection is set to auto-refresh
      if (this.borrowersCollection) {
        this.borrowersCollection.refresh();
      }
      
      console.log("New borrower added successfully");
    } catch (error) {
      console.error("Error adding borrower:", error);
    }
  }

  async removeBorrower(borrowerId) {
    const borrowerDoc = this.borrowersCollection.docs.find(doc => doc.id === borrowerId);
    if (borrowerDoc) {
      await borrowerDoc.delete();
    }
  }

  // General loan data updates
  updateLoanApplication(field, value) {
    if (!this.loanDoc) return;
    this.loanDoc.update({
      application: {
        ...this.loanDoc.data.application,
        [field]: value
      }
    });
  }
  get selectedBorrower() {
    if (!this.selectedBorrowerId || !this.borrowersCollection) return null;
    return this.borrowersCollection.docs.find(doc => doc.id === this.selectedBorrowerId);
  }

  setSelectedBorrowerId(id) {
    this.selectedBorrowerId = id;
  }

  get incomeAndExpensesTotal() {
    if (!this.selectedBorrower?.data) return 0;
    const data = this.selectedBorrower.data;
    return (parseFloat(data.netPurchasedLS) || 0) +
           (parseFloat(data.raisedFarmProducts) || 0) +
           (parseFloat(data.cooperativeDistributions) || 0) +
           (parseFloat(data.agProgramPayments) || 0) +
           (parseFloat(data.cccLoans) || 0) +
           (parseFloat(data.cropInsurance) || 0) +
           (parseFloat(data.customHire) || 0) +
           (parseFloat(data.farmOtherIncomeA) || 0) +
           (parseFloat(data.farmOtherIncomeB) || 0);
  }

  get farmExpensesTotal() {
    if (!this.selectedBorrower?.data) return 0;
    const data = this.selectedBorrower.data;
    return (parseFloat(data.carAndTruck) || 0) +
           (parseFloat(data.chemicals) || 0) +
           (parseFloat(data.conservation) || 0) +
           (parseFloat(data.expensesCustomHire) || 0) +
           (parseFloat(data.depreciation) || 0) +
           (parseFloat(data.employeeBenefit) || 0) +
           (parseFloat(data.feed) || 0) +
           (parseFloat(data.fertilizer) || 0) +
           (parseFloat(data.freight) || 0) +
           (parseFloat(data.gasolineFuel) || 0) +
           (parseFloat(data.insurance) || 0) +
           (parseFloat(data.interestMortgageNonCurrent) || 0) +
           (parseFloat(data.otherInterest) || 0) +
           (parseFloat(data.labor) || 0) +
           (parseFloat(data.rentLeaseMachinery) || 0) +
           (parseFloat(data.rentLeaseRealProperty) || 0) +
           (parseFloat(data.repairs) || 0) +
           (parseFloat(data.seed) || 0) +
           (parseFloat(data.storage) || 0) +
           (parseFloat(data.supplies) || 0) +
           (parseFloat(data.taxes) || 0) +
           (parseFloat(data.utilities) || 0) +
           (parseFloat(data.veterinaryFees) || 0) +
           (parseFloat(data.otherExpensesA) || 0) +
           (parseFloat(data.otherExpensesB) || 0);
  }

  get nonFarmIncomeTotal() {
    if (!this.selectedBorrower?.data) return 0;
    const data = this.selectedBorrower.data;
    return (parseFloat(data.salaries) || 0) +
           (parseFloat(data.interestIncome) || 0) +
           (parseFloat(data.dividends) || 0) +
           (parseFloat(data.businessIncomeSchC) || 0) +
           (parseFloat(data.capitalGainLossSchD) || 0) +
           (parseFloat(data.otherGainLoss4797) || 0) +
           (parseFloat(data.rentalIncomeSchE) || 0) +
           (parseFloat(data.otherIncomeA) || 0) +
           (parseFloat(data.otherIncomeB) || 0) +
           (parseFloat(data.otherIncomeC) || 0);
  }

  get netFarmIncome() {
    return this.incomeAndExpensesTotal - this.farmExpensesTotal;
  }

  get totalPreTaxIncome() {
    return this.netFarmIncome + this.nonFarmIncomeTotal;
  }

  get totalCashFlowAvailable() {
    if (!this.selectedBorrower?.data) return 0;
    const data = this.selectedBorrower.data;

    const totalPlusAdjustments = 
      (parseFloat(data.depreciation) || 0) +
      (parseFloat(data.nonFarmDepreciation) || 0) +
      (parseFloat(data.interestMortgageNonCurrent) || 0) +
      (parseFloat(data.otherInterest) || 0) +
      (parseFloat(data.nonFarmCurrentNonCurrentIntEE) || 0) +
      (parseFloat(data.rentLeaseMachinery) || 0) +
      (parseFloat(data.otherPlusAdjustment) || 0);

    const minusAdjustments =
      (parseFloat(data.incomeAndFICATaxes) || 0) +
      (parseFloat(data.familyLiving) || 0) +
      (parseFloat(data.nonRecurring) || 0) +
      (parseFloat(data.otherMinusAdjustments) || 0);

    return this.totalPreTaxIncome + totalPlusAdjustments - minusAdjustments;
  }
}

const loanStore = new LoanStore();
export default loanStore;