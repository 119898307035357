import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';
import IncomeStatementTrendAnalysis from "./IncomeStatementTrendAnalysis";

const PeerDataBalanceSheetTrendAnalysis = observer(() => {
    const { loanData, borrowers, updateLoanData } = loanDetailStore;

    // Initialize balanceSheetTrend object if it doesn't exist
    useEffect(() => {
        if (!loanData.loanSummary?.balanceSheetTrend) {
            updateLoanData({
                loanSummary: {
                    ...loanData.loanSummary,
                    balanceSheetTrend: {}
                }
            });
        }
    }, [loanData.loanSummary, updateLoanData]);

    const handleInputChange = (field, value) => {
        updateLoanData({
            loanSummary: {
                ...loanData.loanSummary,
                balanceSheetTrend: {
                    ...loanData.loanSummary?.balanceSheetTrend,
                    [field]: value,
                },
            },
        });
    };

    // Get principal borrower's balance sheet date for the "current" column
    const getPrincipalBorrowerBalanceSheetDate = () => {
        const principalBorrower = borrowers.find(b => b.is_principal);
        return principalBorrower?.balanceSheet?.date ? new Date(principalBorrower.balanceSheet.date).toLocaleDateString() : 'Current';
    };

    // Get data from consolidated balance sheet for the "current" column
    const getConsolidatedValue = (field) => {
        if (!loanData.consolidatedBalance) return 0;
        
        // Map field names from balanceSheetTrend to consolidatedBalance
        // This mapping needs to be updated based on the actual field structure
        const fieldMapping = {
            'currentAssets': 'currentAssets',
            'otherNonCurrentAssets': 'intermediateAssets',
            'realEstate': 'longTermAssets.realEstateOwned',
            'currentLiabilities': 'currentLiabilities',
            'otherNonCurrentLiabilities': 'intermediateLiabilities',
            'realEstateLiabilities': 'longTermLiabilities.realEstateMortgages'
        };

        // Parse the nested field path if needed
        if (fieldMapping[field]?.includes('.')) {
            const [section, subfield] = fieldMapping[field].split('.');
            return parseFloat(loanData.consolidatedBalance[section]?.[subfield]) || 0;
        }
        
        return parseFloat(loanData.consolidatedBalance[fieldMapping[field]]) || 0;
    };

    // Get data from proforma balance sheet for the "proForma" column
    const getProformaValue = (field) => {
        if (!loanData.balanceSheetProforma) return 0;
        
        // Map field names from balanceSheetTrend to balanceSheetProforma
        // This mapping needs to be updated based on the actual field structure
        const fieldMapping = {
            'currentAssets': 'currentAssets',
            'otherNonCurrentAssets': 'intermediateAssets',
            'realEstate': 'longTermAssets.realEstateOwned',
            'currentLiabilities': 'currentLiabilities',
            'otherNonCurrentLiabilities': 'intermediateLiabilities',
            'realEstateLiabilities': 'longTermLiabilities.realEstateMortgages'
        };

        // Parse the nested field path if needed
        if (fieldMapping[field]?.includes('.')) {
            const [section, subfield] = fieldMapping[field].split('.');
            return parseFloat(loanData.balanceSheetProforma[section]?.[subfield]) || 0;
        }
        
        return parseFloat(loanData.balanceSheetProforma[fieldMapping[field]]) || 0;
    };

    // Get total assets for a year
    const calculateTotalAssets = (year) => {
        if (year === 'current') {
            // For "current" column, use consolidated balance sheet data
            return getConsolidatedValue('currentAssets') + 
                   getConsolidatedValue('otherNonCurrentAssets') + 
                   getConsolidatedValue('realEstate');
        } else if (year === 'proForma') {
            // For "proForma" column, use proforma balance sheet data
            return getProformaValue('currentAssets') + 
                   getProformaValue('otherNonCurrentAssets') + 
                   getProformaValue('realEstate');
        } else {
            // For historical years, use the balance sheet trend data
            const trend = loanData.loanSummary?.balanceSheetTrend?.[year] || {};
            return (parseFloat(trend.currentAssets) || 0) +
                   (parseFloat(trend.otherNonCurrentAssets) || 0) +
                   (parseFloat(trend.realEstate) || 0);
        }
    };

    // Get total liabilities for a year
    const calculateTotalLiabilities = (year) => {
        if (year === 'current') {
            // For "current" column, use consolidated balance sheet data
            return getConsolidatedValue('currentLiabilities') + 
                   getConsolidatedValue('otherNonCurrentLiabilities') + 
                   getConsolidatedValue('realEstateLiabilities');
        } else if (year === 'proForma') {
            // For "proForma" column, use proforma balance sheet data
            return getProformaValue('currentLiabilities') + 
                   getProformaValue('otherNonCurrentLiabilities') + 
                   getProformaValue('realEstateLiabilities');
        } else {
            // For historical years, use the balance sheet trend data
            const trend = loanData.loanSummary?.balanceSheetTrend?.[year] || {};
            return (parseFloat(trend.currentLiabilities) || 0) +
                   (parseFloat(trend.otherNonCurrentLiabilities) || 0) +
                   (parseFloat(trend.realEstateLiabilities) || 0);
        }
    };

    // Calculate net worth (total assets - total liabilities)
    const calculateNetWorth = (year) => {
        return calculateTotalAssets(year) - calculateTotalLiabilities(year);
    };

    // Calculate change in net worth (current year net worth - previous year net worth)
    const calculateChangeInNetWorth = (currentYear, previousYear) => {
        if (!previousYear) return 0;
        return calculateNetWorth(currentYear) - calculateNetWorth(previousYear);
    };

    // Calculate earned gain 
    // (change in net worth - RE appreciation + plus adjustments - minus adjustments)
    const calculateEarnedGain = (year, previousYear) => {
        if (!previousYear) return 0;
        
        const changeInNetWorth = calculateChangeInNetWorth(year, previousYear);
        const trend = loanData.loanSummary?.balanceSheetTrend?.[year] || {};
        
        const reAppreciation = parseFloat(trend.reAppreciation) || 0;
        const plusAdjustments = parseFloat(trend.plusAdjustments) || 0;
        const minusAdjustments = parseFloat(trend.minusAdjustments) || 0;
        
        return changeInNetWorth - reAppreciation + plusAdjustments - minusAdjustments;
    };

    // Calculate current ratio (current assets / current liabilities)
    const calculateCurrentRatio = (year) => {
        let currentAssets, currentLiabilities;
        
        if (year === 'current') {
            currentAssets = getConsolidatedValue('currentAssets');
            currentLiabilities = getConsolidatedValue('currentLiabilities');
        } else if (year === 'proForma') {
            currentAssets = getProformaValue('currentAssets');
            currentLiabilities = getProformaValue('currentLiabilities');
        } else {
            const trend = loanData.loanSummary?.balanceSheetTrend?.[year] || {};
            currentAssets = parseFloat(trend.currentAssets) || 0;
            currentLiabilities = parseFloat(trend.currentLiabilities) || 0;
        }
        
        return currentLiabilities === 0 ? 0 : (currentAssets / currentLiabilities).toFixed(2);
    };

    // Calculate debt/asset ratio (total liabilities / total assets)
    const calculateDebtAssetRatio = (year) => {
        const totalAssets = calculateTotalAssets(year);
        const totalLiabilities = calculateTotalLiabilities(year);
        
        return totalAssets === 0 ? 0 : (totalLiabilities / totalAssets).toFixed(2);
    };

    const years = ['year1', 'year2', 'year3', 'year4', 'current', 'proForma'];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
                <Typography variant="h6" gutterBottom>Balance Sheet Trend Analysis</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography>Year:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            {year === 'current' ? (
                                <TextField
                                    value={getPrincipalBorrowerBalanceSheetDate()}
                                    InputProps={{readOnly: true}}
                                />
                            ) : year === 'proForma' ? (
                                <TextField
                                    value="Pro-Forma"
                                    InputProps={{readOnly: true}}
                                />
                            ) : (
                                <DatePicker
                                    value={loanData.loanSummary?.balanceSheetTrend?.[year]?.date || null}
                                    onChange={(newValue) => handleInputChange(`${year}.date`, newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            )}
                        </Grid>
                    ))}

                    {/* Current Assets */}
                    <Grid item xs={2}>
                        <Typography>Current Assets:</Typography>
                    </Grid>
                    {years.map((year) => {
                        let value;
                        
                        if (year === 'current') {
                            value = getConsolidatedValue('currentAssets');
                        } else if (year === 'proForma') {
                            value = getProformaValue('currentAssets');
                        } else {
                            value = loanData.loanSummary?.balanceSheetTrend?.[year]?.currentAssets || '';
                        }
                        
                        return (
                            <Grid item xs={1.5} key={year}>
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    value={value}
                                    onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                        handleInputChange(`${year}.currentAssets`, value)}
                                    InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                                />
                            </Grid>
                        );
                    })}

                    {/* Other Non-Current Assets */}
                    <Grid item xs={2}>
                        <Typography>Other N/Curr Ass.:</Typography>
                    </Grid>
                    {years.map((year) => {
                        let value;
                        
                        if (year === 'current') {
                            value = getConsolidatedValue('otherNonCurrentAssets');
                        } else if (year === 'proForma') {
                            value = getProformaValue('otherNonCurrentAssets');
                        } else {
                            value = loanData.loanSummary?.balanceSheetTrend?.[year]?.otherNonCurrentAssets || '';
                        }
                        
                        return (
                            <Grid item xs={1.5} key={year}>
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    value={value}
                                    onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                        handleInputChange(`${year}.otherNonCurrentAssets`, value)}
                                    InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                                />
                            </Grid>
                        );
                    })}

                    {/* Real Estate */}
                    <Grid item xs={2}>
                        <Typography>Real Estate:</Typography>
                    </Grid>
                    {years.map((year) => {
                        let value;
                        
                        if (year === 'current') {
                            value = getConsolidatedValue('realEstate');
                        } else if (year === 'proForma') {
                            value = getProformaValue('realEstate');
                        } else {
                            value = loanData.loanSummary?.balanceSheetTrend?.[year]?.realEstate || '';
                        }
                        
                        return (
                            <Grid item xs={1.5} key={year}>
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    value={value}
                                    onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                        handleInputChange(`${year}.realEstate`, value)}
                                    InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                                />
                            </Grid>
                        );
                    })}

                    {/* Total Assets */}
                    <Grid item xs={2}>
                        <Typography>Total Assets:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateTotalAssets(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Current Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Current Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => {
                        let value;
                        
                        if (year === 'current') {
                            value = getConsolidatedValue('currentLiabilities');
                        } else if (year === 'proForma') {
                            value = getProformaValue('currentLiabilities');
                        } else {
                            value = loanData.loanSummary?.balanceSheetTrend?.[year]?.currentLiabilities || '';
                        }
                        
                        return (
                            <Grid item xs={1.5} key={year}>
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    value={value}
                                    onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                        handleInputChange(`${year}.currentLiabilities`, value)}
                                    InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                                />
                            </Grid>
                        );
                    })}

                    {/* Other Non-Current Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Other N/Curr Liab.:</Typography>
                    </Grid>
                    {years.map((year) => {
                        let value;
                        
                        if (year === 'current') {
                            value = getConsolidatedValue('otherNonCurrentLiabilities');
                        } else if (year === 'proForma') {
                            value = getProformaValue('otherNonCurrentLiabilities');
                        } else {
                            value = loanData.loanSummary?.balanceSheetTrend?.[year]?.otherNonCurrentLiabilities || '';
                        }
                        
                        return (
                            <Grid item xs={1.5} key={year}>
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    value={value}
                                    onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                        handleInputChange(`${year}.otherNonCurrentLiabilities`, value)}
                                    InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                                />
                            </Grid>
                        );
                    })}

                    {/* Real Estate Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Real Estate Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => {
                        let value;
                        
                        if (year === 'current') {
                            value = getConsolidatedValue('realEstateLiabilities');
                        } else if (year === 'proForma') {
                            value = getProformaValue('realEstateLiabilities');
                        } else {
                            value = loanData.loanSummary?.balanceSheetTrend?.[year]?.realEstateLiabilities || '';
                        }
                        
                        return (
                            <Grid item xs={1.5} key={year}>
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    value={value}
                                    onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                        handleInputChange(`${year}.realEstateLiabilities`, value)}
                                    InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                                />
                            </Grid>
                        );
                    })}

                    {/* Total Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Total Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateTotalLiabilities(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Net Worth */}
                    <Grid item xs={2}>
                        <Typography>Net Worth:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateNetWorth(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Change In Net Worth */}
                    <Grid item xs={2}>
                        <Typography>Change In Net Worth:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateChangeInNetWorth(year, index > 0 ? years[index - 1] : null)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* RE Appreciation */}
                    <Grid item xs={2}>
                        <Typography>RE Appreciation:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={year !== 'current' && year !== 'proForma' ? 
                                    loanData.loanSummary?.balanceSheetTrend?.[year]?.reAppreciation || '' : ''}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.reAppreciation`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Plus Adjustments (New field) */}
                    <Grid item xs={2}>
                        <Typography>Plus Adjustments:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={year !== 'current' && year !== 'proForma' ?
                                    loanData.loanSummary?.balanceSheetTrend?.[year]?.plusAdjustments || '' : ''}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.plusAdjustments`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Minus Adjustments (New field) */}
                    <Grid item xs={2}>
                        <Typography>Minus Adjustments:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={year !== 'current' && year !== 'proForma' ?
                                    loanData.loanSummary?.balanceSheetTrend?.[year]?.minusAdjustments || '' : ''}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.minusAdjustments`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Earned Gain */}
                    <Grid item xs={2}>
                        <Typography>Earned Gain:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateEarnedGain(year, index > 0 ? years[index - 1] : null)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Current Ratio */}
                    <Grid item xs={2}>
                        <Typography>Current Ratio:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <TextField
                                variant="outlined"
                                value={calculateCurrentRatio(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Debt/Asset Ratio */}
                    <Grid item xs={2}>
                        <Typography>Debt/Asset Ratio:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <TextField
                                variant="outlined"
                                value={calculateDebtAssetRatio(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Comments */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Comments"
                            value={loanData.loanSummary?.balanceSheetTrend?.comments || ''}
                            onChange={(e) => handleInputChange('comments', e.target.value)}
                        />
                    </Grid>
                </Grid>
                
                <IncomeStatementTrendAnalysis/>
            </Box>
        </LocalizationProvider>
    );
});

export default PeerDataBalanceSheetTrendAnalysis