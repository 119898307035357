import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';
import IncomeStatementTrendAnalysis from "./IncomeStatementTrendAnalysis";

const PeerDataBalanceSheetTrendAnalysis = observer(() => {
    const { loanData, borrowers, updateLoanData } = loanDetailStore;

    // Initialize balanceSheetTrend object if it doesn't exist
    useEffect(() => {
        if (!loanData.loanSummary?.balanceSheetTrend) {
            updateLoanData({
                loanSummary: {
                    ...loanData.loanSummary,
                    balanceSheetTrend: {}
                }
            });
        }
    }, [loanData.loanSummary, updateLoanData]);

    const handleInputChange = (field, value) => {
        updateLoanData({
            loanSummary: {
                ...loanData.loanSummary,
                balanceSheetTrend: {
                    ...loanData.loanSummary?.balanceSheetTrend,
                    [field]: value,
                },
            },
        });
    };

    // Find the principal borrower and get their balance sheet date for the current column
    const principalBorrower = borrowers.find(b => b.is_principal);
    const principalBorrowerBalanceSheetDate = principalBorrower?.balanceSheet?.date ? 
        new Date(principalBorrower.balanceSheet.date).toLocaleDateString() : 'Current';

    // Get consolidated value for a field
    const getConsolidatedValue = (field) => {
        // Sum values from all borrowers
        const borrowerSum = borrowers.reduce((sum, borrower) => {
            const borrowerBalanceSheet = borrower.balanceSheet || {};
            
            switch(field) {
                case 'currentAssets':
                    const caValue = parseFloat(borrowerBalanceSheet.currentAssets?.cash || 0) +
                        parseFloat(borrowerBalanceSheet.currentAssets?.savings || 0) +
                        parseFloat(borrowerBalanceSheet.currentAssets?.marketableBondsAndSecurities || 0) +
                        parseFloat(borrowerBalanceSheet.currentAssets?.cashValueLifeInsurance || 0) +
                        parseFloat(borrowerBalanceSheet.currentAssets?.prepaidExpensesAndSupplies || 0);
                    
                    // Add other current assets
                    const otherCurrentAssets = borrowerBalanceSheet.otherCurrentAssets || [];
                    const otherCASum = otherCurrentAssets.reduce((sum, asset) => {
                        return sum + (parseFloat(asset.amount) || 0);
                    }, 0);
                    
                    return sum + caValue + otherCASum;
                    
                case 'otherNonCurrentAssets':
                    // Sum intermediate assets
                    const iaValue = parseFloat(borrowerBalanceSheet.intermediateAssets?.livestockBreeding || 0) +
                        parseFloat(borrowerBalanceSheet.intermediateAssets?.machineryAndVehicles || 0) +
                        parseFloat(borrowerBalanceSheet.intermediateAssets?.notesAndContractsReceivable || 0);
                    
                    // Add other non-current assets
                    const otherNonCurrentAssets = borrowerBalanceSheet.otherNonCurrentAssets || [];
                    const otherNCSum = otherNonCurrentAssets.reduce((sum, asset) => {
                        return sum + (parseFloat(asset.amount) || 0);
                    }, 0);
                    
                    // Add other investments
                    const otherInvestments = borrowerBalanceSheet.otherInvestments || [];
                    const otherInvSum = otherInvestments.reduce((sum, inv) => {
                        return sum + (parseFloat(inv.amount) || 0);
                    }, 0);
                    
                    return sum + iaValue + otherNCSum + otherInvSum;
                    
                case 'realEstate':
                    // Sum long term assets
                    const ltaValue = parseFloat(borrowerBalanceSheet.longTermAssets?.realEstateOwned || 0);
                    
                    // Add other non-real estate long term assets
                    const otherLTAssets = borrowerBalanceSheet.otherNonRealEstateLongTermAssets || [];
                    const otherLTSum = otherLTAssets.reduce((sum, asset) => {
                        return sum + (parseFloat(asset.amount) || 0);
                    }, 0);
                    
                    return sum + ltaValue + otherLTSum;
                    
                case 'currentLiabilities':
                    const clValue = parseFloat(borrowerBalanceSheet.currentLiabilities?.incomeTaxPayable || 0) +
                        parseFloat(borrowerBalanceSheet.currentLiabilities?.realEstateTaxesDue || 0) +
                        parseFloat(borrowerBalanceSheet.currentLiabilities?.rentsDue || 0);
                    
                    // Add accounts payable
                    const accountsPayable = borrowerBalanceSheet.accountsPayable || [];
                    const apSum = accountsPayable.reduce((sum, account) => {
                        return sum + (parseFloat(account.amount) || 0);
                    }, 0);
                    
                    return sum + clValue + apSum;
                    
                case 'otherNonCurrentLiabilities':
                    // Sum intermediate liabilities (usually chattel debt)
                    return sum + parseFloat(borrowerBalanceSheet.intermediateLiabilities?.chattelDebtAndNotesPayable || 0);
                    
                case 'realEstateLiabilities':
                    // Sum long term liabilities (usually real estate mortgages)
                    return sum + parseFloat(borrowerBalanceSheet.longTermLiabilities?.realEstateMortgages || 0);
                    
                default:
                    return sum;
            }
        }, 0);
        
        // Subtract eliminations if present
        const eliminations = loanData.consolidatedBalance || {};
        let elimsValue = 0;
        
        switch(field) {
            case 'currentAssets':
                elimsValue = parseFloat(eliminations.currentAssets?.cashElims || 0) +
                    parseFloat(eliminations.currentAssets?.savingsElims || 0) +
                    parseFloat(eliminations.currentAssets?.marketableBondsAndSecuritiesElims || 0) +
                    parseFloat(eliminations.currentAssets?.cashValueLifeInsuranceElims || 0) +
                    parseFloat(eliminations.currentAssets?.prepaidExpensesAndSuppliesElims || 0);
                break;
                
            case 'otherNonCurrentAssets':
                elimsValue = parseFloat(eliminations.intermediateAssets?.livestockBreedingElims || 0) +
                    parseFloat(eliminations.intermediateAssets?.machineryAndVehiclesElims || 0) +
                    parseFloat(eliminations.intermediateAssets?.notesAndContractsReceivableElims || 0);
                break;
                
            case 'realEstate':
                elimsValue = parseFloat(eliminations.longTermAssets?.realEstateOwnedElims || 0);
                break;
                
            case 'currentLiabilities':
                elimsValue = parseFloat(eliminations.currentLiabilities?.incomeTaxPayableElims || 0) +
                    parseFloat(eliminations.currentLiabilities?.realEstateTaxesDueElims || 0) +
                    parseFloat(eliminations.currentLiabilities?.rentsDueElims || 0);
                break;
                
            case 'otherNonCurrentLiabilities':
                elimsValue = parseFloat(eliminations.intermediateLiabilities?.chattelDebtAndNotesPayableElims || 0);
                break;
                
            case 'realEstateLiabilities':
                elimsValue = parseFloat(eliminations.longTermLiabilities?.realEstateMortgagesElims || 0);
                break;
                
            default:
                elimsValue = 0;
        }
        
        return borrowerSum - elimsValue;
    };

    // Get proforma value for a field
    const getProformaValue = (field) => {
        // Start with consolidated value
        const consolidatedValue = getConsolidatedValue(field);
        
        // Apply proforma adjustments if present
        const proformaAdjustments = loanData.balanceSheetProforma || {};
        let adjustmentValue = 0;
        
        switch(field) {
            case 'currentAssets':
                adjustmentValue = parseFloat(proformaAdjustments.currentAssets?.cashChange || 0) +
                    parseFloat(proformaAdjustments.currentAssets?.savingsChange || 0) +
                    parseFloat(proformaAdjustments.currentAssets?.marketableBondsAndSecuritiesChange || 0) +
                    parseFloat(proformaAdjustments.currentAssets?.cashValueLifeInsuranceChange || 0) +
                    parseFloat(proformaAdjustments.currentAssets?.prepaidExpensesAndSuppliesChange || 0);
                break;
                
            case 'otherNonCurrentAssets':
                adjustmentValue = parseFloat(proformaAdjustments.intermediateAssets?.livestockBreedingChange || 0) +
                    parseFloat(proformaAdjustments.intermediateAssets?.machineryAndVehiclesChange || 0) +
                    parseFloat(proformaAdjustments.intermediateAssets?.notesAndContractsReceivableChange || 0);
                break;
                
            case 'realEstate':
                adjustmentValue = parseFloat(proformaAdjustments.longTermAssets?.realEstateOwnedChange || 0);
                break;
                
            case 'currentLiabilities':
                adjustmentValue = parseFloat(proformaAdjustments.currentLiabilities?.incomeTaxPayableChange || 0) +
                    parseFloat(proformaAdjustments.currentLiabilities?.realEstateTaxesDueChange || 0) +
                    parseFloat(proformaAdjustments.currentLiabilities?.rentsDueChange || 0);
                break;
                
            case 'otherNonCurrentLiabilities':
                adjustmentValue = parseFloat(proformaAdjustments.intermediateLiabilities?.chattelDebtAndNotesPayableChange || 0);
                break;
                
            case 'realEstateLiabilities':
                adjustmentValue = parseFloat(proformaAdjustments.longTermLiabilities?.realEstateMortgagesChange || 0);
                break;
                
            default:
                adjustmentValue = 0;
        }
        
        return consolidatedValue + adjustmentValue;
    };

    // Get historical value from trend data
    const getHistoricalValue = (year, field) => {
        return parseFloat(loanData.loanSummary?.balanceSheetTrend?.[year]?.[field]) || 0;
    };

    // Get value based on year (historical, current, proforma)
    const getFieldValue = (year, field) => {
        if (year === 'current') {
            return getConsolidatedValue(field);
        } else if (year === 'proForma') {
            return getProformaValue(field);
        } else {
            return getHistoricalValue(year, field);
        }
    };

    // Calculate total assets for a specific year
    const calculateTotalAssets = (year) => {
        return getFieldValue(year, 'currentAssets') + 
               getFieldValue(year, 'otherNonCurrentAssets') + 
               getFieldValue(year, 'realEstate');
    };

    // Calculate total liabilities for a specific year
    const calculateTotalLiabilities = (year) => {
        return getFieldValue(year, 'currentLiabilities') + 
               getFieldValue(year, 'otherNonCurrentLiabilities') + 
               getFieldValue(year, 'realEstateLiabilities');
    };

    // Calculate net worth (total assets - total liabilities)
    const calculateNetWorth = (year) => {
        return calculateTotalAssets(year) - calculateTotalLiabilities(year);
    };

    // Calculate change in net worth (prev year - next year)
    const calculateChangeInNetWorth = (year, index, years) => {
        if (index === 0) return null; // First column has no previous year
        const prevYear = years[index - 1];
        
        return calculateNetWorth(year) - calculateNetWorth(prevYear);
    };

    // Calculate earned gain 
    // (change in net worth - RE appreciation + plus adjustments - minus adjustments)
    const calculateEarnedGain = (year, index, years) => {
        if (index === 0) return null; // First column has no change in net worth
        
        const changeInNetWorth = calculateChangeInNetWorth(year, index, years);
        const reAppreciation = parseFloat(loanData.loanSummary?.balanceSheetTrend?.[year]?.reAppreciation || 0);
        const plusAdjustments = parseFloat(loanData.loanSummary?.balanceSheetTrend?.[year]?.plusAdjustments || 0);
        const minusAdjustments = parseFloat(loanData.loanSummary?.balanceSheetTrend?.[year]?.minusAdjustments || 0);
        
        return changeInNetWorth - reAppreciation + plusAdjustments - minusAdjustments;
    };

    // Calculate current ratio (current assets / current liabilities)
    const calculateCurrentRatio = (year) => {
        const currentAssets = getFieldValue(year, 'currentAssets');
        const currentLiabilities = getFieldValue(year, 'currentLiabilities');
        
        return currentLiabilities === 0 ? 0 : (currentAssets / currentLiabilities);
    };

    // Calculate debt/asset ratio (total liabilities / total assets)
    const calculateDebtAssetRatio = (year) => {
        const totalAssets = calculateTotalAssets(year);
        const totalLiabilities = calculateTotalLiabilities(year);
        
        return totalAssets === 0 ? 0 : (totalLiabilities / totalAssets);
    };

    const years = ['year1', 'year2', 'year3', 'year4', 'current', 'proForma'];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
                <Typography variant="h6" gutterBottom>Balance Sheet Trend Analysis</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography>Year:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            {year === 'current' ? (
                                <TextField
                                    value={principalBorrowerBalanceSheetDate}
                                    InputProps={{readOnly: true}}
                                />
                            ) : year === 'proForma' ? (
                                <TextField
                                    value="Pro-Forma"
                                    InputProps={{readOnly: true}}
                                />
                            ) : (
                                <DatePicker
                                    value={loanData.loanSummary?.balanceSheetTrend?.[year]?.date || null}
                                    onChange={(newValue) => handleInputChange(`${year}.date`, newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            )}
                        </Grid>
                    ))}

                    {/* Current Assets */}
                    <Grid item xs={2}>
                        <Typography>Current Assets:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={getFieldValue(year, 'currentAssets')}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.currentAssets`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Other Non-Current Assets */}
                    <Grid item xs={2}>
                        <Typography>Other N/Curr Ass.:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={getFieldValue(year, 'otherNonCurrentAssets')}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.otherNonCurrentAssets`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Real Estate */}
                    <Grid item xs={2}>
                        <Typography>Real Estate:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={getFieldValue(year, 'realEstate')}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.realEstate`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Total Assets */}
                    <Grid item xs={2}>
                        <Typography>Total Assets:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateTotalAssets(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Current Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Current Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={getFieldValue(year, 'currentLiabilities')}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.currentLiabilities`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Other Non-Current Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Other N/Curr Liab.:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={getFieldValue(year, 'otherNonCurrentLiabilities')}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.otherNonCurrentLiabilities`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Real Estate Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Real Estate Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={getFieldValue(year, 'realEstateLiabilities')}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.realEstateLiabilities`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Total Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Total Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateTotalLiabilities(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Net Worth */}
                    <Grid item xs={2}>
                        <Typography>Net Worth:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateNetWorth(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Change In Net Worth */}
                    <Grid item xs={2}>
                        <Typography>Change In Net Worth:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateChangeInNetWorth(year, index, years)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* RE Appreciation */}
                    <Grid item xs={2}>
                        <Typography>RE Appreciation:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={year !== 'current' && year !== 'proForma' ? 
                                    loanData.loanSummary?.balanceSheetTrend?.[year]?.reAppreciation || '' : ''}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.reAppreciation`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Plus Adjustments */}
                    <Grid item xs={2}>
                        <Typography>Plus Adjustments:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={year !== 'current' && year !== 'proForma' ? 
                                    loanData.loanSummary?.balanceSheetTrend?.[year]?.plusAdjustments || '' : ''}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.plusAdjustments`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Minus Adjustments */}
                    <Grid item xs={2}>
                        <Typography>Minus Adjustments:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                value={year !== 'current' && year !== 'proForma' ? 
                                    loanData.loanSummary?.balanceSheetTrend?.[year]?.minusAdjustments || '' : ''}
                                onChange={(e, value) => year !== 'current' && year !== 'proForma' && 
                                    handleInputChange(`${year}.minusAdjustments`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Earned Gain */}
                    <Grid item xs={2}>
                        <Typography>Earned Gain:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                variant="outlined"
                                value={calculateEarnedGain(year, index, years)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Current Ratio */}
                    <Grid item xs={2}>
                        <Typography>Current Ratio:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <TextField
                                variant="outlined"
                                value={calculateCurrentRatio(year).toFixed(2)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Debt/Asset Ratio */}
                    <Grid item xs={2}>
                        <Typography>Debt/Asset Ratio:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <TextField
                                variant="outlined"
                                value={calculateDebtAssetRatio(year).toFixed(2)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Comments */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Comments"
                            value={loanData.loanSummary?.balanceSheetTrend?.comments || ''}
                            onChange={(e) => handleInputChange('comments', e.target.value)}
                        />
                    </Grid>
                </Grid>
                
                <IncomeStatementTrendAnalysis/>
            </Box>
        </LocalizationProvider>
    );
});

export default PeerDataBalanceSheetTrendAnalysis;