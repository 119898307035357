import React, {useCallback, useMemo, useState} from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const ScheduleG = observer(({ borrowerData, readOnly, proforma }) => {
  const { updateBSAData, addBSAItem, deleteBSAItem, updateBSAProformaData, addBSAProformaItem, deleteBSAProformaItem, selectedBorrowerId } = loanDetailStore;
  const [editedItem, setEditedItem] = useState(null);
  const [editedIndex, setEditedIndex] = useState(null);

  const selectedBorrowerLocalId = useMemo(() => {
    let result;
    if (readOnly || proforma) {
      result = borrowerData.find(borrower => borrower.is_principal === true)["id"];
    } else {
      result = selectedBorrowerId;
    }
    if (!result) {
      console.warn('No borrower found!');
    }
    return result;
  }, [selectedBorrowerId, readOnly, proforma, borrowerData]);

  const selectedBorrower = useMemo(() => {
    return borrowerData.find(borrower => borrower.id === selectedBorrowerLocalId);
  }, [selectedBorrowerLocalId, borrowerData]);

  const handleInputChange = (item, index, field, value) => {
    if (editedIndex !== index) {
      setEditedItem({...item});
      setEditedIndex(index);
    }

    setEditedItem(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleBlur = (borrowerId, index) => {
    if (editedItem && editedIndex === index) {
      Object.entries(editedItem).forEach(([field, value]) => {
        if (field !== 'index' && field !== 'borrowerId') {
          if (proforma) {
            updateBSAProformaData(borrowerId, 'g', index, field, value);
          } else {
            updateBSAData(borrowerId, 'g', index, field, value);
          }
        }
      });
      setEditedItem(null);
      setEditedIndex(null);
    }
  };

  const handleAddItem = useCallback(() => {
    if (proforma) {
      addBSAProformaItem('g');
    } else {
      addBSAItem(selectedBorrowerLocalId, 'g');
    }
  }, [addBSAItem, addBSAProformaItem, proforma, selectedBorrowerLocalId]);

  const handleDeleteItem = useCallback((borrowerId, index) => {
    if (proforma) {
      const borrower = borrowerData.find(b => b.id === borrowerId);
      if (borrower && borrower.bsas?.g) {
        const originalIndex = borrower.bsas.g.findIndex((item, idx) => idx === index);
        if (originalIndex !== -1) {
          deleteBSAProformaItem(borrowerId, 'g', originalIndex);
        }
      }
    } else {
      deleteBSAItem(borrowerId, 'g', index);
    }
  }, [deleteBSAItem, deleteBSAProformaItem, proforma, borrowerData]);

  const getFieldValue = useCallback((item, field, index) => {
    if (editedIndex === index && editedItem) {
      return editedItem[field] ?? '';
    }
    return proforma ? (item.proforma?.[field] ?? item[field] ?? '') : (item[field] ?? '');
  }, [proforma, editedIndex, editedItem]);

  const renderScheduleItems = () => {
    if (readOnly) {
      return borrowerData.flatMap(borrower => borrower.bsas?.g || []);
    }

    if (proforma) {
      return borrowerData.flatMap((borrower, borrowerIndex) =>
          (borrower.bsas?.g || []).map((item, itemIndex) => ({
            ...item,
            ...(item.proforma || {}),
            borrowerId: borrower.id,
            index: itemIndex,
          }))
      );
    }

    return (selectedBorrower.bsas?.g || []).map((item, index) => ({ ...item, index }));
  };

  const scheduleItems = renderScheduleItems();

  const calculateTotals = () => {
    return scheduleItems.reduce((totals, item) => {
      const amount = parseFloat(getFieldValue(item, 'amount', item.index)) || 0;
      const price = parseFloat(getFieldValue(item, 'price', item.index)) || 0;
      totals.amount += amount;
      totals.value += amount * price;
      return totals;
    }, { amount: 0, value: 0 });
  };

  const totals = calculateTotals();

  return (
      <>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Schedule G - Harvested Crops</Typography>
        {scheduleItems.map((item, index) => (
            <Box key={`${item.borrowerId}-${item.index}`} mb={2}>
              <Box display="flex" gap={2} mb={1}>
                <TextField
                    label="Crop"
                    value={getFieldValue(item, 'crop', index)}
                    onChange={(e) => handleInputChange(item, index, 'crop', e.target.value)}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Amount"
                    type="number"
                    value={getFieldValue(item, 'amount', index)}
                    onChange={(e) => handleInputChange(item, index, 'amount', parseFloat(e.target.value) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Units"
                    value={getFieldValue(item, 'units', index)}
                    onChange={(e) => handleInputChange(item, index, 'units', e.target.value)}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    modifyValueOnWheel={false}
                    variant="outlined"
                    label="Price"
                    value={getFieldValue(item, 'price', index)}
                    onChange={(e) => handleInputChange(item, index, 'price', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    onBlur={() => handleBlur(item.borrowerId || selectedBorrowerLocalId, index)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Value"
                    value={(parseFloat(getFieldValue(item, 'amount', index)) || 0) * (parseFloat(getFieldValue(item, 'price', index)) || 0)}
                    InputProps={{ readOnly: true }}
                />

                {(proforma || !readOnly) && (
                    <IconButton onClick={() => handleDeleteItem(item.borrowerId || selectedBorrowerLocalId, item.index)}>
                      <DeleteIcon />
                    </IconButton>
                )}
              </Box>
            </Box>
        ))}
        {(proforma || !readOnly) && (
            <Box display="flex" justifyContent="flex-start" mb={2}>
              <Button variant="contained" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>
        )}
        {(readOnly || proforma) && (
            <Box display="flex" gap={2} my={3} justifyContent="flex-end">
              <TextField
                  variant="outlined"
                  label="Total Amount"
                  value={totals.amount}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  variant="outlined"
                  label="Total Value"
                  value={totals.value}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
            </Box>
        )}
      </>
  );
});

export default ScheduleG;