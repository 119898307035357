// IntermediateLiabilities.js
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, Grid } from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const IntermediateLiabilities = observer(({ borrowers, consolidated, proforma = false, readOnly = false }) => {
  // Find and update the calculateArrayFieldSum function to handle annualTotalPrincipal special case
  const calculateArrayFieldSum = (scheduleType, field) => {
    const scheduleData = borrowers.flatMap(borrower => {
      const scheduleItems = proforma 
        ? (borrower.bsas?.[scheduleType] || []).map(item => ({
            ...item,
            ...(item.proforma || {})
          }))
        : borrower.bsas?.[scheduleType] || [];
      return scheduleItems;
    });
  
    if (field === 'annualTotalPrincipal') {
      // Special case for derived field
      return scheduleData.reduce((sum, item) => {
        const annualPayment = parseFloat(item.annualPayment) || 0;
        const balance = parseFloat(item.balance) || 0;
        const rate = parseFloat(item.rate) || 0;
        const annualInterest = balance * (rate/100);
        const principal = annualPayment - annualInterest;
        return sum + principal;
      }, 0);
    }
    
    // Standard field summation
    return scheduleData.reduce((sum, item) => {
      return sum + (parseFloat(item[field]) || 0);
    }, 0);
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="subtitle1" my={2}>
        Intermediate Liabilities
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <CurrencyTextField
          variant="outlined"
          label="Chattel Debt and Notes Payable (Schedule B)"
          value={calculateArrayFieldSum('b', 'balance')}
          InputProps={{ readOnly: true }}
        />
        <CurrencyTextField
          variant="outlined"
          label="Less Current Portion (Schedule B)"
          value={-calculateArrayFieldSum('b', 'annualTotalPrincipal')}
          InputProps={{ readOnly: true }}
        />
        <Box display="flex" flexDirection="row" gap={2} my={2}>
          <Grid item xs={12} md={6}>
            <Typography>Total Intermediate Liabilities:</Typography>
          </Grid>
          <Grid item xs={12} md={6} align="right">
            <Typography>
              {(
                calculateArrayFieldSum('b', 'balance') -
                calculateArrayFieldSum('b', 'annualPayment')
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
});

export default IntermediateLiabilities;