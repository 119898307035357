import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    MenuItem
} from '@mui/material';

const YearChangeHandler = ({ currentYears, onYearChange, hasData, selectedBorrowerId }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [pendingYearChange, setPendingYearChange] = useState(null);

    const handleYearSelection = (event) => {
        const newYears = parseInt(event.target.value);

        // If there's no existing data, we can change directly without confirmation
        if (!hasData) {
            onYearChange(selectedBorrowerId, newYears);
            return;
        }

        // Store the pending change and show confirmation dialog
        setPendingYearChange(newYears);
        setIsDialogOpen(true);
    };

    const handleConfirm = () => {
        if (pendingYearChange !== null) {
            onYearChange(selectedBorrowerId, pendingYearChange);
        }
        setIsDialogOpen(false);
        setPendingYearChange(null);
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
        setPendingYearChange(null);
    };

    return (
        <>
            <TextField
                select
                label="Years"
                value={currentYears || ''}
                onChange={handleYearSelection}
                fullWidth
            >
                {[1, 2, 3, 4].map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </TextField>

            <Dialog
                open={isDialogOpen}
                onClose={handleCancel}
                aria-labelledby="year-change-dialog-title"
                aria-describedby="year-change-dialog-description"
            >
                <DialogTitle id="year-change-dialog-title">
                    Confirm Year Change
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="year-change-dialog-description">
                        Changing the number of years will clear all existing data in the grid. Are you sure you want to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default YearChangeHandler;