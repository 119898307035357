import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, TextField, IconButton, Typography, Box, Button } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import loanDetailStore from '../store';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const IntermediateAssets = observer(({ borrowers, consolidated, proforma }) => {
    const {
        updateLoanData,
        loanData,
        updateProformaBalanceSheetItem,
        deleteProformaBalanceSheetItem,
        addProformaBalanceSheetItem
    } = loanDetailStore;

    const calculateProformaOtherNonCurrentAssetsTotal = () => {
        return (loanData.balanceSheetProforma?.intermediateAssets?.otherNonCurrentAssets || [])
            .reduce((sum, asset) => sum + (parseFloat(asset.amount) || 0), 0);
    };

    const calculateProformaOtherInvestmentsTotal = () => {
        return (loanData.balanceSheetProforma?.intermediateAssets?.otherInvestments || [])
            .reduce((sum, investment) => sum + (parseFloat(investment.amount) || 0), 0);
    };


    const calculateConsolidatedTotal = () => {
        return (
            calculateCurrentTotal('h', 'value') - calculateElims('livestockBreeding') +
            calculateCurrentTotal('k', 'marketValue') - calculateElims('machineryAndVehicles') +
            calculateOtherNonCurrentAssetsConsolidatedTotal() +
            calculateOtherInvestmentsConsolidatedTotal() +
            calculateCurrentTotal('e', 'principal') - calculateElims('notesAndContractsReceivable') +
            calculateCurrentTotal('e', 'interest') - calculateElims('lessCurrentPortion') -
            calculateCurrentTotal('e', 'value')
        );
    };

    const calculateProformaTotal = () => {
        return (
            calculateProforma('livestockBreeding') +
            calculateProforma('machineryAndVehicles') +
            calculateProformaOtherNonCurrentAssetsTotal() +
            calculateProformaOtherInvestmentsTotal() +
            calculateProforma('notesAndContractsReceivable') +
            calculateProforma('lessCurrentPortion')
        );
    };
    // const calculateConsolidated = (field) => {
    //     return calculateCurrentTotal('h', 'value') - calculateElims('livestockBreeding') +
    //         calculateCurrentTotal('k', 'marketValue') - calculateElims('machineryAndVehicles') +
    //         (field === 'otherNonCurrentAssets' ? calculateOtherNonCurrentAssetsConsolidatedTotal() : 0) +
    //         (field === 'otherInvestments' ? calculateOtherInvestmentsConsolidatedTotal() : 0) +
    //         (field === 'notesAndContractsReceivable' ? calculateCurrentTotal('e', 'principal') + calculateCurrentTotal('e', 'interest') - calculateElims('notesAndContractsReceivable') : 0) +
    //         (field === 'lessCurrentPortion' ? -calculateCurrentTotal('e', 'value') - calculateElims('lessCurrentPortion') : 0);
    // };
    const calculateConsolidated = (field) => {
        return calculateCurrentTotal(field) - calculateElims(field);
    };

    const calculateProforma = (field) => {
        return calculateConsolidated(field) + (loanDetailStore.loanData.balanceSheetProforma?.intermediateAssets?.[`${field}Change`] || 0);
    };

    const updateConsolidatedField = (field, value) => {
        updateLoanData({
            consolidatedBalance: {
                ...loanData.consolidatedBalance,
                intermediateAssets: {
                    ...loanData.consolidatedBalance?.intermediateAssets,
                    [field]: value,
                },
            },
        });
    };

    const updateProformaField = (field, value) => {
        updateLoanData({
            balanceSheetProforma: {
                ...loanData.balanceSheetProforma,
                intermediateAssets: {
                    ...loanData.balanceSheetProforma?.intermediateAssets,
                    [field]: value,
                },
            },
        });
    };
    const calculateCurrentTotal = (schedule, field) => {
        return borrowers.reduce((sum, borrower) => {
            const array = borrower.bsas?.[schedule] || [];
            const arraySum = array.reduce((innerSum, item) => {
                const value = parseFloat(item?.[field] || 0);
                return innerSum + value;
            }, 0);
            return sum + arraySum;
        }, 0);
    };

    const calculateElims = (field) => {
        return parseFloat(loanData.consolidatedBalance?.intermediateAssets?.[`${field}Elims`] || 0);
    };

    const calculateOtherNonCurrentAssetsTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherNonCurrentAssets = borrower.balanceSheet?.otherNonCurrentAssets || [];
            const otherNonCurrentAssetsSum = otherNonCurrentAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset?.amount || 0);
                return innerSum + value;
            }, 0);
            return sum + otherNonCurrentAssetsSum;
        }, 0);
    };

    const calculateOtherInvestmentsTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherInvestments = borrower.balanceSheet?.otherInvestments || [];
            const otherInvestmentsSum = otherInvestments.reduce((innerSum, investment) => {
                const value = parseFloat(investment?.amount || 0);
                return innerSum + value;
            }, 0);
            return sum + otherInvestmentsSum;
        }, 0);
    };

    const calculateOtherNonCurrentAssetsConsolidatedTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherNonCurrentAssets = borrower.balanceSheet?.otherNonCurrentAssets || [];
            const otherNonCurrentAssetsSum = otherNonCurrentAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0);
                return innerSum + value;
            }, 0);
            return sum + otherNonCurrentAssetsSum;
        }, 0);
    };

    const calculateOtherInvestmentsConsolidatedTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherInvestments = borrower.balanceSheet?.otherInvestments || [];
            const otherInvestmentsSum = otherInvestments.reduce((innerSum, investment) => {
                const value = parseFloat(investment.amount || 0) - parseFloat(investment.elims || 0);
                return innerSum + value;
            }, 0);
            return sum + otherInvestmentsSum;
        }, 0);
    };

    const {
        addBalanceSheetField,
        updateBalanceSheetField,
        deleteBalanceSheetField,
    } = loanDetailStore;

    return (
        <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" my={2}>
                Intermediate Assets
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
                {consolidated && (
                    <>
                        {/* Livestock - Breeding */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Livestock - Breeding (Schedule H) - Current"
                                value={calculateCurrentTotal('h', 'value')}
                                InputProps={{ readOnly: true }}
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Livestock - Breeding (Schedule H) - Elims"
                                value={loanData.consolidatedBalance?.intermediateAssets?.livestockBreedingElims || ""}
                                onChange={(e) =>
                                    updateConsolidatedField(
                                        'livestockBreedingElims',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Livestock - Breeding (Schedule H) - Consolidated"
                                value={calculateCurrentTotal('h', 'value') - calculateElims('livestockBreeding')}
                                InputProps={{ readOnly: true }}
                            />
                        </Box>

                        {/* Machinery and Vehicles */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Machinery and Vehicles (Schedule K) - Current"
                                value={calculateCurrentTotal('k', 'marketValue')}
                                InputProps={{ readOnly: true }}
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Machinery and Vehicles (Schedule K) - Elims"
                                value={loanData.consolidatedBalance?.intermediateAssets?.machineryAndVehiclesElims || ""}
                                onChange={(e) =>
                                    updateConsolidatedField(
                                        'machineryAndVehiclesElims',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Machinery and Vehicles (Schedule K) - Consolidated"
                                value={calculateCurrentTotal('k', 'marketValue') - calculateElims('machineryAndVehicles')}
                                InputProps={{ readOnly: true }}
                            />
                        </Box>
                    </>
                )}
                {proforma && (
                    <>
                        {/* Livestock - Breeding */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Livestock - Breeding (Schedule H) - Current"
                                value={calculateCurrentTotal('h', 'value') - calculateElims('livestockBreeding')}
                                InputProps={{ readOnly: true }}
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Livestock - Breeding (Schedule H) - Change"
                                value={loanDetailStore.loanData.balanceSheetProforma?.intermediateAssets?.livestockBreedingChange || ""}
                                onChange={(e) =>
                                    updateProformaField(
                                        'livestockBreedingChange',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Livestock - Breeding (Schedule H) - Proforma"
                                value={calculateProforma('livestockBreeding')}
                                InputProps={{ readOnly: true }}
                            />
                        </Box>

                        {/* Machinery and Vehicles */}
                        <Box display="flex" gap={2}>
                            <CurrencyTextField
                                variant="outlined"
                                label="Machinery and Vehicles (Schedule K) - Current"
                                value={calculateCurrentTotal('k', 'marketValue') - calculateElims('machineryAndVehicles')}
                                InputProps={{ readOnly: true }}
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Machinery and Vehicles (Schedule K) - Change"
                                value={loanDetailStore.loanData.balanceSheetProforma?.intermediateAssets?.machineryAndVehiclesChange || ""}
                                onChange={(e) =>
                                    updateProformaField(
                                        'machineryAndVehiclesChange',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ""
                                    )
                                }
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Machinery and Vehicles (Schedule K) - Proforma"
                                value={calculateProforma('machineryAndVehicles')}
                                InputProps={{ readOnly: true }}
                            />
                        </Box>
                    </>
                )}

                {!consolidated && !proforma && (
                    <>
                        <CurrencyTextField
                            variant="outlined"
                            label="Livestock - Breeding (Schedule H)"
                            value={calculateCurrentTotal('h', 'value')}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Machinery and Vehicles (Schedule K)"
                            value={calculateCurrentTotal('k', 'marketValue')}
                            InputProps={{ readOnly: true }}
                        />
                    </>
                )}
            </Box>

            <Typography variant="subtitle1" my={2}>Other Non-Current Assets</Typography>
            {borrowers.map((borrower) => (
                <Box key={borrower.id} mb={2}>
                    <Typography variant="subtitle2">{borrower.name}</Typography>
                    {borrower.balanceSheet?.otherNonCurrentAssets?.map((asset, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={asset.description}
                                onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonCurrentAssets', index, {
                                    ...asset,
                                    description: e.target.value
                                })}
                                InputProps={{ readOnly: consolidated || proforma }}
                            />
                            {consolidated && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={asset.amount}
                                        InputProps={{ readOnly: true }}
                                    />
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Elims"
                                        value={asset.elims || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonCurrentAssets', index, {
                                            ...asset,
                                            elims: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Consolidated"
                                        value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0)).toFixed(2)}
                                        InputProps={{ readOnly: true }}
                                    />
                                </>
                            )}
                            {proforma && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0)).toFixed(2)}
                                        InputProps={{ readOnly: true }}
                                    />
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Change"
                                        value={asset.change || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonCurrentAssets', index, { ...asset, change: parseFloat(e.target.value.replace(/,/g, "")) || '' })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Proforma"
                                        value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0) + parseFloat(asset.change || 0)).toFixed(2)}
                                        InputProps={{ readOnly: true }}
                                    />
                                </>
                            )}
                            {!consolidated && !proforma && (
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Amount"
                                    value={asset.amount}
                                    onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonCurrentAssets', index, {
                                        ...asset,
                                        amount: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    })}
                                />
                            )}
                            {!consolidated && (
                                <IconButton
                                    onClick={() => deleteBalanceSheetField(borrower.id, 'otherNonCurrentAssets', index)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    ))}
                    {!consolidated && !proforma && (
                        <Box display="flex" justifyContent="flex-start" mb={2}>
                            <Button variant="contained"
                                onClick={() => addBalanceSheetField(borrower.id, 'otherNonCurrentAssets')}>
                                Add Row
                            </Button>
                        </Box>
                    )}
                </Box>
            ))}
            {proforma && (
                <Box mb={2}>
                    {(loanData.balanceSheetProforma?.intermediateAssets?.otherNonCurrentAssets || []).map((asset, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={asset.description || ''}
                                onChange={(e) => {
                                    updateProformaBalanceSheetItem(
                                        'intermediateAssets',
                                        'otherNonCurrentAssets',
                                        index,
                                        'description',
                                        e.target.value
                                    );
                                }}
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Current"
                                value="0"
                                InputProps={{ readOnly: true }}
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Change"
                                value={asset.amount || ''}
                                onChange={(e) => {
                                    updateProformaBalanceSheetItem(
                                        'intermediateAssets',
                                        'otherNonCurrentAssets',
                                        index,
                                        'amount',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    );
                                }}
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Proforma"
                                value={parseFloat(asset.amount || 0).toFixed(2)}
                                InputProps={{ readOnly: true }}
                            />
                            <IconButton
                                onClick={() => deleteProformaBalanceSheetItem(
                                    'intermediateAssets',
                                    'otherNonCurrentAssets',
                                    index
                                )}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Box display="flex" justifyContent="flex-start" mb={2}>
                        <Button
                            variant="contained"
                            onClick={() => addProformaBalanceSheetItem(
                                'intermediateAssets',
                                'otherNonCurrentAssets'
                            )}
                        >
                            Add Row
                        </Button>
                    </Box>
                </Box>
            )}

            <Typography variant="subtitle1" my={2}>Other Investments</Typography>
            {borrowers.map((borrower) => (
                <Box key={borrower.id} mb={2}>
                    <Typography variant="subtitle2">{borrower.name}</Typography>
                    {borrower.balanceSheet?.otherInvestments?.map((investment, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={investment.description}
                                onChange={(e) => updateBalanceSheetField(borrower.id, 'otherInvestments', index, {
                                    ...investment,
                                    description: e.target.value
                                })}
                                InputProps={{ readOnly: consolidated || proforma }}
                            />
                            {consolidated && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={investment.amount}
                                        InputProps={{ readOnly: true }}
                                    />
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Elims"
                                        value={investment.elims || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'otherInvestments', index, {
                                            ...investment,
                                            elims: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Consolidated"
                                        value={(parseFloat(investment.amount || 0) - parseFloat(investment.elims || 0)).toFixed(2)}
                                        InputProps={{ readOnly: true }}
                                    />
                                </>
                            )}
                            {proforma && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={(parseFloat(investment.amount || 0) - parseFloat(investment.elims || 0)).toFixed(2)}
                                        InputProps={{ readOnly: true }}
                                    />
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Change"
                                        value={investment.change || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'otherInvestments', index, { ...investment, change: parseFloat(e.target.value.replace(/,/g, "")) || '' })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Proforma"
                                        value={(parseFloat(investment.amount || 0) - parseFloat(investment.elims || 0) + parseFloat(investment.change || 0)).toFixed(2)}
                                        InputProps={{ readOnly: true }}
                                    />
                                </>
                            )}
                            {!proforma && !consolidated && (
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Amount"
                                    value={investment.amount}
                                    onChange={(e) => updateBalanceSheetField(borrower.id, 'otherInvestments', index, {
                                        ...investment,
                                        amount: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    })}
                                />
                            )}
                            {!consolidated && !consolidated && (
                                <IconButton
                                    onClick={() => deleteBalanceSheetField(borrower.id, 'otherInvestments', index)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    ))}
                    {!consolidated && !proforma && (
                        <Box display="flex" justifyContent="flex-start" mb={2}>
                            <Button variant="contained"
                                onClick={() => addBalanceSheetField(borrower.id, 'otherInvestments')}>
                                Add Row
                            </Button>
                        </Box>
                    )}
                </Box>
            ))}
            {proforma && (
                <Box mb={2}>
                    {(loanData.balanceSheetProforma?.intermediateAssets?.otherInvestments || []).map((investment, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={investment.description || ''}
                                onChange={(e) => {
                                    updateProformaBalanceSheetItem(
                                        'intermediateAssets',
                                        'otherInvestments',
                                        index,
                                        'description',
                                        e.target.value
                                    );
                                }}
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Current"
                                value="0"
                                InputProps={{ readOnly: true }}
                            />
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Change"
                                value={investment.amount || ''}
                                onChange={(e) => {
                                    updateProformaBalanceSheetItem(
                                        'intermediateAssets',
                                        'otherInvestments',
                                        index,
                                        'amount',
                                        parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    );
                                }}
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Proforma"
                                value={parseFloat(investment.amount || 0).toFixed(2)}
                                InputProps={{ readOnly: true }}
                            />
                            <IconButton
                                onClick={() => deleteProformaBalanceSheetItem(
                                    'intermediateAssets',
                                    'otherInvestments',
                                    index
                                )}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Box display="flex" justifyContent="flex-start" mb={2}>
                        <Button
                            variant="contained"
                            onClick={() => addProformaBalanceSheetItem(
                                'intermediateAssets',
                                'otherInvestments'
                            )}
                        >
                            Add Row
                        </Button>
                    </Box>
                </Box>
            )}

            <Box display="flex" flexDirection="column" gap={2} my={2}>
                <Box display="flex" flexDirection="column" gap={2} my={2}>
                    {consolidated && (
                        <>
                            {/* Notes and Contracts Receivable */}
                            <Box display="flex" gap={2}>
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Notes and Contracts Receivable (Schedule E) - Current"
                                    value={calculateCurrentTotal('e', 'principal') + calculateCurrentTotal('e', 'interest')}
                                    InputProps={{ readOnly: true }}
                                />
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Notes and Contracts Receivable (Schedule E) - Elims"
                                    value={loanData.consolidatedBalance?.intermediateAssets?.notesAndContractsReceivableElims || ""}
                                    onChange={(e) =>
                                        updateConsolidatedField(
                                            'notesAndContractsReceivableElims',
                                            parseFloat(e.target.value.replace(/,/g, "")) || ""
                                        )
                                    }
                                />
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Notes and Contracts Receivable (Schedule E) - Consolidated"
                                    value={calculateCurrentTotal('e', 'principal') + calculateCurrentTotal('e', 'interest') - calculateElims('notesAndContractsReceivable')}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>

                            {/* Less Current Portion */}
                            <Box display="flex" gap={2}>
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Less Current Portion (Schedule E) - Current"
                                    value={-calculateCurrentTotal('e', 'value')}
                                    InputProps={{ readOnly: true }}
                                />
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Less Current Portion (Schedule E) - Elims"
                                    value={loanData.consolidatedBalance?.intermediateAssets?.lessCurrentPortionElims || ""}
                                    onChange={(e) =>
                                        updateConsolidatedField(
                                            'lessCurrentPortionElims',
                                            parseFloat(e.target.value.replace(/,/g, "")) || ""
                                        )
                                    }
                                />
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Less Current Portion (Schedule E) - Consolidated"
                                    value={-calculateCurrentTotal('e', 'value') - calculateElims('lessCurrentPortion')}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </>
                    )}
                    {proforma && (
                        <>
                            {/* Notes and Contracts Receivable */}
                            <Box display="flex" gap={2}>
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Notes and Contracts Receivable (Schedule E) - Current"
                                    value={calculateCurrentTotal('e', 'principal') + calculateCurrentTotal('e', 'interest') - calculateElims('notesAndContractsReceivable')}
                                    InputProps={{ readOnly: true }}
                                />
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Notes and Contracts Receivable (Schedule E) - Change"
                                    value={loanDetailStore.loanData.balanceSheetProforma?.intermediateAssets?.notesAndContractsReceivableChange || ""}
                                    onChange={(e) =>
                                        updateProformaField(
                                            'notesAndContractsReceivableChange',
                                            parseFloat(e.target.value.replace(/,/g, "")) || ""
                                        )
                                    }
                                />
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Notes and Contracts Receivable (Schedule E) - Proforma"
                                    value={calculateProforma('notesAndContractsReceivable')}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>

                            {/* Less Current Portion */}
                            <Box display="flex" gap={2}>
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Less Current Portion (Schedule E) - Current"
                                    value={-calculateCurrentTotal('e', 'value') - calculateElims('lessCurrentPortion')}
                                    InputProps={{ readOnly: true }}
                                />
                                <CurrencyTextField
                                    modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Less Current Portion (Schedule E) - Change"
                                    value={loanDetailStore.loanData.balanceSheetProforma?.intermediateAssets?.lessCurrentPortionChange || ""}
                                    onChange={(e) =>
                                        updateProformaField(
                                            'lessCurrentPortionChange',
                                            parseFloat(e.target.value.replace(/,/g, "")) || ""
                                        )
                                    }
                                />
                                <CurrencyTextField
                                    variant="outlined"
                                    label="Less Current Portion (Schedule E) - Proforma"
                                    value={-calculateProforma('lessCurrentPortion')}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </>
                    )}
                    {!consolidated && !proforma && (
                        <>
                            <CurrencyTextField
                                variant="outlined"
                                label="Notes and Contracts Receivable (Schedule E)"
                                value={calculateCurrentTotal('e', 'principal') + calculateCurrentTotal('e', 'interest')}
                                InputProps={{ readOnly: true }}
                            />
                            <CurrencyTextField
                                variant="outlined"
                                label="Less Current Portion (Schedule E)"
                                value={-calculateCurrentTotal('e', 'value')}
                                InputProps={{ readOnly: true }}
                            />
                        </>
                    )}

                </Box>
                <Box display="flex" flexDirection="row" gap={2} my={2}>
                    <Grid item xs={12} md={6}>
                        <Typography>Total Intermediate Assets:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} align="right">
                        <Typography>
                            {(
                                consolidated ? calculateConsolidatedTotal() :
                                    proforma ? calculateProformaTotal() :
                                        calculateCurrentTotal('h', 'value') +
                                        calculateCurrentTotal('k', 'marketValue') +
                                        calculateOtherNonCurrentAssetsTotal() +
                                        calculateOtherInvestmentsTotal() +
                                        calculateCurrentTotal('e', 'principal') +
                                        calculateCurrentTotal('e', 'interest') -
                                        calculateCurrentTotal('e', 'value')
                            ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </Typography>
                    </Grid>
                </Box>
            </Box>
        </Grid>
    );
});

export default IntermediateAssets;