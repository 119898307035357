import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Autocomplete
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { US_STATES } from '../../../constants';
import loanStore from '../loan-store';

// Keep the formatting functions at module level
const formatTaxId = (borrowerType, inputValue, currentTaxId) => {
    const isIndividualType = borrowerType === 'Individual' || borrowerType === 'Individual + Spouse';
    if (!inputValue) return '';

    const digitsOnly = inputValue.replace(/\D/g, '');
    const maxLength = isIndividualType ? 9 : 9;
    const truncatedDigits = digitsOnly.slice(0, maxLength);

    if (isIndividualType) {
        if (currentTaxId && currentTaxId.startsWith('XXX-XX-') && inputValue.length > 11) {
            return inputValue.slice(-4);
        }
        const formattedValue = truncatedDigits.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
        if (formattedValue.length === 11) {
            return `XXX-XX-${formattedValue.slice(-4)}`;
        }
        return formattedValue;
    } else {
        return truncatedDigits.replace(/^(\d{2})(\d{7})$/, '$1-$2');
    }
};

const formatPhoneNumber = (inputValue) => {
    const digitsOnly = inputValue.replace(/\D/g, '');
    const truncatedDigits = digitsOnly.slice(0, 10);

    if (truncatedDigits.length <= 3) {
        return truncatedDigits;
    } else if (truncatedDigits.length <= 6) {
        return `(${truncatedDigits.slice(0, 3)}) ${truncatedDigits.slice(3)}`;
    } else {
        return `(${truncatedDigits.slice(0, 3)}) ${truncatedDigits.slice(3, 6)}-${truncatedDigits.slice(6)}`;
    }
};

const isValidEmail = (email) => {
    if (!email) return true; // Empty emails are considered valid for validation purposes
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const CommonFields = observer(({ borrower, editedBorrower, onChange, onBlur, maskedTaxId, onTaxIdChange }) => {
    if (!borrower) return null;
    
    const displayData = editedBorrower || borrower.data;

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    name="address"
                    label="Address"
                    value={displayData.address || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    margin="normal"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="city"
                    label="City"
                    value={displayData.city || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    margin="normal"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Autocomplete
                    options={US_STATES}
                    value={displayData.state || ''}
                    onChange={(event, newValue) => {
                        onChange({ target: { name: 'state', value: newValue } });
                    }}
                    onBlur={onBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="State"
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    name="zip"
                    label="Zip Code"
                    value={displayData.zip || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    margin="normal"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="phone"
                    label="Phone"
                    value={displayData.phone || ''}
                    onChange={(e) => {
                        const formattedValue = formatPhoneNumber(e.target.value);
                        onChange({ target: { name: 'phone', value: formattedValue } });
                    }}
                    onBlur={onBlur}
                    fullWidth
                    margin="normal"
                    inputProps={{
                        maxLength: 14,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="email"
                    label="Email"
                    value={displayData.email || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    margin="normal"
                    error={displayData.email && !isValidEmail(displayData.email)}
                    helperText={displayData.email && !isValidEmail(displayData.email) ? 'Please enter a valid email address' : ''}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    name="taxId"
                    label={(displayData.type === 'Individual' || displayData.type === 'Individual + Spouse') ? 'SSN' : 'EIN'}
                    value={maskedTaxId || ''}
                    onChange={(e) => onTaxIdChange(e.target.value)}
                    onBlur={onBlur}
                    fullWidth
                    margin="normal"
                    inputProps={{
                        maxLength: (displayData.type === 'Individual' || displayData.type === 'Individual + Spouse') ? 11 : 10,
                    }}
                />
            </Grid>
            {(displayData.type === 'Individual' || displayData.type === 'Individual + Spouse') && (
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="cbrScore"
                        label="CBR Score"
                        value={displayData.cbrScore || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="nonUSCitizen"
                            checked={displayData.nonUSCitizen || false}
                            onChange={(e) => {
                                onChange({ target: { name: 'nonUSCitizen', value: e.target.checked } });
                            }}
                            onBlur={onBlur}
                        />
                    }
                    label="Check here if you are NOT a US Citizen or Permanent Alien"
                />
            </Grid>
        </>
    );
});

const BorrowerDetails = observer(() => {
    const [editedBorrower, setEditedBorrower] = useState(null);
    const [editedBorrowerId, setEditedBorrowerId] = useState(null);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [maskedTaxIds, setMaskedTaxIds] = useState({});

    useEffect(() => {
        // Use the current values from loanStore.borrowers without dependency
        loanStore.borrowers.forEach(borrower => {
            const formattedValue = formatTaxId(borrower.data.type, borrower.data.taxId || '', maskedTaxIds[borrower.id]);
            setMaskedTaxIds(prevState => ({
                ...prevState,
                [borrower.id]: formattedValue,
            }));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run only once on component mount

    const handleBorrowerChange = (borrowerId) => (event) => {
        const { name, value } = event.target;
        
        if (editedBorrowerId !== borrowerId) {
            const borrower = loanStore.borrowers.find(b => b.id === borrowerId);
            setEditedBorrower({...borrower.data});
            setEditedBorrowerId(borrowerId);
        }

        setEditedBorrower(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleBorrowerBlur = (borrowerId) => {
        if (editedBorrower && editedBorrowerId === borrowerId) {
            const borrower = loanStore.borrowers.find(b => b.id === borrowerId);
            borrower.update(editedBorrower);
            
            setEditedBorrower(null);
            setEditedBorrowerId(null);
        }
    };

    const handleTaxIdChange = (borrowerId) => (inputValue) => {
        const borrower = loanStore.borrowers.find(b => b.id === borrowerId);
        const borrowerData = editedBorrower || borrower.data;
        const formattedValue = formatTaxId(borrowerData.type, inputValue, maskedTaxIds[borrowerId]);
        
        setMaskedTaxIds(prevState => ({
            ...prevState,
            [borrowerId]: formattedValue,
        }));

        if (editedBorrowerId !== borrowerId) {
            setEditedBorrower({...borrower.data, taxId: inputValue});
            setEditedBorrowerId(borrowerId);
        } else {
            setEditedBorrower(prev => ({
                ...prev,
                taxId: inputValue
            }));
        }
    };

    const handleQuestionnaireChange = (event) => {
        const { name, value } = event.target;
        
        if (!questionnaire) {
            setQuestionnaire({
                ...loanStore.loanDoc.data?.application?.questionnaire,
                [name]: value === 'yes'
            });
        } else {
            setQuestionnaire(prev => ({
                ...prev,
                [name]: value === 'yes'
            }));
        }
    };

    const handleQuestionnaireBlur = () => {
        if (questionnaire) {
            loanStore.loanDoc.update({
                'application.questionnaire': questionnaire
            });
            setQuestionnaire(null);
        }
    };

    const handleDateChange = (borrowerId) => (date) => {
        if (editedBorrowerId !== borrowerId) {
            const borrower = loanStore.borrowers.find(b => b.id === borrowerId);
            setEditedBorrower({...borrower.data});
            setEditedBorrowerId(borrowerId);
        }

        setEditedBorrower(prev => ({
            ...prev,
            dob: date
        }));
    };

    if (!loanStore.loanDoc?.hasData) return null;

    const sortedBorrowers = [...loanStore.borrowers].sort((a, b) => {
        if (!a.data.createdAt) return -1;
        if (!b.data.createdAt) return -1;
        return new Date(a.data.createdAt) - new Date(b.data.createdAt);
    });

    const questionnaireData = questionnaire || loanStore.loanDoc.data?.application?.questionnaire || {};

    return (
        <Box>
            <Typography variant="h6" mb={1}>Borrower Details</Typography>
            
            {/* Questionnaire Radio Groups */}
            <Box display="flex" alignItems="center" mb={2}>
                <RadioGroup
                    name="otherLoansWithOriginator"
                    value={questionnaireData.otherLoansWithOriginator ? 'yes' : 'no'}
                    onChange={handleQuestionnaireChange}
                    onBlur={handleQuestionnaireBlur}
                    row
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <Typography variant="body1">Do applicants have other loans with originator?</Typography>
            </Box>
            
            <Box display="flex" alignItems="center" mb={2}>
                <RadioGroup
                    name="guaranteesOnLoan"
                    value={questionnaireData.guaranteesOnLoan ? 'yes' : 'no'}
                    onChange={handleQuestionnaireChange}
                    onBlur={handleQuestionnaireBlur}
                    row
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <Typography variant="body1">Are there guarantees on this loan?</Typography>
            </Box>
            
            <Box display="flex" alignItems="center" mb={2}>
                <RadioGroup
                    name="coApplicantsOnLoan"
                    value={questionnaireData.coApplicantsOnLoan ? 'yes' : 'no'}
                    onChange={handleQuestionnaireChange}
                    onBlur={handleQuestionnaireBlur}
                    row
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <Typography variant="body1">Are there co-applicants for this loan?</Typography>
            </Box>

            {/* Borrower Forms */}
            {sortedBorrowers.map((borrower, index) => {
                const displayData = editedBorrowerId === borrower.id ? editedBorrower : borrower.data;
                
                return (
                    <Box key={borrower.id} mb={2}>
                        <Typography variant="subtitle1" mb={1}>Borrower {index + 1}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="type"
                                    label="Type"
                                    value={displayData.type || ''}
                                    onChange={handleBorrowerChange(borrower.id)}
                                    onBlur={() => handleBorrowerBlur(borrower.id)}
                                    fullWidth
                                    margin="normal"
                                    select
                                >
                                    <MenuItem value="">Select Borrower Type</MenuItem>
                                    <MenuItem value="Individual">Individual</MenuItem>
                                    <MenuItem value="Individual + Spouse">Individual + Spouse</MenuItem>
                                    <MenuItem value="Entity">Entity</MenuItem>
                                    <MenuItem value="Trust">Trust</MenuItem>
                                </TextField>
                            </Grid>

                            {/* Individual Fields */}
                            {(displayData.type === 'Individual' || displayData.type === 'Individual + Spouse') && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="firstName"
                                            label="First Name"
                                            value={displayData.firstName || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="middleName"
                                            label="Middle Name"
                                            value={displayData.middleName || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="lastName"
                                            label="Last Name"
                                            value={displayData.lastName || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            select
                                            name="suffix"
                                            label="Suffix"
                                            value={displayData.suffix || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            <MenuItem value="Jr.">Jr.</MenuItem>
                                            <MenuItem value="Sr.">Sr.</MenuItem>
                                            <MenuItem value="II">II</MenuItem>
                                            <MenuItem value="III">III</MenuItem>
                                            <MenuItem value="IV">IV</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="nameAffidavit"
                                            label="Name Affidavit"
                                            value={displayData.nameAffidavit || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    {displayData.type === 'Individual + Spouse' && (
                                        <Grid item xs={12}>
                                            <TextField
                                                name="spouseName"
                                                label="Spouse Name"
                                                value={displayData.spouseName || ''}
                                                onChange={handleBorrowerChange(borrower.id)}
                                                onBlur={() => handleBorrowerBlur(borrower.id)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={4}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date of Birth"
                                                value={displayData.dob?.toDate ? displayData.dob.toDate() : displayData.dob}
                                                onChange={handleDateChange(borrower.id)}
                                                onAccept={() => handleBorrowerBlur(borrower.id)}
                                                format="MM/dd/yyyy"
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        margin: "normal"
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            )}

                            {/* Entity Fields */}
                            {displayData.type === 'Entity' && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="entityName"
                                            label="Entity Name"
                                            value={displayData.entityName || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            select
                                            name="organizationState"
                                            label="Organization State"
                                            value={displayData.organizationState || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        >
                                            {US_STATES.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="organizationType"
                                            label="Organization Type"
                                            value={displayData.organizationType || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="organizationId"
                                            label="Organization ID#"
                                            value={displayData.organizationId || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                </>
                            )}

                            {/* Trust Fields */}
                            {displayData.type === 'Trust' && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="trustName"
                                            label="Trust Name"
                                            value={displayData.trustName || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="beneficiaryName"
                                            label="Beneficiary Name"
                                            value={displayData.beneficiaryName || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="trustDate"
                                            label="Trust Date"
                                            value={displayData.trustDate || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            select
                                            name="trustState"
                                            label="Trust State"
                                            value={displayData.trustState || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        >
                                            {US_STATES.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="trustees"
                                            label="Trustees"
                                            value={displayData.trustees || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="settlors"
                                            label="Settlors"
                                            value={displayData.settlors || ''}
                                            onChange={handleBorrowerChange(borrower.id)}
                                            onBlur={() => handleBorrowerBlur(borrower.id)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                </>
                            )}

                            {/* Common Fields */}
                            <CommonFields
                                borrower={borrower}
                                editedBorrower={editedBorrowerId === borrower.id ? editedBorrower : null}
                                onChange={handleBorrowerChange(borrower.id)}
                                onBlur={() => handleBorrowerBlur(borrower.id)}
                                maskedTaxId={maskedTaxIds[borrower.id] || ''}
                                onTaxIdChange={handleTaxIdChange(borrower.id)}
                            />
                        </Grid>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => loanStore.removeBorrower(borrower.id)}
                            style={{ marginTop: '16px' }}
                        >
                            Remove Borrower
                        </Button>
                    </Box>
                );
            })}

            <Button 
                variant="contained" 
                onClick={() => loanStore.addNewBorrower()}
                style={{ marginTop: '16px' }}
            >
                Add Borrower
            </Button>
        </Box>
    );
});

export default BorrowerDetails;