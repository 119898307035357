import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, TextField } from '@mui/material';
import loanStore from '../loan-store';

const OriginatorInformation = observer(() => {
    if (!loanStore.originatorDoc?.hasData) {
        console.log('No originator data available');
        return null;
    }
    
    const originatorData = loanStore.originatorDoc.data;

    return (
        <Box>
            <Typography variant="h6" mb={1}>Originator Information</Typography>
            <TextField
                label="Originator Name"
                value={originatorData?.name || ''}
                fullWidth
                margin="normal"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                label="Contact Name"
                value={originatorData?.display_name || ''}
                fullWidth
                margin="normal"
                InputProps={{
                    readOnly: true,
                }}
            />
            <Box display="flex" gap={2}>
                <TextField
                    label="Street Address"
                    value={originatorData?.street_address || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="City"
                    value={originatorData?.city || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="State"
                    value={originatorData?.state || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
            <Box display="flex" gap={2}>
                <TextField
                    label="Phone Number"
                    value={originatorData?.phone_number || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Fax"
                    value={originatorData?.fax_number || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
        </Box>
    );
});

export default OriginatorInformation;