import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid } from '@mui/material';
import loanDetailStore from '../store';

const IncomeStatementTrendAnalysis = observer(() => {
    const { borrowers, loanData } = loanDetailStore;
    const selectedBorrower = borrowers.find(b => b.is_principal) || borrowers[0];
    const incomeAndExpenses = selectedBorrower?.incomeAndExpenses || {};
    const proformaData = loanData.incomeAndExpensesProforma || {};

    // Generate years dynamically
    const mostRecentYear = parseInt(loanData?.mostRecentYear) || new Date().getFullYear();
    const years = Array.from({ length: incomeAndExpenses.years || 0 }, (_, i) => 
        mostRecentYear - ((incomeAndExpenses.years || 0) - 1 - i)
    );

    const calculateTotalFarmIncome = (data) => {
        return (
            (parseFloat(data?.netPurchasedLS) || 0) +
            (parseFloat(data?.raisedFarmProducts) || 0) +
            (parseFloat(data?.cooperativeDistributions) || 0) +
            (parseFloat(data?.agProgramPayments) || 0) +
            (parseFloat(data?.cccLoans) || 0) +
            (parseFloat(data?.cropInsurance) || 0) +
            (parseFloat(data?.customHire) || 0) +
            (parseFloat(data?.farmOtherIncomeA) || 0) +
            (parseFloat(data?.farmOtherIncomeB) || 0)
        );
    };

    const calculateTotalFarmExpenses = (data) => {
        return (
            (parseFloat(data?.carAndTruck) || 0) +
            (parseFloat(data?.chemicals) || 0) +
            (parseFloat(data?.conservation) || 0) +
            (parseFloat(data?.expensesCustomHire) || 0) +
            (parseFloat(data?.depreciation) || 0) +
            (parseFloat(data?.employeeBenefit) || 0) +
            (parseFloat(data?.feed) || 0) +
            (parseFloat(data?.fertilizer) || 0) +
            (parseFloat(data?.freight) || 0) +
            (parseFloat(data?.gasolineFuel) || 0) +
            (parseFloat(data?.insurance) || 0) +
            (parseFloat(data?.interestMortgageNonCurrent) || 0) +
            (parseFloat(data?.otherInterest) || 0) +
            (parseFloat(data?.labor) || 0) +
            (parseFloat(data?.rentLeaseMachinery) || 0) +
            (parseFloat(data?.rentLeaseRealProperty) || 0) +
            (parseFloat(data?.repairs) || 0) +
            (parseFloat(data?.seed) || 0) +
            (parseFloat(data?.storage) || 0) +
            (parseFloat(data?.supplies) || 0) +
            (parseFloat(data?.taxes) || 0) +
            (parseFloat(data?.utilities) || 0) +
            (parseFloat(data?.veterinaryFees) || 0) +
            (parseFloat(data?.otherExpensesA) || 0) +
            (parseFloat(data?.otherExpensesB) || 0)
        );
    };

    const calculateTotalNonFarmIncome = (data) => {
        return (
            (parseFloat(data?.salaries) || 0) +
            (parseFloat(data?.interestIncome) || 0) +
            (parseFloat(data?.dividends) || 0) +
            (parseFloat(data?.businessIncomeSchC) || 0) +
            (parseFloat(data?.capitalGainLossSchD) || 0) +
            (parseFloat(data?.otherGainLoss4797) || 0) +
            (parseFloat(data?.rentalIncomeSchE) || 0) +
            (parseFloat(data?.otherIncomeA) || 0) +
            (parseFloat(data?.otherIncomeB) || 0) +
            (parseFloat(data?.otherIncomeC) || 0)
        );
    };

    const calculateTotalInterest = (data) => {
        return (parseFloat(data?.interestMortgageNonCurrent) || 0) + 
               (parseFloat(data?.otherInterest) || 0);
    };

    const calculateCashFlowAvailable = (data) => {
        const netFarmIncome = calculateTotalFarmIncome(data) - calculateTotalFarmExpenses(data);
        const totalNonFarmIncome = calculateTotalNonFarmIncome(data);
        
        return netFarmIncome + 
               totalNonFarmIncome +
               (parseFloat(data?.depreciation) || 0) +
               calculateTotalInterest(data) +
               (parseFloat(data?.rentLeaseMachinery) || 0) +
               (parseFloat(data?.otherPlusAdjustment) || 0) -
               (parseFloat(data?.incomeAndFICATaxes) || 0) -
               (parseFloat(data?.familyLiving) || 0) -
               (parseFloat(data?.nonRecurring) || 0) -
               (parseFloat(data?.otherMinusAdjustments) || 0);
    };

    const calculateCFR = (data) => {
        return (parseFloat(data?.totalAnnualPI) || 0) +
               (parseFloat(data?.rentLeaseMachinery) || 0) +
               (parseFloat(data?.lessPINewLandPurchase) || 0);
    };

    const calculateAverage = (field, calculator = null) => {
        const data = incomeAndExpenses.data || [];
        if (calculator) {
            return data.reduce((sum, yearData) => sum + calculator(yearData), 0) / (data.length || 1);
        }
        return data.reduce((sum, yearData) => sum + (parseFloat(yearData[field]) || 0), 0) / (data.length || 1);
    };

    const renderValue = (value, isPercentage = false) => {
        if (value === undefined || value === null || isNaN(value)) return '';
        if (isPercentage) {
            return value.toFixed(2);
        }
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const columnCount = (incomeAndExpenses.years || 0) + 3; // years + label + average + proforma

    const renderRow = (label, field, calculator = null, isPercentage = false) => {
        const data = incomeAndExpenses.data || [];
        const reversedData = [...data].reverse(); // Reverse data to show newest to oldest
        return (
            <>
                <Grid item xs={12 / columnCount}>
                    <Typography>{label}</Typography>
                </Grid>
                {reversedData.map((yearData, index) => (
                    <Grid item xs={12 / columnCount} key={index}>
                        <Typography align="right">
                            {renderValue(calculator ? calculator(yearData) : yearData[field], isPercentage)}
                        </Typography>
                    </Grid>
                ))}
                <Grid item xs={12 / columnCount}>
                    <Typography align="right">
                        {renderValue(calculator ? calculateAverage(field, calculator) : calculateAverage(field), isPercentage)}
                    </Typography>
                </Grid>
                <Grid item xs={12 / columnCount}>
                    <Typography align="right">
                        {renderValue(calculator ? calculator(proformaData) : proformaData[field], isPercentage)}
                    </Typography>
                </Grid>
            </>
        );
    };

    return (
        <Box mt={4}>
            <Typography variant="h6" gutterBottom>Income Statement Trend Analysis</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12 / columnCount}>
                    <Typography>Year</Typography>
                </Grid>
                {years.slice().reverse().map(year => (
                    <Grid item xs={12 / columnCount} key={year}>
                        <Typography align="right">{year}</Typography>
                    </Grid>
                ))}
                <Grid item xs={12 / columnCount}>
                    <Typography align="right">Average</Typography>
                </Grid>
                <Grid item xs={12 / columnCount}>
                    <Typography align="right">Pro-Forma</Typography>
                </Grid>

                {renderRow('+ Total Farm Income', 'totalFarmIncome', calculateTotalFarmIncome)}
                {renderRow('- Total Farm Expenses', 'totalFarmExpenses', calculateTotalFarmExpenses)}
                {renderRow('= Net Farm Income', 'netFarmIncome', 
                    data => calculateTotalFarmIncome(data) - calculateTotalFarmExpenses(data))}
                {renderRow('Net Non-Farm Income', 'netNonFarmIncome', calculateTotalNonFarmIncome)}
                {renderRow('+ Depreciation', 'depreciation')}
                {renderRow('+ Total Interest', 'totalInterest', calculateTotalInterest)}
                {renderRow('+ Annual Capital Lease Payments', 'rentLeaseMachinery')}
                {renderRow('+ Other Plus Adjustments', 'otherPlusAdjustment')}
                {renderRow('- Income & FICA Tax', 'incomeAndFICATaxes')}
                {renderRow('- Family Living', 'familyLiving')}
                {renderRow('- Non-Recurring', 'nonRecurring')}
                {renderRow('- Other Minus Adjustment', 'otherMinusAdjustments')}
                {renderRow('= Cash Flow Available (CFA)', 'cashFlowAvailable', calculateCashFlowAvailable)}
                {renderRow('+ Annual P&I', 'totalAnnualPI')}
                {renderRow('+ Annual Capital Lease', 'rentLeaseMachinery')}
                {renderRow('+ P&I New Land Purchase', 'lessPINewLandPurchase')}
                {renderRow('= CFR', 'cfr', calculateCFR)}
                {renderRow('Excess Cash', 'excessCash', 
                    data => calculateCashFlowAvailable(data) - calculateCFR(data))}
                {renderRow('TDC', 'tdc', 
                    data => {
                        const cfr = calculateCFR(data);
                        return cfr ? calculateCashFlowAvailable(data) / cfr : 0;
                    }, true)}
                {renderRow('TDC Shock 3%:', 'tdcShock3', 
                    data => {
                        const cfr = calculateCFR(data);
                        return cfr ? (calculateCashFlowAvailable(data) * 0.97) / cfr : 0;
                    }, true)}
                {renderRow('TDC Shock 5%:', 'tdcShock5', 
                    data => {
                        const cfr = calculateCFR(data);
                        return cfr ? (calculateCashFlowAvailable(data) * 0.95) / cfr : 0;
                    }, true)}
            </Grid>
        </Box>
    );
});

export default IncomeStatementTrendAnalysis;