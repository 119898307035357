// LongTermLiabilities.js
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, Grid } from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const LongTermLiabilities = observer(({ borrowers, consolidated, proforma = false }) => {
  const calculateArrayFieldSum = (schedule, field) => {
    return borrowers.reduce((sum, borrower) => {
      const array = borrower.bsas?.[schedule] || [];
      const arraySum = array.reduce((innerSum, item) => {
        const value = parseFloat(item?.[field] || 0);
        return innerSum + value;
      }, 0);
      return sum + arraySum;
    }, 0);
  };

  // Calculate the current portion of principal payments for schedule A
  const calculateCurrentPrincipalSum = () => {
    const scheduleData = borrowers.flatMap(borrower => {
      const items = proforma 
        ? (borrower.bsas?.['a'] || []).map(item => ({
            ...item,
            ...(item.proforma || {})
          }))
        : borrower.bsas?.['a'] || [];
      return items;
    });
    
    // Sum the principal component from each item in schedule A
    return scheduleData.reduce((sum, item) => {
      const annualPayment = parseFloat(item.annualPayment) || 0;
      const balance = parseFloat(item.balance) || 0;
      const rate = parseFloat(item.rate) || 0;
      const annualInterest = balance * (rate/100);
      const principal = annualPayment - annualInterest;
      return sum + principal;
    }, 0);
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="subtitle1" my={2}>
        Long Term Liabilities
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <CurrencyTextField
          variant="outlined"
          label="Real Estate Mortgages (Schedule A)"
          value={calculateArrayFieldSum('a', 'balance')}
          InputProps={{ readOnly: true }}
          fullWidth
        />
        <CurrencyTextField
          variant="outlined"
          label="Less Current Portion (Schedule A)"
          value={-calculateCurrentPrincipalSum()}
          InputProps={{ readOnly: true }}
          fullWidth
        />
      </Box>

      <Box display="flex" flexDirection="row" gap={2} my={2}>
        <Grid item xs={12} md={6}>
          <Typography>Total Long Term Liabilities:</Typography>
        </Grid>
        <Grid item xs={12} md={6} align="right">
          <Typography>
            {(calculateArrayFieldSum('a', 'balance') - calculateCurrentPrincipalSum()).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
});

export default LongTermLiabilities;