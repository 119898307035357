import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CommonLayout from '../GlobalComponents/CommonLayout';
import loansStore from '../LoanListView/store';
import { format } from 'date-fns';

const DashboardComponent = observer(() => {
    const [totalLoanAmount, setTotalLoanAmount] = useState(0);
    const [averageLoanAmount, setAverageLoanAmount] = useState(0);
    const [loansByStatus, setLoansByStatus] = useState({});
    const [recentLoans, setRecentLoans] = useState([]);

    useEffect(() => {
        // Only process when we have data and aren't loading
        if (!loansStore.isLoading && loansStore.hasData) {
            const processLoanData = () => {
                const loans = loansStore.loans;
                
                // Calculate total with better error handling
                const total = loans.reduce((sum, loan) => {
                    const amount = Number(loan.loanAmountRequested);
                    return sum + (isNaN(amount) ? 0 : amount);
                }, 0);

                const average = loans.length > 0 ? total / loans.length : 0;

                const statusCount = loans.reduce((acc, loan) => {
                    const status = loan.status || 'Unknown';
                    acc[status] = (acc[status] || 0) + 1;
                    return acc;
                }, {});

                const sortedLoans = [...loans].sort((a, b) => {
                    const dateA = a.created_at?.toDate?.() || new Date(0);
                    const dateB = b.created_at?.toDate?.() || new Date(0);
                    return dateB - dateA;
                });

                setTotalLoanAmount(total);
                setAverageLoanAmount(average);
                setLoansByStatus(statusCount);
                setRecentLoans(sortedLoans.slice(0, 5));
            };

            processLoanData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loansStore.isLoading, loansStore.hasData]);

    // Show loading state while loading or when we have no data yet
    const isLoading = loansStore.isLoading || !loansStore.hasData;

    const loanTrendData = loansStore.loans
        .filter(loan => loan.created_at && loan.loanAmountRequested)
        .map((loan) => ({
            date: format(loan.created_at.toDate(), 'MM/dd'),
            amount: Number(loan.loanAmountRequested) || 0,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    return (
        <CommonLayout>
            <Typography variant="h4" mb={3}>Loan Dashboard</Typography>

            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3}>
                    {/* Summary Cards */}
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>Total Loan Amount</Typography>
                                <Typography variant="h5">${totalLoanAmount.toLocaleString()}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>Average Loan Amount</Typography>
                                <Typography variant="h5">${Math.round(averageLoanAmount).toLocaleString()}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>Total Loans</Typography>
                                <Typography variant="h5">{loansStore.loans.length}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Loan Trend Chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Loan Amount Trend</Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={loanTrendData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
                                    <Line type="monotone" dataKey="amount" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>

                    {/* Loans by Status */}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Loans by Status</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Status</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(loansByStatus).map(([status, count]) => (
                                            <TableRow key={status}>
                                                <TableCell component="th" scope="row">{status || 'Unknown'}</TableCell>
                                                <TableCell align="right">{count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>

                    {/* Recent Loans */}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Recent Loans</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Borrower</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell align="right">Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recentLoans.map((loan) => (
                                            <TableRow key={loan.id}>
                                                <TableCell component="th" scope="row">
                                                    {loan.principalBorrower || 'Not Available'}
                                                </TableCell>
                                                <TableCell align="right">
                                                    ${Number(loan.loanAmountRequested || 0).toLocaleString()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {loan.created_at ?
                                                        format(loan.created_at.toDate(), 'MM/dd/yyyy') :
                                                        'N/A'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </CommonLayout>
    );
});

export default DashboardComponent;