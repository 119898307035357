import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, TextField, FormControlLabel, MenuItem, Grid, Button } from '@mui/material';
import { Radio, RadioGroup, FormControl } from '@mui/material';
import loanStore from '../loan-store';
import { US_STATES } from '../../../constants';

const Security = observer(() => {
    const securities = loanStore.loanDoc?.data?.application?.securities || [];
    const [editedSecurity, setEditedSecurity] = useState(null);
    const [editedIndex, setEditedIndex] = useState(null);

    if (!loanStore.loanDoc?.hasData) return null;

    const handleInputChange = (timestamp) => (event) => {
        const { name, value } = event.target;
        const securityIndex = securities.findIndex(s => s.timestamp === timestamp);
        
        if (editedIndex !== securityIndex) {
            setEditedSecurity({...securities[securityIndex]});
            setEditedIndex(securityIndex);
        }
        
        setEditedSecurity(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleRadioChange = (timestamp) => (event) => {
        const { name, value } = event.target;
        const securityIndex = securities.findIndex(s => s.timestamp === timestamp);
        
        if (editedIndex !== securityIndex) {
            setEditedSecurity({...securities[securityIndex]});
            setEditedIndex(securityIndex);
        }
        
        setEditedSecurity(prev => ({
            ...prev,
            [name]: value === 'yes'
        }));
    };

    const handleBlur = (timestamp) => {
        if (editedSecurity && editedIndex !== null) {
            const updatedSecurities = [...securities];
            updatedSecurities[editedIndex] = editedSecurity;
            
            loanStore.loanDoc.update({
                'application.securities': updatedSecurities
            });
            
            setEditedSecurity(null);
            setEditedIndex(null);
        }
    };

    const addSecurity = () => {
        const newSecurity = {
            timestamp: Date.now()
        };
        
        loanStore.loanDoc.update({
            'application.securities': [...securities, newSecurity]
        });
    };

    const removeSecurity = (timestamp) => {
        loanStore.loanDoc.update({
            'application.securities': securities.filter(security => security.timestamp !== timestamp)
        });
    };

    const sortedSecurities = [...securities].sort((a, b) => a.timestamp - b.timestamp);

    return (
        <Box>
            <Typography variant="h6" mb={1}>Security</Typography>
            {sortedSecurities.map((security, index) => {
                const displaySecurity = (editedIndex === index) ? editedSecurity : security;
                
                return (
                    <Box key={security.timestamp} mb={4}>
                        <Typography variant="subtitle1">Security #{index + 1}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Acres"
                                    name="acres"
                                    value={displaySecurity.acres || ''}
                                    onChange={handleInputChange(security.timestamp)}
                                    onBlur={() => handleBlur(security.timestamp)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="County"
                                    name="county"
                                    value={displaySecurity.county || ''}
                                    onChange={handleInputChange(security.timestamp)}
                                    onBlur={() => handleBlur(security.timestamp)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    select
                                    label="State"
                                    name="state"
                                    value={displaySecurity.state || ''}
                                    onChange={handleInputChange(security.timestamp)}
                                    onBlur={() => handleBlur(security.timestamp)}
                                    fullWidth
                                    margin="normal"
                                >
                                    {US_STATES.map((state) => (
                                        <MenuItem key={state} value={state}>
                                            {state}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="The title is/will be vested in:"
                                    name="titleVestedIn"
                                    value={displaySecurity.titleVestedIn || ''}
                                    onChange={handleInputChange(security.timestamp)}
                                    onBlur={() => handleBlur(security.timestamp)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset">
                                    <Typography component="legend">Is this property irrigated?</Typography>
                                    <RadioGroup
                                        row
                                        name="isIrrigated"
                                        value={displaySecurity.isIrrigated ? 'yes' : 'no'}
                                        onChange={handleRadioChange(security.timestamp)}
                                        onBlur={() => handleBlur(security.timestamp)}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" mb={1}>Legal Description</Typography>
                                <TextField
                                    name="legalDescription"
                                    value={displaySecurity.legalDescription || ''}
                                    onChange={handleInputChange(security.timestamp)}
                                    onBlur={() => handleBlur(security.timestamp)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" mb={1}>
                                    Additional Security (Include federal & state grazing leases, irrigation equipment, water rights & other equipment necessary to the operation of the security):
                                </Typography>
                                <TextField
                                    name="additionalSecurity"
                                    value={displaySecurity.additionalSecurity || ''}
                                    onChange={handleInputChange(security.timestamp)}
                                    onBlur={() => handleBlur(security.timestamp)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" mb={1}>
                                    Description of property and improvements (including leased equipment i.e. irrigation pivots):
                                </Typography>
                                <TextField
                                    name="propertyDescription"
                                    value={displaySecurity.propertyDescription || ''}
                                    onChange={handleInputChange(security.timestamp)}
                                    onBlur={() => handleBlur(security.timestamp)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => removeSecurity(security.timestamp)}
                            style={{ marginTop: '16px' }}
                        >
                            Remove Security
                        </Button>
                    </Box>
                );
            })}
            <Button variant="contained" color="primary" onClick={addSecurity}>
                Add Security
            </Button>
        </Box>
    );
});

export default Security;