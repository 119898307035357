import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, TextField, Button, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanStore from '../loan-store';

const UseOfFunds = observer(() => {
    const useOfFunds = loanStore.loanDoc?.data?.application?.useOfFunds || [];
    const [editedFund, setEditedFund] = useState(null);
    const [editedIndex, setEditedIndex] = useState(null);

    const loanPurposeOptions = [
        'Purchase Real Estate',
        'Refinance Real Estate',
        'Improvements',
        'Refinance Non-Real Estate',
        'Cash Out',
        'Closing Fees'
    ];

    if (!loanStore.loanDoc?.hasData) return null;

    const handleInputChange = (index) => (event) => {
        const { name, value } = event.target;
        
        if (editedIndex !== index) {
            setEditedFund({...useOfFunds[index]});
            setEditedIndex(index);
        }

        setEditedFund(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAmountChange = (index) => (event, value) => {
        if (editedIndex !== index) {
            setEditedFund({...useOfFunds[index]});
            setEditedIndex(index);
        }

        setEditedFund(prev => ({
            ...prev,
            amount: value
        }));
    };

    const handleBlur = (index) => {
        if (editedFund && editedIndex !== null) {
            const updatedFunds = [...useOfFunds];
            updatedFunds[editedIndex] = editedFund;
            
            loanStore.loanDoc.update({
                'application.useOfFunds': updatedFunds
            });
            
            setEditedFund(null);
            setEditedIndex(null);
        }
    };

    const addUseOfFunds = () => {
        loanStore.loanDoc.update({
            'application.useOfFunds': [...useOfFunds, { 
                loanPurpose: '', 
                description: '', 
                amount: '' 
            }]
        });
    };

    const removeUseOfFunds = (index) => {
        const updatedFunds = [...useOfFunds];
        updatedFunds.splice(index, 1);
        loanStore.loanDoc.update({
            'application.useOfFunds': updatedFunds
        });
    };

    const calculateTotal = () => {
        return useOfFunds.reduce((sum, fund) => sum + (Number(fund.amount) || 0), 0);
    };

    return (
        <Box>
            <Typography variant="h6" mb={1}>Use of Funds</Typography>
            {useOfFunds.map((fund, index) => {
                const displayFund = (editedIndex === index) ? editedFund : fund;
                
                return (
                    <Grid container spacing={2} key={index} alignItems="center" mb={2}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                select
                                label="Loan Purpose"
                                name="loanPurpose"
                                value={displayFund.loanPurpose || ''}
                                onChange={handleInputChange(index)}
                                onBlur={() => handleBlur(index)}
                                fullWidth
                            >
                                {loanPurposeOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Description"
                                name="description"
                                value={displayFund.description || ''}
                                onChange={handleInputChange(index)}
                                onBlur={() => handleBlur(index)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                variant="outlined"
                                label="Amount"
                                value={displayFund.amount || ''}
                                onChange={handleAmountChange(index)}
                                onBlur={() => handleBlur(index)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => removeUseOfFunds(index)}
                                fullWidth
                            >
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                );
            })}
            <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={3}>
                    <CurrencyTextField
                        disabled
                        value={calculateTotal()}
                        variant="outlined"
                        label="Total"
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Button variant="contained" onClick={addUseOfFunds}>
                Add additional row
            </Button>
        </Box>
    );
});

export default UseOfFunds;