import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Grid } from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const TotalPISummary = observer(({ borrowerData }) => {
  const { updateLoanData, loanData } = loanDetailStore;

  const calculateScheduleTotals = () => {
    let totals = {
      scheduleA: { balance: 0, accruedInterest: 0, annualTotalInterest: 0, annualTotalPrincipal: 0 },
      scheduleB: { balance: 0, accruedInterest: 0, annualTotalInterest: 0, annualTotalPrincipal: 0 },
      scheduleC: { balance: 0, accruedInterest: 0 }
    };

    borrowerData.forEach(borrower => {
      // Schedule A totals
      (borrower.bsas?.a || []).forEach(item => {
        totals.scheduleA.balance += parseFloat(item.balance || 0);
        totals.scheduleA.accruedInterest += parseFloat(item.accruedInterest || 0);
        totals.scheduleA.annualTotalInterest += parseFloat(item.balance || 0) * (parseFloat(item.rate || 0) / 100);
        totals.scheduleA.annualTotalPrincipal += (parseFloat(item.annualPayment || 0) - 
          (parseFloat(item.balance || 0) * (parseFloat(item.rate || 0) / 100)));
      });

      // Schedule B totals
      (borrower.bsas?.b || []).forEach(item => {
        totals.scheduleB.balance += parseFloat(item.balance || 0);
        totals.scheduleB.accruedInterest += parseFloat(item.accruedInterest || 0);
        totals.scheduleB.annualTotalInterest += parseFloat(item.balance || 0) * (parseFloat(item.rate || 0) / 100);
        totals.scheduleB.annualTotalPrincipal += (parseFloat(item.annualPayment || 0) - 
          (parseFloat(item.balance || 0) * (parseFloat(item.rate || 0) / 100)));
      });

      // Schedule C totals
      (borrower.bsas?.c || []).forEach(item => {
        totals.scheduleC.balance += parseFloat(item.balance || 0);
        totals.scheduleC.accruedInterest += parseFloat(item.accruedInterest || 0);
      });
    });

    return totals;
  };

  const totals = calculateScheduleTotals();

  const handleOperatingLineUpdate = (field, value) => {
    updateLoanData({ 
      ...loanData,
      operatingLine: {
        ...loanData.operatingLine,
        [field]: value
      }
    });
  };

  return (
    <Box mt={4} p={2} sx={{ border: '1px solid #e0e0e0', borderRadius: 1 }}>
      <Typography variant="h6" mb={2}>
        Total Annual Principal And Interest Summary
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>Description</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Balance</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography>Rate (%)</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Accrued Interest</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography>Annual Total Interest</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography>Annual Total Principal</Typography>
        </Grid>

        {/* Average Outstanding Op. Line and Rate */}
        <Grid item xs={4}>
          <Typography>Average Outstanding Op. Line and Rate</Typography>
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            value={loanData.operatingLine?.balance || 0}
            onChange={(e) => handleOperatingLineUpdate('balance', parseFloat(e.target.value.replace(/,/g, "")) || 0)}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            variant="outlined"
            type="number"
            value={loanData.operatingLine?.rate || 0}
            onChange={(e) => handleOperatingLineUpdate('rate', parseFloat(e.target.value) || 0)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            value={loanData.operatingLine?.balance * (loanData.operatingLine?.rate / 100) || 0}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>

        {/* TOTAL FARM NON-CURRENT P&I */}
        <Grid item xs={4}>
          <Typography>TOTAL FARM NON-CURRENT P&I, ACCRUED INTEREST</Typography>
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            variant="outlined"
            value=""
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            value={totals.scheduleB.accruedInterest + totals.scheduleC.accruedInterest}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            value={totals.scheduleB.annualTotalInterest + totals.scheduleC.annualTotalInterest}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            value={totals.scheduleB.annualTotalPrincipal + totals.scheduleC.annualTotalPrincipal}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>

        {/* TOTAL NON-FARM NON-CURRENT P&I */}
        <Grid item xs={4}>
          <Typography>TOTAL NON-FARM NON-CURRENT P&I (excluding tax Schedule A, P&I)</Typography>
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            variant="outlined"
            value=""
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            value={totals.scheduleA.annualTotalInterest}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            value={totals.scheduleA.annualTotalPrincipal}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>

        {/* TOTAL ANNUAL P&I */}
        <Grid item xs={4}>
          <Typography sx={{ fontWeight: 'bold' }}>TOTAL ANNUAL P&I (REQUIRED CASH FLOW)</Typography>
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            variant="outlined"
            value=""
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <CurrencyTextField
            variant="outlined"
            value={
            totals.scheduleA.annualTotalPrincipal + totals.scheduleA.annualTotalInterest + 
            totals.scheduleB.annualTotalPrincipal + totals.scheduleB.annualTotalInterest + 
            (loanData.operatingLine?.balance * (loanData.operatingLine?.rate / 100) || 0)}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default TotalPISummary;