import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { formatISO } from 'date-fns';
import { Box, Typography, TextField, MenuItem, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import loanStore from '../loan-store';

const LoanOverview = observer(() => {
    const [editedData, setEditedData] = useState(null);

    useEffect(() => {
        if (loanStore.loanDoc?.data?.created_by) {
            loanStore.fetchUserName(loanStore.loanDoc.data.created_by);
        }
    });

    if (!loanStore.hasLoanDoc) {
        return null;
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (!editedData) {
            // Initialize edited data from current values
            setEditedData({
                applicationDate: loanStore.applicationDate,
                mostRecentYear: loanStore.mostRecentYear,
                status: loanStore.status
            });
        }

        setEditedData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handleBlur = () => {
        if (editedData) {
            // Filter out undefined values to avoid Firestore errors
            const sanitizedData = Object.fromEntries(
                Object.entries(editedData)
                    .filter(([_, value]) => value !== undefined)
            );
            
            loanStore.loanDoc.update(sanitizedData);
            setEditedData(null);
        }
    };

    const displayData = editedData || {
        applicationDate: loanStore.applicationDate,
        mostRecentYear: loanStore.mostRecentYear,
        status: loanStore.status
    };

    return (
        <Box mb={4}>
            <Typography variant="h6" gutterBottom mb={1}>
                Loan Overview
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Application Date"
                            value={displayData.applicationDate ? new Date(displayData.applicationDate) : null}
                            onChange={(newValue) => {
                                if (!editedData) {
                                    // Initialize edited data from current values
                                    setEditedData({
                                        applicationDate: newValue ? formatISO(newValue) : null,
                                        mostRecentYear: loanStore.mostRecentYear,
                                        status: loanStore.status
                                    });
                                } else {
                                    setEditedData(prev => ({
                                        ...prev,
                                        applicationDate: newValue ? formatISO(newValue) : null
                                    }));
                                }
                            }}
                            onClose={handleBlur}  // Update store when calendar closes
                            format="MM/dd/yyyy"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    margin: "normal",
                                    onBlur: handleBlur  // Also update on field blur
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Most Recent Inc/Exp Year"
                        name="mostRecentYear"
                        value={displayData.mostRecentYear || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 4 }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Status"
                        name="status"
                        value={displayData.status || 'Pending'}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        select
                        fullWidth
                    >
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Submitted">Submitted</MenuItem>
                        <MenuItem value="Underwriting Processing">Underwriting Processing</MenuItem>
                        <MenuItem value="Approved">Approved</MenuItem>
                        <MenuItem value="Closed and Funded">Closed and Funded</MenuItem>
                        <MenuItem value="Denied">Denied</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Current Underwriter"
                        value={loanStore.userName || ''}
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default LoanOverview;