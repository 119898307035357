import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, TextField, Checkbox, FormControlLabel } from '@mui/material';
import loanStore from '../loan-store';

const FinalDetails = observer(() => {
    const [editedDetails, setEditedDetails] = useState(null);

    if (!loanStore.loanDoc?.hasData) return null;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        
        if (!editedDetails) {
            // Initialize edited state from current values
            setEditedDetails({
                ...loanStore.loanDoc.data?.application?.finalDetails
            });
        }
        
        setEditedDetails(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        
        if (!editedDetails) {
            setEditedDetails({
                ...loanStore.loanDoc.data?.application?.finalDetails
            });
        }
        
        setEditedDetails(prev => ({
            ...prev,
            [name]: checked
        }));
        
        // Immediately update checkbox changes
        handleBlur();
    };

    const handleBlur = () => {
        if (editedDetails) {
            loanStore.loanDoc.update({
                'application.finalDetails': editedDetails
            });
            setEditedDetails(null);
        }
    };

    // Use either edited values or stored values
    const displayData = editedDetails || loanStore.loanDoc.data?.application?.finalDetails || {};

    return (
        <Box>
            <Typography variant="h6" mb={1}>Final Details</Typography>

            <TextField
                label="Describe any labor performed, materials furnished, or contracts made in connection with the erection or alteration or other improvements on the offered security within the past six months"
                name="laborPerformed"
                value={displayData.laborPerformed || ''}
                onChange={handleInputChange}
                onBlur={handleBlur}
                fullWidth
                margin="normal"
                multiline
                rows={4}
            />

            <TextField
                label="List all leases on the security, including lessees, amounts, property leased and the lease expiration dates"
                name="leasesOnSecurity"
                value={displayData.leasesOnSecurity || ''}
                onChange={handleInputChange}
                onBlur={handleBlur}
                fullWidth
                margin="normal"
                multiline
                rows={4}
            />

            <TextField
                label="Comments"
                name="comments"
                value={displayData.comments || ''}
                onChange={handleInputChange}
                onBlur={handleBlur}
                fullWidth
                margin="normal"
                multiline
                rows={4}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        name="agriculturalProduction"
                        checked={displayData.agriculturalProduction || false}
                        onChange={handleCheckboxChange}
                    />
                }
                label="Each of the undersigned applicants certify that they are or will be actively engaged in agricultural production, either in their own right or as a landlord, and they intend to continue agricultural production on the real estate used as a collateral for the loan."
            />

            <FormControlLabel
                control={
                    <Checkbox
                        name="revolvingCredit"
                        checked={displayData.revolvingCredit || false}
                        onChange={handleCheckboxChange}
                    />
                }
                label="If the loan product selected involves a revolving line of credit, the Applicant represents and agrees that any and all requests for funds under the loan will be used only for agricultural or other business purposes."
            />
        </Box>
    );
});

export default FinalDetails;