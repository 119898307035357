import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import loansStore from './store';
import {
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    Divider,
    CircularProgress,
    IconButton,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import CommonLayout from '../GlobalComponents/CommonLayout';
import uiStore from '../GlobalComponents/store';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const LOAN_STATUSES = [
    'Pending',
    'Submitted',
    'Underwriting Processing',
    'Approved',
    'Closed and Funded',
    'Denied'
];

const LoansListView = observer(() => {
    const navigate = useNavigate();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loanToDelete, setLoanToDelete] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');
    const [amountFilter, setAmountFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [sortField, setSortField] = useState('created_at');
    const [sortDirection, setSortDirection] = useState('desc');

    useEffect(() => {
        uiStore.setCurrentPage('Loans');
    }, []);

    const handleLoanClick = (loanId) => {
        navigate(`/loans/${loanId}`);
    };

    const handleDeleteClick = (e, loan) => {
        e.stopPropagation();
        setLoanToDelete(loan);
        setDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (loanToDelete) {
            try {
                await loansStore.loansCollection.doc(loanToDelete.id).delete();
            } catch (error) {
                console.error('Error deleting loan:', error);
            }
        }
        setDeleteModalOpen(false);
        setLoanToDelete(null);
    };

    // Get all loans from Firestorter collection
    const loans = loansStore.loans;

    const filteredAndSortedLoans = loans
        .filter(loan => {
            // Now loan should be the processed data, not a Document
            const principalBorrower = loan.principalBorrower || '';
            const principalBorrowerType = loan.principalBorrowerType || '';
            const loanAmount = loan.loanAmountRequested || ''; // Remove .data access
            const status = loan.status || ''; // Remove .data access

            return (
                principalBorrower.toLowerCase().includes(nameFilter.toLowerCase()) &&
                (typeFilter === '' || principalBorrowerType === typeFilter) &&
                (amountFilter === '' || Number(loanAmount) >= Number(amountFilter)) &&
                (statusFilter === '' || status === statusFilter)
            );
        })
        .sort((a, b) => {
            if (sortField === 'created_at') {
                const dateA = a.created_at?.toDate?.() || new Date(0); // Remove .data access
                const dateB = b.created_at?.toDate?.() || new Date(0); // Remove .data access
                return sortDirection === 'asc'
                    ? dateA - dateB
                    : dateB - dateA;
            } else if (sortField === 'loanAmountRequested') {
                const amountA = Number(a.loanAmountRequested || 0); // Remove .data access
                const amountB = Number(b.loanAmountRequested || 0); // Remove .data access
                return sortDirection === 'asc'
                    ? amountA - amountB
                    : amountB - amountA;
            } else {
                const valueA = (a[sortField] || '').toString();
                const valueB = (b[sortField] || '').toString();
                return sortDirection === 'asc'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }
        });

    return (
        <CommonLayout>
            <Typography variant="h6" gutterBottom>
                Loans
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Filter by Name"
                        variant="outlined"
                        fullWidth
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Borrower Type</InputLabel>
                        <Select
                            value={typeFilter}
                            onChange={(e) => setTypeFilter(e.target.value)}
                            label="Borrower Type"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Individual">Individual</MenuItem>
                            <MenuItem value="Entity">Entity</MenuItem>
                            <MenuItem value="Trust">Trust</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Minimum Amount"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={amountFilter}
                        onChange={(e) => setAmountFilter(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            label="Status"
                        >
                            <MenuItem value="">All</MenuItem>
                            {LOAN_STATUSES.map((status) => (
                                <MenuItem key={status} value={status}>{status}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Sort By</InputLabel>
                        <Select
                            value={sortField}
                            onChange={(e) => setSortField(e.target.value)}
                            label="Sort By"
                        >
                            <MenuItem value="principalBorrower">Name</MenuItem>
                            <MenuItem value="principalBorrowerType">Type</MenuItem>
                            <MenuItem value="loanAmountRequested">Amount</MenuItem>
                            <MenuItem value="status">Status</MenuItem>
                            <MenuItem value="created_at">Date</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Sort Direction</InputLabel>
                        <Select
                            value={sortDirection}
                            onChange={(e) => setSortDirection(e.target.value)}
                            label="Sort Direction"
                        >
                            <MenuItem value="asc">Ascending</MenuItem>
                            <MenuItem value="desc">Descending</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {loansStore.loansCollection.isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <List>
                    {filteredAndSortedLoans.map((loan) => (
                        <React.Fragment key={loan.id}>
                            <ListItem
                                button
                                onClick={() => handleLoanClick(loan.id)}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(e) => handleDeleteClick(e, loan)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={`Principal Borrower: ${loan.principalBorrower}`}
                                    secondary={`Type: ${loan.principalBorrowerType} | Status: ${loan.status} | Date: ${loan.created_at ? format(loan.created_at.toDate(), 'MMMM d, yyyy') : 'N/A'}`}
                                />
                                <Box flexGrow={1} />
                                <Typography variant="subtitle1">
                                    {loan.loanAmountRequested && !isNaN(Number(loan.loanAmountRequested))
                                        ? `${Number(loan.loanAmountRequested).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`
                                        : 'N/A'}
                                </Typography>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            )}
            <DeleteConfirmationModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={handleDeleteConfirm}
                principalBorrower={loanToDelete?.data?.principalBorrower}
            />
        </CommonLayout>
    );
});

export default LoansListView;