// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  endBefore,
  serverTimestamp,
  deleteField
} from 'firebase/firestore';
import authStore from './authStore';
import { initFirestorter } from 'firestorter';

const app = initializeApp({
  apiKey: "AIzaSyDI_f0Ci9mlif_aEfF2aBzBW6cGzCe3YG4",
  authDomain: "harvestuw-app.firebaseapp.com",
  projectId: "harvestuw-app",
  storageBucket: "harvestuw-app.appspot.com",
  messagingSenderId: "733632458331",
  appId: "1:733632458331:web:06567f09e73b0a9bafd97e",
  measurementId: "G-01HXQ1W7F2"
});

const firestore = getFirestore(app);
const auth = getAuth(app);
const db = firestore;

// Initialize Firestorter with a complete context that matches the IContext interface
initFirestorter({ 
  collection: (path) => collection(firestore, path),
  doc: (path) => doc(firestore, path),
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  endBefore,
  deleteField: () => deleteField(),
  serverTimestamp: () => serverTimestamp()
});

// Helper functions for authentication
const handleLogout = async () => {
  try {
    await signOut(auth);
    console.log('Logout successful');
    authStore.setUser(null);
  } catch (error) {
    console.error('Logout error:', error);
  }
};

const handleLogin = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log('Login successful');
    authStore.setUser(userCredential.user);
  } catch (error) {
    console.error('Login error:', error);
  }
};

const handlePasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log('Password reset email sent');
  } catch (error) {
    console.error('Error sending password reset email:', error);
  }
};

export { 
  auth, 
  firestore, 
  handleLogin, 
  handlePasswordReset, 
  handleLogout,
  db
};